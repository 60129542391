import LandingPage from "./Landing";
import Dashboard from "./Dashboard";
import SearchEngine from "./SearchEngine";
import HighPatientNeeds from "./HighPatientNeeds";
import StudyModule from "./StudyModule";
import DiscoverModule from "./DiscoverModule";
import Discon from "./HighPatientNeeds/Discon/Discon";
import StoriesPage from "./Stories";
import OldStoriesPage from "./OldStories";
import PatientJourneyPage from "./PatientJourney";
import NodeGraphView from "./NodeGraph";
import CognitivePage from "./Cognitive";
import InsightsDashboard from "./InsightsDashboard";

const ob = {
	LandingPage,
	Dashboard,
	SearchEngine,
	HighPatientNeeds,
	StudyModule,
	DiscoverModule,
	Discon,
	StoriesPage,
	OldStoriesPage,
	PatientJourneyPage,
	NodeGraphView,
	CognitivePage,
	InsightsDashboard,
};
export default ob;
