import React, { useState } from "react";
import "./index.css";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Upload, message } from "antd";
// import * as StudyServiceHelper from "../../../SearchEngine/ServiceHelpers/study";

interface WaveData {
	key: number;
	waveId?: number;
	waveName: string;
	studyIterId: number;
	geoId?: number;
	geography: string | null;
	startDate?: string;
	endDate?: string;
	guideId?: number;
	guideName: string | null;
	transcriptCount: number;
	rowSpan?: number;
}

export default function GuideInput(props: {
	studyName: string;
	waveData: WaveData;
	handleGuideUpload: (record: WaveData, updatedGuideId: number, updatedGuideName: string) => void;
	disabled: boolean;
	studyTypeId: number | undefined;
}) {
	const [fileList, setFileList] = useState<any[]>([]);
	const [uploadStatus, setUploadStatus] = useState("idle"); // Possible values: 'idle', 'uploading', 'done', 'error'

	let filePath: string =
		process.env.REACT_APP_API_URL +
		"/study/UploadToS3?data=" +
		(props.waveData.guideId ?? 0) + // dgId
		"|" +
		props.waveData.studyIterId + // studyIterId
		"|" +
		(props.waveData.studyIterId + "/guide") + // upload s3 path (studyIterId/guide)
		"|" +
		-1 + // FileId
		"|" +
		"true" + // true for guide, false for transcript
		"|" +
		(props.studyTypeId || -1); // studyTypeId, send -1 if not available
	filePath = filePath.replace(/\s/g, "_");

	// Define a function to convert a file to base64 string for previewing
	const getBase64 = (file: any) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleUploadChange = (info: any) => {
		console.log("Upload File Info -> ", info);
		//        console.log("Upload File API Response Info -> ", info.file.response);

		setFileList(info.fileList);

		if (info.file.status === "done") {
			setUploadStatus("done");
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (info.file.status === "uploading") {
			setUploadStatus("uploading");
			// message.loading(`${info.file.name} file is uploading.`, 10);
		} else if (info.file.status === "removed") {
			message.success(`${info.file.name} file removed successfully.`);
		} else if (info.file.status === "error") {
			setUploadStatus("error");
			message.error(`${info.file.name} file upload failed.`);
		}

		if (info.file.response > -1) props.handleGuideUpload(props.waveData, info.file.response, info.file.name);
	};

	const beforeUploadHandler = (file: any) => {
		console.log("Before Upload File Info -> ", file);

		const isValidContentType =
			file.type === "text/plain" || // txt
			file.type === "application/msword" || // doc
			file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"; // docx

		if (!isValidContentType) {
			message.error("Invalid uploaded file extension. Please try again.");
			return false;
		}

		const isValidSize = file.size <= 5242880; // 5MB in bytes
		if (!isValidSize) {
			message.error("File size exceeds the maximum allowed (5MB). Please upload a smaller file.");
			return false;
		}

		return true;
	};

	const handlePreview = async (file: any) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		// Do something with the file preview, e.g., show it in a modal.
	};

	const handleRemove = (file: any) => {
		//        console.log('Removing file:', file.name);
		setUploadStatus("idle");
		setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== file.uid));
		// Perform additional actions when a file is removed.
	};

	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			{uploadStatus === "done" ? (
				<span className="guide-input-text" style={{ color: "#2F54EB" }}>
					{fileList[0]?.name}
				</span>
			) : (
				<Upload
					// customRequest={handleUpload}
					action={filePath}
					headers={{
						"X-Api-Key": process.env.REACT_APP_API_KEY ?? "0",
					}}
					withCredentials={true}
					beforeUpload={beforeUploadHandler}
					onChange={handleUploadChange}
					onPreview={handlePreview}
					onRemove={handleRemove}
					fileList={fileList}
					showUploadList={{
						showPreviewIcon: true,
						showRemoveIcon: true,
						showDownloadIcon: true,
					}}
					accept=".txt,.doc,.docx"
					listType="text"
					disabled={uploadStatus === "uploading"}
					maxCount={1}
				>
					<Button
						style={{
							border: "none",
							background: "transparent",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						disabled={props.disabled}
						icon={<UploadOutlined size={20} style={{ color: props.disabled ? "#5a5a5a" : "#27A6A4" }} />}
					>
						<span style={{ marginLeft: "10px" }}>Upload</span>
					</Button>
				</Upload>
			)}

			{uploadStatus === "error" && (
				// Show danger sign if file upload fails
				<span style={{ marginLeft: "5px" }}>
					<ExclamationCircleOutlined size={20} style={{ color: "#E9364C" }} />
				</span>
			)}
		</div>
	);
}
