import React, { useRef, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import basicLineChartConfig from '../chartConfigs/basicLineChartConfig';

interface ILineChartProps {
	data: Array<{
		date: string;
		data: Array<{ id?: number; label?: string; value: number; date?: string }>;
	}>;
	chartType?: string;
	colors?: string[];
	title?: string;
	handleClick: (inputData: Record<string, any>, inputType: string) => void;
	maxDataPoints?: number; // New prop to control the maximum number of data points
}

// Helper function to aggregate data
const aggregateData = (data: any[], maxDataPoints: number) => {
	if (data.length <= maxDataPoints) return data;

	const aggregationFactor = Math.ceil(data.length / maxDataPoints);
	return data.reduce((acc, curr, index) => {
		const aggregateIndex = Math.floor(index / aggregationFactor);
		if (!acc[aggregateIndex]) {
			acc[aggregateIndex] = { ...curr, data: curr.data.map((d: any) => ({ ...d, value: 0 })) };
		}
		curr.data.forEach((d: any, i: number) => {
			acc[aggregateIndex].data[i].value += d.value / aggregationFactor;
		});
		return acc;
	}, []);
};

export default function LineChart({
	data,
	chartType = 'basic',
	colors,
	title = '',
	handleClick,
	maxDataPoints = 10, // Default to 10 data points
}: ILineChartProps) {
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	// Memoize the aggregated data
	const aggregatedData = useMemo(() => aggregateData(data, maxDataPoints), [data, maxDataPoints]);

	useEffect(() => {
		if (chartComponentRef.current) {
			const chart = chartComponentRef.current.chart;
			if (chart) {
				chart.series.forEach((series) => {
					series.data.forEach((point) => {
						point.update({
							events: {
								click: () => {
									// @ts-ignore
									handleClick({ id: point.id, label: point.category, value: point.y }, title + 'LineChart');
									console.log('Line Chart Point Clicked:', point);
								},
							},
						});
					});
				});
			}
		}
	}, [aggregatedData, handleClick, title]);

	if (!aggregatedData || aggregatedData.length === 0) {
		return null;
	}

	let chartConfig = basicLineChartConfig;

	// Get all unique categories from the data
	const categories = Array.from(new Set(aggregatedData.flatMap((item: any) => item.data.map((d: any) => d.label))));

	const options = {
		...chartConfig,
		chart: {
			...chartConfig.chart,
			zoomType: 'x', // Allow zooming on x-axis
		},
		xAxis: {
			categories: aggregatedData.map((item: any) => item.date),
		},
		series: categories.map((category, index) => ({
			name: category,
			data: aggregatedData.map((item: any) => {
				const dataPoint = item.data.find((d: any) => d.label === category);
				return dataPoint ? dataPoint.value : null;
			}),
			color: colors && colors[index],
		})),
		plotOptions: {
			series: {
				turboThreshold: 0, // Disable turbo threshold
			},
		},
	};

	return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
}
