import { useState } from "react";
import { Card, Collapse } from "antd";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import "../style.css";
import "../../../Components/Markdown/style.css";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

export default function StoriesSummaryCard(props: {
  storyId?: any;
  data: any;
  isloaded: boolean;
  fetchedStoryData?: any;
  fetchStoryData?: any;
  transcriptData?: any;
  storyDialogues?: any;
  searchString?: any;
  setSearchString?: any;
  keywords?: string[];
  maxHeight?: string;
}) {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <>
      {props.isloaded && props.data !== "" ? (
        <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
          <Collapse.Panel className="px-2" header={<span className="w-12/12 font-bold text-base text-xl text-text-black">{props.searchString}</span>} key="1">
            <Card style={{ maxHeight: props.maxHeight, overflowY: "auto", marginBottom: "1rem" }} bordered={false}>
              <div className="text-base">
                {showMore && props.data.length > 1100 ? (
                  <>
                    {/* <ReactMarkdown
                      // children={props.data}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({ children }) {
                          return (
                            <SyntaxHighlighter
                              children={String(children).replace(/\n$/, "")}
                              //   {...TempData}
                            />
                          );
                        },
                      }}
                    >
                      {props.data}
                    </ReactMarkdown> */}
                    {/* <Markdown remarkPlugins={[breaks, gfm]}>{"**Para Start** \n Line1,Line1 \n ~~Line2~~ \n *Line3* \n **ParaEnd** \n&nbsp;\n **Para Start** \n *Line1* \n *Line2* \n *Line3* \n **ParaEnd** \n&nbsp;\n \n |Fahrenheit|Celsius|Kelvin| \n|---:|---:|---:| \n|-459.67|-273.15|0| \n|-40|-40|233.15| \n|32|0|273.15| \n|212|100|373.15|"}</Markdown> */}
                    <Markdown remarkPlugins={[breaks, gfm]}>{props.data}</Markdown>
                    {/* <Markdown remarkPlugins={[breaks, gfm]}>{`**Para Start** \n Line1,Line1 \n ~~Line2~~ \n *Line3* \n **ParaEnd** \n&nbsp;\n **Para Start** \n *Line1* \n *Line2* \n *Line3* \n **ParaEnd** \n&nbsp;\n \n |Fahrenheit|Celsius|Kelvin| \n|---:|---:|---:| \n|-459.67|-273.15|0| \n|-40|-40|233.15| \n|32|0|273.15| \n|212|100|373.15|`}</Markdown> */}
                  </>
                ) : (
                  // <ReactMarkdown
                  //   children={props.data.slice(0, 1100)}
                  //   remarkPlugins={[remarkGfm]}
                  //   components={{
                  //     code({ children }) {
                  //       return (
                  //         <SyntaxHighlighter
                  //           children={String(children).replace(/\n$/, "")}
                  //           //   {...TempData}
                  //         />
                  //       );
                  //     },
                  //   }}
                  // ></ReactMarkdown>
                  // <Markdown remarkPlugins={[breaks, gfm]}>{"**Para Start** \n Line1,Line1 \n ~~Line2~~ \n *Line3* \n **ParaEnd** \n&nbsp;\n **Para Start** \n *Line1* \n *Line2* \n *Line3* \n **ParaEnd** \n&nbsp;\n \n |Fahrenheit|Celsius|Kelvin| \n|---:|---:|---:| \n|-459.67|-273.15|0| \n|-40|-40|233.15| \n|32|0|273.15| \n|212|100|373.15|"}</Markdown>
                  <Markdown remarkPlugins={[breaks, gfm]}>{props.data.slice(0, 1100)}</Markdown>
                )}
                <div
                  className="text-text-teal font-normal cursor-pointer w-max pt-2"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {!showMore ? <>Show more</> : <>Show less</>}
                </div>
              </div>
            </Card>
          </Collapse.Panel>
        </Collapse>
      ) : (
        <></>
      )}
    </>
  );
}
