import React, { useEffect, useState } from "react";
import { Form, Select, Radio, Button } from "antd";
import { InsightsDatasetDropdown } from "./Components/InsightsDatasetDropdown";
import { getSearchEmployee } from "../../services/search";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";
import { IFetchedFilter } from "../../model/interface/search";

export interface IFilterOption {
	value: string;
	label: string;
}

export interface IFilterConfig {
	name: string;
	label: string;
	type: "select" | "radio" | "datasetDropdown" | "metaFiltersDropdown";
	options?: IFilterOption[];
	multiple?: boolean;
	mode?: "tags" | "multiple" | undefined;
}

interface IFiltersProps {
	config: IFilterConfig[];
	onFiltersChange: (inputData: Record<string, any>, inputType: string) => void;
}

const ConfigDrivenFilters: React.FC<IFiltersProps> = ({ config, onFiltersChange }) => {
	const [form] = Form.useForm();
	const { Option, OptGroup } = Select;

	const [metaDataDropdownData, setMetaDataDropdownData] = useState<any[]>([]);
	const [fetchedFilters, setFetchedFilters] = useState<IFetchedFilter[]>([]);

	useEffect(() => {
		config.forEach((filter) => {
			if (filter.type === "datasetDropdown") fetchFilters();
			if (filter.type === "metaFiltersDropdown") fetchSearchMetaData();
		});
	}, [config]);

	const fetchFilters = async () => {
		try {
			const type = "insights";
			const data: any = await SearchServiceHelper.getFilters(type);
			if (data) setFetchedFilters(data);
		} catch (error) {
			console.error("Failed to fetch filters:", error);
		}
	};

	const fetchSearchMetaData = async () => {
		try {
			let data;
			const studyIds = form.getFieldsValue().studyIds;

			if (studyIds !== undefined && studyIds !== null) {
				if (Array.isArray(studyIds)) {
					const studyIdsString = studyIds.length > 0 ? studyIds.join("|") : -1;
					data = await getSearchEmployee(studyIdsString);
				} else {
					data = await getSearchEmployee(studyIds);
				}
			} else {
				data = await getSearchEmployee(-1);
			}

			if (data) {
				const groupMap = new Map();
				const transformedFilterGroups: any[] = [];

				data.forEach((item: any) => {
					const [category, subcategory] = item.name.split(":");
					if (category && subcategory) {
						let group = groupMap.get(category.trim());
						if (!group) {
							group = { key: item.id, label: category.trim(), children: [] };
							groupMap.set(category.trim(), group);
							transformedFilterGroups.push(group);
						}
						group.children.push({
							key: item.id,
							value: item.id,
							label: subcategory.trim(),
							...item,
						});
					}
				});

				setMetaDataDropdownData(transformedFilterGroups);
			}
		} catch (error) {
			console.error("Failed to fetch search meta data:", error);
		}
	};

	const handleFilterChange = () => {
		const values = form.getFieldsValue();
		onFiltersChange(values, "formFilters");
	};

	return (
		<Form form={form} layout="vertical" onValuesChange={handleFilterChange}>
			<div className="flex flex-wrap items-end">
				{config.map((filter) => (
					<div key={filter.name} className="w-1/3 p-2">
						<Form.Item label={filter.label} name={filter.name}>
							{filter.type === "select" && (
								<Select mode={filter.multiple ? "multiple" : filter.mode} options={filter.options} placeholder={`Select ${filter.label}`} />
							)}
							{filter.type === "radio" && <Radio.Group options={filter.options} />}
							{filter.type === "datasetDropdown" && (
								<InsightsDatasetDropdown
									data={{
										transcripts: fetchedFilters[0]?.treeOptionsObject?.transcripts || [],
										reports: fetchedFilters[0]?.treeOptionsObject?.reports || [],
									}}
									handleFilters={(filterType, filterValue) => {
										form.setFieldsValue({ [filter.name]: filterValue });
										fetchSearchMetaData();
									}}
									searchIn={false}
								/>
							)}
							{filter.type === "metaFiltersDropdown" && (
								<Select
									mode="multiple"
									placeholder={`Select ${filter.label}`}
									optionFilterProp="children"
									onChange={(selectedValues) => {
										form.setFieldsValue({ [filter.name]: selectedValues });
									}}
								>
									{metaDataDropdownData
										.sort((a, b) => a.label.localeCompare(b.label))
										.map((group: any) => (
											<OptGroup key={group.key} label={group.label}>
												{group.children
													.sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label))
													.map((item: any) => (
														<Option key={item.key} value={item.value}>
															{item.label}
														</Option>
													))}
											</OptGroup>
										))}
								</Select>
							)}
						</Form.Item>
					</div>
				))}
				<div className="p-2" style={{ paddingBottom: "1.75rem" }}>
					<Button type="primary" onClick={handleFilterChange}>
						Apply Filters
					</Button>
				</div>
			</div>
		</Form>
	);
};

export default ConfigDrivenFilters;
