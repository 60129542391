import { Discover } from "./Discover"
import { DiscoverModuleTabs } from "../SearchEngine/data";
import SubNavigation from "../../Components/SubNavigation";
import { Search } from "../SearchEngine/Search";
import { StoriesView } from "../Stories/StoriesView";

export default function DiscoverModule() {

  const tabs = DiscoverModuleTabs();
  return (
    <>
      <SubNavigation tabs={tabs} />
      <Discover />
    </>
  );
};
