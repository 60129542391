import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import LineChart from './LineChart'; // Assuming this is the path to your existing LineChart component

const { Option } = Select;

interface ILineChartWithSelectorProps {
	data: Array<{
		date: string;
		data: Array<{ id?: number; label?: string; value: number; date?: string }>;
	}>;
	chartType?: string;
	colors?: string[];
	title?: string;
	handleClick: (inputData: Record<string, any>, inputType: string) => void;
}

const dataPointOptions = [
	{ label: '5 points', value: 5 },
	{ label: '10 points', value: 10 },
	{ label: '50 points', value: 50 },
	{ label: '100 points', value: 100 },
	{ label: '200 points', value: 200 },
	{ label: 'All points', value: Infinity },
];

export default function LineChartWithSelector({ data, chartType, colors, title, handleClick }: ILineChartWithSelectorProps) {
	const [maxDataPoints, setMaxDataPoints] = useState<number>(5);

	const handleDataPointChange = useCallback((value: number) => {
		setMaxDataPoints(value);
	}, []);

	return (
		<div>
			<div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
				<Select defaultValue={5} style={{ width: 120 }} onChange={handleDataPointChange}>
					{dataPointOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>
			</div>
			<LineChart data={data} chartType={chartType} colors={colors} title={title} handleClick={handleClick} maxDataPoints={maxDataPoints} />
		</div>
	);
}
