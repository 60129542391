import React, { useEffect, useState, useCallback, useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { TreeSelect, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { selectStore, setSearchSummaryFilterInSync } from "../../../../state/slice/appSlice";
// import testingTreeData from "./data.json";

const { SHOW_PARENT } = TreeSelect;

interface IStudyTreeDropdownProps {
	data: {
		transcripts: any[];
		reports: any[];
	};
	handleFilters: (filterType: string, filterValue: string | boolean | string[] | number) => void;
	searchIn: boolean;
}

export function StudyTreeDropdown(props: IStudyTreeDropdownProps) {
	const store = useSelector(selectStore);
	const dispatch = useDispatch();
	const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4", alignSelf: "center", flex: 1 }} spin />;

	const [value, setValue] = useState<string[] | undefined>(undefined);
	const [dropdownData, setDropdownData] = useState<any[]>(props.searchIn ? props.data?.reports : props.data?.transcripts);

	useEffect(() => {
		props.handleFilters("studyIds", value || []);
	}, [value]);

	useEffect(() => {
		setDropdownData(props.searchIn ? props.data?.reports : props.data?.transcripts);
	}, [props.searchIn, props.data?.reports, props.data?.transcripts]);

	useEffect(() => {
		if (store.searchTab.searchFilters.studyIds && Array.isArray(store.searchTab.searchFilters.studyIds)) {
			// if (store.searchTab.searchFilters.studyIds.length > 0) {
			setValue(store.searchTab.searchFilters.studyIds);
			// }
		}
	}, [dropdownData]);

	const filterTreeNode = useCallback((input: any, treeNode: any) => {
		const title = treeNode.props.title.toLowerCase();
		return title.indexOf(input.toLowerCase()) >= 0;
	}, []);

	const dropdownRender = useCallback(
		(menu: any) => (
			<Spin spinning={!props.data} indicator={antIcon}>
				{menu}
			</Spin>
		),
		[props.data, antIcon]
	);

	const onChange = useCallback(
		(newValue: string[]) => {
			// console.log("onChange ", newValue);
			setValue(newValue);
			if (store.searchTab.searchStreamedData !== "Loading data..." && store.searchTab.searchRefData.length > 0) {
				dispatch(setSearchSummaryFilterInSync(false));
			}
		},
		[store.searchTab.searchStreamedData, store.searchTab.searchRefData, dispatch]
	);

	const tProps = useMemo(
		() => ({
			treeData: dropdownData,
			value,
			onChange,
			filterTreeNode,
			treeCheckable: true,
			treeLine: true,
			showSearch: true,
			showCheckedStrategy: SHOW_PARENT,
			placeholder: "Select Datasets",
			maxTagTextLength: 15,
			maxTagCount: "responsive" as const,
			style: {
				width: "100%",
			},
			dropdownRender,
		}),
		[dropdownData, value, onChange, filterTreeNode, dropdownRender]
	);

	return (
		<>
			<TreeSelect {...tProps} />
		</>
	);
}
