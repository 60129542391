import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "antd/lib/layout";
import message from "antd/lib/message";
import WikiPageCard from "./WikiPageCard";
import { icons } from "../../../../GlobalThemes";
// import { DiscoverFilters } from "../../../../Components/DiscoverCards/DiscoverFilters";
import { CognitiveFilters } from "../../../../Components/CognitiveFilters";
import { GetCognitiveFactorsDetails } from "../../../SearchEngine/ServiceHelpers/cognitive";
import { getNodeJsons } from "../../../SearchEngine/ServiceHelpers/nodeGraph";
import { ICognitiveWikiPageData } from "../../../../model/interface/cognitive";
import SideMenu from "../../../../Components/Sider";
import Skeleton from "antd/lib/skeleton";

function WikiPage() {
	const { id } = useParams();
	const currentStudyIterId = sessionStorage.getItem("studyIterId");
	const [collapsed, setCollapsed] = useState<boolean>(true);
	const [wikiData, setWikiData] = useState<ICognitiveWikiPageData[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [topicsData, setTopicsData] = useState<string[]>([]);

	const fetchedStudyFilters: any = [];

	useEffect(() => {
		if (id && currentStudyIterId) {
			fetchCognitiveFactorsDetails();
		}
	}, []);

	// API Request to fetch Cognitive Factor Data
	async function fetchCognitiveFactorsDetails() {
		setLoading(true);
		try {
			const data: any = await GetCognitiveFactorsDetails(Number(id), Number(currentStudyIterId));

			if (data) {
				setTopicsData(data.topics);
				const s3WikiData: any = await getNodeJsons(data.jsonS3path);
				if (s3WikiData) {
					setWikiData(s3WikiData);
					message.success("Cognitive Factor details loaded");
				} else {
					message.error("Error fetching Cognitive Factor details");
				}
			}
		} catch (error) {
			message.error("Error fetching cognitive factor details");
		} finally {
			setLoading(false);
			//add condition or state
		}
	}

	function searchClick(value: any) {
		// console.log("Cognitive searchClick value: ", value);
	}

	const handleCollapse = () => {
		setCollapsed(!collapsed);
	};

	// const menuItems = cogData.cogFactorDetails.map((item) => ({
	// 	id: item.id,
	// 	name: item.name,
	// 	link: `/cognitive/${item.id}`,
	// }));

	return (
		<>
			<div className="w-screen shadow-md py-3 pl-9" style={{ zIndex: 99, position: "relative" }}>
				<Link to="/cognitive">
					<span className="cursor-pointer">{icons.find((f) => f.id === "ArrowLeftOutlined")?.icon}</span>
				</Link>
				<span className="text-lg font-medium ml-4">Cognitive Factors</span>
			</div>
			<Layout>
				{/* <SideMenu menuItems={menuItems} collapsed={collapsed} onCollapse={handleCollapse} siderWidth={"15%"} headerText="Summary of Cognitive Insights" /> */}
				<Layout className="bg-white">
					<div className="overflow-y-auto p-4" style={{ height: "calc(100vh - 8rem)" }}>
						<span className="italic text-blue-700 text-sm ml-5">
							For more information on the Cognitive factor or startegies, please connect with the ZS Cognitve team
						</span>
						<div className="flex justify-between ml-5">
							<CognitiveFilters searchWordRequired={false} fetchedFilters={fetchedStudyFilters} searchClick={searchClick} showButton={false} />
						</div>
						{!loading ? (
							<div>
								<WikiPageCard loading={loading} data={wikiData} topics={topicsData} />
							</div>
						) : (
							<div className="m-4 p-4 bg-white shadow-md shadow-gray-900">
								<Skeleton active />
							</div>
							// <Card className="m-4" loading={loading} />
						)}
					</div>
				</Layout>
			</Layout>
		</>
	);
}

export default WikiPage;
