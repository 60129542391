import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import Card from "antd/lib/card";
import Skeleton from "antd/lib/skeleton";
import List from "antd/lib/list";
import Empty from "antd/lib/empty";
import message from "antd/lib/message";
import moment from "moment";
import "../../style.css";
import { useParams } from "react-router-dom";
import TranscriptsWrapper from "../../../../Components/TransciptsWrapper";
import { Transcripts } from "../../../../Components/SearchEngineCards/Transcripts";
import * as Messages from "../../../../Components/Messages";
import { transcriptDataInter } from "../../../SearchEngine/Search";
import * as OldStoriesServiceHelper from "../../../SearchEngine/ServiceHelpers/oldStories";
import { GetReferencesForEvidence } from "../../../SearchEngine/ServiceHelpers/cognitive";

function WikiEvidenceCard(props: { data: any; loading?: boolean; icon: any; topics: any }) {
	const { id } = useParams();
	const currentStudyIterId = sessionStorage.getItem("studyIterId");
	const cogStoryType = 1;

	const [transcriptLoading, setTranscriptLoading] = useState<boolean>(false);
	const [transcriptData, setTranscriptData] = useState<transcriptDataInter[]>([]);
	const [emptyTextField, setEmptyTextField] = useState("View transcripts");
	const [expanded, setExpanded] = useState(false);
	const [pinnedStories, setPinnedStories] = useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);
	const [selectedEvidence, setSelectedEvidence] = useState<string>();

	useEffect(() => {
		fetchStoryData();
	}, []);

	useEffect(() => {
		if (props.topics && props.topics.length > 0) {
			fetchReferencesForEvidence(props.topics[0].evidenceName);
		}
	}, [props.topics]);

	// API Request to fetch Cognitive Factor Data
	async function fetchReferencesForEvidence(evidenceName: string) {
		setSelectedEvidence(evidenceName);
		setTranscriptLoading(true);
		try {
			const data: any = await GetReferencesForEvidence(Number(id), Number(currentStudyIterId), evidenceName);

			if (data) {
				setTranscriptData([data]);
				setTranscriptLoading(false);
			} else {
				setTranscriptData([]);
				console.log("Fetch reference for evidence API returned empty data.");
			}
		} catch (error) {
			message.error("Error fetching reference for evidence");
		} finally {
			//add condition or state
		}
	}

	async function fetchStoryData() {
		const data: any = await OldStoriesServiceHelper.getAllStories(cogStoryType);

		if (data) {
			setFetchedStoryData(data);
		}
	}

	const changePinnedStories = (stories: any) => {
		setPinnedStories(stories);
	};

	async function addNewStory(addData: { StoryName: string }) {
		const key = "updatable";
		Messages.loading("Adding Story", key);
		// console.log(addData);
		const data: any = await OldStoriesServiceHelper.addNewStory(addData);
		if (data) {
			var temp: any = [];
			var d = data["Date Created"];
			data["Date Created"] = moment(d).format("DD MMM YYYY");
			temp = [data, ...fetchedStoryData];
			setFetchedStoryData(temp);
			Messages.success("Successfully Created New Story", key);
		} else Messages.error("Failed", key);
	}

	async function addStoryToTranscriptData(transcript: {
		id: any;
		name: any;
		tags: any;
		storiesAdded: any;
		source: number;
		studyId: number;
		trId: number;
		studyName: string;
		geography: string;
		wave: string;
		fileName: string;
		dialogueNo: any;
		callId: any;
		callDate: any;
		callTime: any;
		s3path: any;
		addedToStory: any;
		score: number;
		dialogueAppearedAt: any;
		dialogueLength: any;
		transcriptLength: any;
		transcripts: any;
		patientId: any;
		speaker: any;
		content: any;
		context: string;
		context_preview: string;
		elasticSearchId: any;
		storyDialogues: any;
		therapyStatusId: string;
		notAddedStories: any;
		FileType: string;
	}) {
		const key = "updatable";
		Messages.loading("Adding Transcript to Story", key);
		// console.log("Trans", transcript);
		const data: any = await OldStoriesServiceHelper.addTransToStory(transcript);
		// console.log("Fetched : ", data);
		if (data) {
			Messages.success("Successfully Added Transcript to Story", key);

			// const temp = [...transcriptData];
			const temp = JSON.parse(JSON.stringify(transcriptData)); // Deep copy of transcriptData
			//      console.log("transc", transcriptData, temp, transcript);

			const idx = temp[0].searchResults.findIndex((t: any) => t.callId === transcript.callId && t.dialogueNo === transcript.dialogueNo);

			temp[0].searchResults[idx].storiesAdded = data;
			if (data.length === 0) {
				temp[0].searchResults[idx].addedToStory = false;
			}
			setTranscriptData(temp);

			return true;
		} else {
			Messages.error("Failed", key);
			return false;
		}
	}

	const addToStorySearch = (transcriptData: any, storyId: any) => {
		var request = {
			transcriptData: transcriptData,
			storyId: storyId,
		};
		var add: any = [];
		storyId.forEach((story: any, index: number) => {
			add.push({
				storyId: story,
				storyName: fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)].storyName,
			});
		});
		var trans = {
			id: transcriptData.id,
			name: transcriptData.name,
			tags: transcriptData.tags,
			storiesAdded: add,
			source: 1,
			studyId: transcriptData.studyId,
			trId: transcriptData.trId,
			studyName: transcriptData.studyName,
			geography: transcriptData.geography,
			wave: transcriptData.wave,
			fileName: transcriptData.fileName,
			dialogueNo: transcriptData.dialogueNo,
			callId: transcriptData.callId,
			callDate: transcriptData.callDate,
			callTime: transcriptData.callTime,
			s3path: transcriptData.s3path,
			addedToStory: true,
			score: transcriptData.score,
			dialogueAppearedAt: transcriptData.dialogue_appeared_at,
			dialogueLength: transcriptData.dialogueLength,
			transcriptLength: transcriptData.transcriptLength,
			transcripts: transcriptData.transcripts,
			patientId: transcriptData.patientId,
			speaker: transcriptData.speaker,
			content: transcriptData.content,
			context: transcriptData.context,
			context_preview: transcriptData.context_preview,
			elasticSearchId: transcriptData.elasticSearchId,
			storyDialogues: transcriptData.storyDialogues,
			callTypeId: transcriptData.callTypeId,
			therapyStatusId: transcriptData.therapyStatusId,
			notAddedStories: transcriptData.notAddedStories,
			FileType: transcriptData.FileType,
		};
		const d = addStoryToTranscriptData(trans);
		return d;
	};

	const handleEvidenceClick = (evidenceName: string) => {
		// console.log("Evidence Clicked", evidenceName);
		fetchReferencesForEvidence(evidenceName);
	};

	return (
		<Card
			title={
				<div className="flex content-center">
					{props.data.card_position && props.icon[props.data.card_position] && <img src={props.icon[props.data.card_position]} />}
					<span className="ml-3">{props.data.main_header}</span>
				</div>
			}
			headStyle={{ padding: 0 }}
			className="homepage-card m-2 pt-10 h-96"
			loading={props.loading}
			hoverable={true}
		>
			<div className="flex">
				<div className="w-1/2 pr-2">
					{props.data.left_text && (
						<div className="my-1 text-base">
							<Markdown remarkPlugins={[breaks, gfm]}>{props.data.left_text}</Markdown>
							{props.topics &&
								props.topics.map((topic: any, index: number) => (
									<p
										className={`ml-2 ${topic.evidenceName === selectedEvidence || (index === 0 && !selectedEvidence) ? "font-bold text-lg text-blue-700" : ""}`}
										onClick={() => handleEvidenceClick(topic.evidenceName)}
									>
										{topic.topicOrder}. {topic.evidenceName}
									</p>
								))}
						</div>
					)}
				</div>
				<div className="refernce w-1/2 overflow-y-auto absolute inset-y-3 right-3">
					<TranscriptsWrapper header={"References"} loading={transcriptLoading} noSideSummary={false}>
						{transcriptLoading ? (
							<div>
								<Skeleton active className="py-4 px-8 border-b" />
							</div>
						) : (
							<div>
								<List
									itemLayout="vertical"
									size="small"
									pagination={{
										hideOnSinglePage: true,
										style: {
											float: "left",
										},
										pageSize: 10,
									}}
									dataSource={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
									locale={{
										emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
									}}
									renderItem={(result, index) => (
										<>
											<Transcripts
												data={result}
												expanded={expanded}
												addToStoryApi={addToStorySearch}
												apiResponseData={transcriptData.length > 0 ? transcriptData[0] : null}
												fetchedStoryData={fetchedStoryData}
												fetchStoryData={fetchStoryData}
												addNewStory={addNewStory}
												pinnedStoriesData={pinnedStories}
												changePinnedStories={changePinnedStories}
												transcriptData={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
												tagName={"Search"}
												storyType={cogStoryType}
											/>
										</>
									)}
								/>
							</div>
						)}
					</TranscriptsWrapper>
				</div>
			</div>
		</Card>
	);
}

export default WikiEvidenceCard;
