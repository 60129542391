import React, { useState } from "react";
import {
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	// UploadOutlined,
	DeleteOutlined,
	// CloseCircleOutlined,
	ClockCircleTwoTone,
} from "@ant-design/icons";
import {
	Table,
	Modal,
	// Typography,
	Popconfirm,
	Tooltip,
	message,
	Skeleton,
	Button,
} from "antd";
import { TranscriptModalData } from "../../../../Components/SearchEngineCards/TranscriptModal";
import * as SearchServiceHelper from "../../../SearchEngine/ServiceHelpers/search";
import AppContext from "../../../../store";
// import { Transcripts } from "../../../../Components/SearchEngineCards/Transcripts";
// import axios from "axios";

interface transModalPayloadData {
	transcriptData: any;
	presignedURL: string;
	format: string;
}

export default function TranscriptData(props: {
	isModalOpen: boolean;
	setIsModalOpen: any;
	uploadComponent: (reUploadFilePath: string) => React.ReactNode;
	uploadedCount: number;
	dataSource: any[];
	geographyCreatedBy: string;
	handleDelete: (key: string) => void;
	fetchStudyTranscriptData: () => void;
	waveName: string;
	geoName: string;
}) {
	const context = React.useContext(AppContext);

	// const [loading, setLoading] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [transModalData, setTransModalData] = useState<transModalPayloadData>();
	const [selectedTranscript, setSelectedTranscript] = useState<{
		storyDialogues: any[];
		id: number;
		name: string;
		tags: string[];
		s3path: string;
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory?: boolean;
		patientId: number;
		speaker: string;
		elasticSearchId?: string;
		dialogueNo?: number;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
	} | null>(null);

	// const currentStudyName = sessionStorage.getItem("studyName");

	// useEffect to re fetch data at fixed intervals
	React.useEffect(() => {
		//    console.log("Inside SM Transcripts Data", props.isModalOpen);
		if (props.isModalOpen) {
			const intervalId = setInterval(() => {
				console.log("Refetching SM Transcripts Data");
				props.fetchStudyTranscriptData(); // Fetch data at each interval
			}, 10000); // Interval in milliseconds (10000ms = 10seconds)

			return () => {
				clearInterval(intervalId); // Clear interval on component unmount
			};
		}
	}, [props.isModalOpen]);

	const showModal = () => {
		props.setIsModalOpen(true);
	};

	const handleOk = () => {
		props.setIsModalOpen(false);
	};

	const handleCancel = () => {
		props.setIsModalOpen(false);
	};

	// console.log("Record info in expandedRowRender ->", record);

	const [dataSourceRheumatologist, setDataSourceRheumatologist] = useState([
		{
			key: "1",
			transcriptFile: "Rheumatologist Interview_1_2.20.2022",
			uploadedOn: "2023-07-20 10:00 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "2",
			transcriptFile: "Rheumatologist Interview_2_2.21.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "3",
			transcriptFile: "Rheumatologist Interview_3_2.21.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "4",
			transcriptFile: "Rheumatologist Interview_4_2.21.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: false,
			processed: false,
		},
		{
			key: "5",
			transcriptFile: "Rheumatologist Interview_5_2.21.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "6",
			transcriptFile: "Rheumatologist Interview_6_2.22.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "7",
			transcriptFile: "Rheumatologist Interview_7_2.22.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "8",
			transcriptFile: "Rheumatologist Interview_8_2.22.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "9",
			transcriptFile: "Rheumatologist Interview_9_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "10",
			transcriptFile: "Rheumatologist Interview_10_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "11",
			transcriptFile: "Rheumatologist Interview_11_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "12",
			transcriptFile: "Rheumatologist Interview_12_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "13",
			transcriptFile: "Rheumatologist Interview_13_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "14",
			transcriptFile: "Rheumatologist Interview_14_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "15",
			transcriptFile: "Rheumatologist Interview_15_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "16",
			transcriptFile: "Rheumatologist Interview_16_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "17",
			transcriptFile: "Rheumatologist Interview_17_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "18",
			transcriptFile: "Rheumatologist Interview_18_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "19",
			transcriptFile: "Rheumatologist Interview_19_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "20",
			transcriptFile: "Rheumatologist Interview_20_2.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "21",
			transcriptFile: "Rheumatologist Interview_21_3.5.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "22",
			transcriptFile: "Rheumatologist Interview_22_3.5.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "23",
			transcriptFile: "Rheumatologist Interview_23_3.5.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "24",
			transcriptFile: "Rheumatologist Interview_24_3.6.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "25",
			transcriptFile: "Rheumatologist Interview_25_3.6.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "26",
			transcriptFile: "Rheumatologist Interview_26_3.6.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "27",
			transcriptFile: "Rheumatologist Interview_27_3.8.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "28",
			transcriptFile: "Rheumatologist Interview_28_3.8.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "29",
			transcriptFile: "Rheumatologist Interview_29_3.8.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "30",
			transcriptFile: "Rheumatologist Interview_30_3.10.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "31",
			transcriptFile: "Rheumatologist Interview_31_3.10.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "32",
			transcriptFile: "Rheumatologist Interview_32_3.10.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "33",
			transcriptFile: "Rheumatologist Interview_33_3.10.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "34",
			transcriptFile: "Rheumatologist Interview_34_3.10.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
	]);

	const [dataSourceOncologist, setDataSourceOncologist] = useState([
		{
			key: "1",
			transcriptFile: "Oncology CM Interview_1_4.11.2022",
			uploadedOn: "2023-07-20 10:00 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "2",
			transcriptFile: "Oncology CM Interview_2_4.13.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "3",
			transcriptFile: "Oncology CM Interview_3_4.13.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "4",
			transcriptFile: "Oncology CM Interview_4_4.14.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "5",
			transcriptFile: "Oncology CM Interview_5_4.14.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: false,
			processed: false,
		},
		{
			key: "6",
			transcriptFile: "Oncology CM Interview_6_4.15.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "7",
			transcriptFile: "Oncology CM Interview_7_4.15.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "8",
			transcriptFile: "Oncology CM Interview_8_4.15.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "9",
			transcriptFile: "Oncology CM Interview_9_4.20.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "10",
			transcriptFile: "Oncology CM Interview_10_4.24.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "11",
			transcriptFile: "Oncology CM Interview_11_4.30.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "12",
			transcriptFile: "Oncology CM Interview_12_5.3.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "13",
			transcriptFile: "Oncology CM Interview_13_5.11.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
		{
			key: "14",
			transcriptFile: "Oncology CM Interview_14_5.15.2022",
			uploadedOn: "2023-07-20 11:30 AM",
			uploaded: true,
			qc: true,
			processed: true,
		},
	]);

	const handleDeleteTranscript = (key: string) => {
		//    console.log("Transcript deleted with key -> ", key);
		// Find the index of the transcript to be deleted
		// const dataIndex = dataSourceRheumatologist.findIndex(
		//   (item) => item.key === key
		// );
		// if (dataIndex !== -1) {
		//   // Remove the transcript from the dataSourceRheumatologist
		//   setDataSourceRheumatologist((prevData) =>
		//     prevData.filter((item) => item.key !== key)
		//   );
		// } else {
		//   // If not found in dataSourceRheumatologist, check dataSourceOncologist
		//   setDataSourceOncologist((prevData) =>
		//     prevData.filter((item) => item.key !== key)
		//   );
		// }
	};

	const createReUploadFilePath = (record: any) => {
		let filePath: string =
			process.env.REACT_APP_API_URL +
			"study/UploadToS3?data=" +
			record.transcriptId + // trId
			"|" +
			record.studyIterId + // studyIterId
			"|" +
			(record.studyIterId + "/transcript") + // upload s3 path (studyIterId/transcript)
			"|" +
			-1 + // FileId
			"|" +
			"false"; // true for guide, false for transcript
		filePath = filePath.replace(/\s/g, "_");

		// console.log("--------------", filePath);

		return filePath;
	};

	// const handleReUploadTranscript = (key:string) => {
	//   // Find the index of the transcript to be deleted
	//   return props.uploadComponent;
	// };

	// COLUMNS FOR HARD CODED DATA SOURCE
	// const columns = [
	//   {
	//     title: "Transcript File",
	//     dataIndex: "transcriptFile",
	//     key: "transcriptFile",
	//     width: 400,
	//   },
	//   {
	//     title: "Uploaded On",
	//     dataIndex: "uploadedOn",
	//     key: "uploadedOn",
	//     width: 300,
	//   },
	//   {
	//     title: "Uploaded",
	//     dataIndex: "uploaded",
	//     key: "uploaded",
	//     width: 100,
	//     render: (uploaded: boolean) => (
	//       <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
	//         {uploaded ? (
	//           <CheckCircleTwoTone twoToneColor="#45C03D" />
	//         ) : (
	//           <Tooltip title="Document is not in English.">
	//             <CloseCircleTwoTone twoToneColor="#E9364C" />
	//           </Tooltip>
	//         )}
	//       </div>
	//     ),
	//   },
	//   {
	//     title: "QC'd",
	//     dataIndex: "qc",
	//     key: "qc",
	//     width: 50,
	//     render: (qc: boolean) => (
	//       <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
	//         {qc ? (
	//           <CheckCircleTwoTone twoToneColor="#45C03D" />
	//         ) : (
	//           // <Tooltip title="Document is not in English.">
	//             <CloseCircleTwoTone twoToneColor="#E9364C" />
	//           // </Tooltip>
	//         )}
	//       </div>
	//     ),
	//   },
	//   {
	//     title: "Processed",
	//     dataIndex: "processed",
	//     key: "processed",
	//     width: 100,
	//     render: (processed: boolean) => (
	//       <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
	//         {processed ? (
	//           <CheckCircleTwoTone twoToneColor="#45C03D" />
	//         ) : (
	//           // <Tooltip title="Document is not in English.">
	//             <CloseCircleTwoTone twoToneColor="#E9364C" />
	//           // </Tooltip>
	//         )}
	//       </div>
	//     ),
	//   },
	//   {
	//     title: "",
	//     dataIndex: "operation",
	//     key: "operation",
	//     render: (_: any, record: any) => {
	//       const hasFalseValue = !record.uploaded || !record.qc || !record.processed;
	//       return (
	//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
	//           {hasFalseValue && (
	//             <Typography.Link onClick={() => handleReUploadTranscript(record.key)} style={{ marginRight: '20px' }}>
	//               <UploadOutlined style={{ color: "#27A6A4", fontWeight: "bold" }} />
	//             </Typography.Link>
	//           )}

	//           <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteTranscript(record.key)}>
	//             <a>
	//               <DeleteOutlined style={{ color: "#27A6A4", fontWeight: "bold" }} />
	//             </a>
	//           </Popconfirm>
	//         </div>
	//       );
	//     },
	//   },
	// ];

	// COLUMNS FOR DATA SOURCE FETCHED FROM BACKEND
	const columns = [
		{
			title: "Files",
			dataIndex: "filename",
			key: "filename",
			// width: 400,
			render: (text: string, record: any) => <a onClick={() => handleFileClick(record)}>{record.filename}</a>,
		},
		{
			title: "Uploaded On",
			dataIndex: "uploadedOn",
			key: "uploadedOn",
			width: 150,
		},
		{
			title: "Uploaded",
			dataIndex: "isUploaded",
			key: "isUploaded",
			width: 100,
			render: (isUploaded: boolean, record: any) => (
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					{isUploaded ? (
						<CheckCircleTwoTone twoToneColor="#45C03D" />
					) : (
						<Tooltip title={record.uploadErrorText}>
							<CloseCircleTwoTone twoToneColor="#E9364C" />
						</Tooltip>
					)}
				</div>
			),
		},
		{
			title: "QC'd",
			dataIndex: "isQCed",
			key: "isQCed",
			width: 50,
			render: (isQCed: boolean, record: any) => (
				// NEW LOGIC
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					{record.parseStatus === null || record.parseStatus === "IN PROGRESS" ? (
						<ClockCircleTwoTone />
					) : record.parseStatus === "ERROR" ? (
						<Tooltip title={record.qCErrorText}>
							<CloseCircleTwoTone twoToneColor="#E9364C" />
						</Tooltip>
					) : record.parseStatus === "PARSED" ? (
						<CheckCircleTwoTone twoToneColor="#45C03D" />
					) : null}
				</div>
			),
		},
		{
			title: "Processed",
			dataIndex: "isProcessed",
			key: "isProcessed",
			width: 100,
			render: (isProcessed: boolean, record: any) => (
				// NEW LOGIC
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					{/* {console.log("--------------", record)} */}
					{record.parseStatus === null || record.parseStatus === "IN PROGRESS" ? (
						<ClockCircleTwoTone />
					) : record.parseStatus === "ERROR" ? (
						<Tooltip title={record.qCErrorText}>
							<CloseCircleTwoTone twoToneColor="#E9364C" />
						</Tooltip>
					) : record.parseStatus === "PARSED" ? (
						<CheckCircleTwoTone twoToneColor="#45C03D" />
					) : null}
				</div>
			),
		},
		{
			title: "",
			dataIndex: "operation",
			key: "operation",
			width: 100,
			render: (_: any, record: any) => {
				const hasFalseValue = !record.isUploaded || !record.isQCed || !record.isProcessed;
				return (
					<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
						{/* {console.log("------------------------", record)} */}
						{
							record.parseStatus === "ERROR" && props.uploadComponent(createReUploadFilePath(record))
							// <Typography.Link onClick={() => handleReUploadTranscript(record.transcriptId)} style={{ marginRight: '20px' }}>
							//   <UploadOutlined style={{ color: "#27A6A4", fontWeight: "bold" }} />
							// </Typography.Link>
						}

						{/* <Popconfirm title="Sure to delete?" onConfirm={() => props.handleDelete(record.transcriptId)}> */}
						{record.parseStatus !== null && record.parseStatus !== "IN PROGRESS" && (
							<Popconfirm title="Sure to delete?" onConfirm={() => props.handleDelete(record.transcriptId)}>
								{context.userName === props.geographyCreatedBy && (
									<a>
										<DeleteOutlined size={20} style={{ color: "#27A6A4", marginLeft: "10px", verticalAlign: "middle", height: "16px" }} />
									</a>
								)}
							</Popconfirm>
						)}
					</div>
				);
			},
		},
	];

	const handleFileClick = async (transcript: any) => {
		try {
			if (transcript.parseStatus !== "PARSED") {
				return;
			}

			setIsLoading(true);
			setIsModalVisible(true);
			// console.log("handleFileClick Record -> ", transcript);

			if (!transcript.processedTranscriptS3path) {
				throw new Error("Transcript file path is missing.");
			}

			// NEW APPROACH
			const updatedS3Path = transcript.processedTranscriptS3path;

			const transData: any = await SearchServiceHelper.getFullTranscript(updatedS3Path);
			// console.log("transdata" + transData);
			if (!transData) {
				throw new Error("Failed to fetch transcript data.");
			}

			const fullTransData = {
				storyDialogues: [],
				id: transcript.transcriptId,
				name: transcript.transcriptName,
				tags: [],
				s3path: transcript.s3Path,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				addedToStory: false,
				patientId: 0,
				speaker: "Speaker Name",
				elasticSearchId: "",
				dialogueNo: 1,
				transcripts: transData.transcriptData,
			};
			//      console.log("handleFileClick fullTransData -> ", fullTransData);

			setTransModalData(transData);
			setSelectedTranscript(fullTransData);
			setIsLoading(false);
		} catch (error) {
			console.error("Error in handleFileClick:", error);
			message.error("An error occurred while processing the transcript.");
		}
		//  finally {
		//   setIsLoading(false);
		// }
	};

	const handleModalOk = () => {
		setIsModalVisible(false);
		setSelectedTranscript(null);
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
		setSelectedTranscript(null);
	};

	return (
		<>
			<Modal title={`${props.waveName} - ${props.geoName}`} open={props.isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1100} centered footer={null}>
				<div style={{ maxHeight: "60vh", overflowY: "auto" }}>
					<Table
						// PROPS WHEN DATA SOURCE HARD CODED AT FRONTEND
						// dataSource={currentStudyName === "RA Market Landscape" ? dataSourceRheumatologist.slice(0, props.uploadedCount) : dataSourceOncologist.slice(0, props.uploadedCount)}
						// rowKey="key"

						// PROPS WHEN DATA SOURCE FETCHED FROM BACKEND
						dataSource={props.dataSource}
						rowKey="transcriptId"
						columns={columns}
						pagination={false}
						// onRow={(record) => {
						//   return {
						//     onClick: () => handleFileClick(record), // Trigger modal on row click
						//   };
						// }}
					/>
					<Modal
						title={selectedTranscript?.name}
						width={1500}
						bodyStyle={{
							height: "600px",
							overflowY: "auto",
						}}
						centered
						open={isModalVisible}
						onOk={handleModalOk}
						onCancel={handleModalClose}
						className="modal-footer-left text-left"
						footer={[
							<Button type="primary" className="green-button items-start mr-6" onClick={handleModalClose}>
								Close
							</Button>,
						]}
					>
						{isLoading ? (
							<>
								<Skeleton active />
								<Skeleton active />
								<Skeleton active />
							</>
						) : (
							selectedTranscript &&
							transModalData && (
								// console.log("Inside TranscriptData, Transcripts child component is called.")
								// <Transcripts
								//   data={selectedTranscript}
								//   expanded={false} // Pass other necessary props
								// />
								<TranscriptModalData
									id={selectedTranscript.id}
									// fullTranscriptData={selectedTranscript.transcripts}
									transPayloadData={transModalData}
									// formatFlag="transcript"
								/>
							)
						)}
						{/* {selectedTranscript && (
              // console.log("Inside TranscriptData, Transcripts child component is called.")
              <Transcripts
                data={selectedTranscript}
                expanded={true} // Pass other necessary props
              />
            )} */}
					</Modal>
				</div>
			</Modal>
		</>
	);
}
