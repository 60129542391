import React, { useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import Card from "antd/lib/card";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import { ICognitiveCardProps, ISectionItemProps } from "../../model/interface/components";
import "./style.css";
import { getFullTranscript } from "../../Pages/SearchEngine/ServiceHelpers/search";
import { TranscriptModalData } from "../SearchEngineCards/TranscriptModal";

const Section: React.FC<ISectionItemProps> = ({ type, value }) => {
	const [transData, setTransData] = useState<any>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (value?.image && value.image !== null) {
					const data: any = await getFullTranscript(value?.image ?? "");
					setTransData(data);
				}
			} catch (error) {
				console.error("Error fetching image:", error);
			}
		};

		if (type === "image") {
			fetchData();
		}
	}, [type, value?.image]);

	switch (type) {
		case "text":
			return (
				<>
					{value?.subHeader && <p className="text-lg font-bold mb-1">{value.subHeader}</p>}
					{value?.text && (
						<div className="my-1 text-base">
							<Markdown remarkPlugins={[breaks, gfm]}>{value.text}</Markdown>
						</div>
					)}
				</>
			);
		case "image":
			return value?.image === null ? (
				<></>
			) : (
				<div className="bg-gray-100 absolute inset-y-3 overflow-hidden img-class">{transData && <TranscriptModalData id={0} transPayloadData={transData} />}</div>
			);
		default:
			return null;
	}
};

const CognitiveCard: React.FC<ICognitiveCardProps> = (props) => {
	const {
		config: { title, headStyle, className, loading, hoverable, contentStyle, bodyStyle, actions, extra, bordered, onClick, left, right },
	} = props;

	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	const hasLeftContent = useMemo(() => left?.some((item) => item.value?.text || item.value?.image), [left]);
	const hasRightContent = useMemo(() => right?.some((item) => item.value?.text || item.value?.image), [right]);

	return (
		<Card
			title={title}
			headStyle={headStyle}
			className={className}
			id="defaultId"
			loading={loading}
			hoverable={hoverable}
			bodyStyle={bodyStyle}
			actions={actions}
			extra={extra}
			bordered={bordered}
			onClick={handleClick}>
			<div className={contentStyle}>
				{hasLeftContent && (
					<div className={`${!hasRightContent ? "w-full" : "w-1/2"} pr-2`}>
						{left?.map((item, index) => (
							<Section key={index} type={item.type} value={item.value} />
						))}
					</div>
				)}
				{hasRightContent && (
					<div className="w-1/2 pr-2">
						{right?.map((item, index) => (
							<Section key={index} type={item.type} value={item.value} />
						))}
					</div>
				)}
			</div>
		</Card>
	);
};

export default CognitiveCard;
