const basicLineChartConfig = {
	chart: {
		type: "line",
		events: {
			click: function (event: any) {
				if (event.point) {
					console.log("Time Evolution Clicked point:", event.point);
				}
			},
		},
	},
	title: {
		text: "",
	},
	xAxis: {
		categories: ["Jan", "Feb", "Mar", "Apr", "May"],
	},
	yAxis: {
		title: {
			text: "Insights Count",
		},
	},
	credits: {
		enabled: false,
	},
};

export default basicLineChartConfig;
