// ImageRenderer.tsx
import React, { MouseEvent, useEffect, useState } from "react";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";
import { Spin } from "antd";

interface IImageRendererProps {
	setIsMarkdownModalVisible: (isMarkdownModalVisible: boolean) => void;
	setMarkdownContentS3Path: (markdownContentS3Path: string | undefined) => void;
}

interface IRenderProps {
	src: string;
	alt: string;
}

export const ImageRenderer = ({ setIsMarkdownModalVisible, setMarkdownContentS3Path }: IImageRendererProps) => {
	const RenderImage = ({ src, alt }: IRenderProps) => {
		const [compressedImagePath, setCompressedImagePath] = useState<string | undefined>(undefined);
		const [isCompressedImageRendered, setIsCompressedImageRendered] = useState<boolean>(false);

		useEffect(() => {
			handleClickGetFullTrans(src);
		});

		const handleClickGetFullTrans = async (s3Path: string | undefined) => {
			const transData: any = await SearchServiceHelper.getFullTranscript(s3Path ? s3Path : "");
			if (transData) {
				setCompressedImagePath(transData.presignedURL);
				setIsCompressedImageRendered(true);
			}
		};

		const handleClick = (e: MouseEvent<HTMLImageElement>, altS3Path?: string) => {
			e.preventDefault();
			setIsMarkdownModalVisible(true);
			setMarkdownContentS3Path(altS3Path);
		};

		return isCompressedImageRendered ? (
			<img src={compressedImagePath} alt={"Media Transcript"} onClick={(e) => handleClick(e, alt)} />
		) : (
			<div>
				<Spin />
			</div>
		);
	};

	return RenderImage;
};
