import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "antd/lib/layout";
import message from "antd/lib/message";
import HomePageCard from "./HomePageCard";
import DecisionMakingGraph from "./DecisionMakingGraph";
// import { DiscoverFilters } from "../../../../Components/DiscoverCards/DiscoverFilters";
import { CognitiveFilters } from "../../../../Components/CognitiveFilters";
import { getStudiesForDiscover } from "../../../SearchEngine/ServiceHelpers/nodeGraph";
import { GetAllCognitiveFactor } from "../../../SearchEngine/ServiceHelpers/cognitive";
import Loading from "../../../../Components/Loading";
import { emptyGraphObject } from "./DecisionMakingGraphEmptyObj";
import { ICognitiveHomePageData } from "../../../../model/interface/cognitive";
import SideMenu from "../../../../Components/Sider";
import { selectStore, setCognitiveHomeData } from "../../../../state/slice/appSlice";
import "../../style.css";
import Card from "antd/lib/card";
import AppContext from "../../../../store";
import { CognitiveModuleMessages, ApiErrorMessages } from "../../../../constants/messages";

const { Header, Content } = Layout;

export default function HomePage() {
	const context = React.useContext(AppContext);
	const store = useSelector(selectStore);
	const dispatch = useDispatch();

	// const [cognitiveData, setCognitiveData] = useState<ICognitiveHomePageData | undefined>({
	// 	parentSummary: "",
	// 	cogFactorDetails: [
	// 		{
	// 			id: 0,
	// 			name: "",
	// 			priority: 0,
	// 			priorityClass: "",
	// 			definition: "",
	// 			topics: [
	// 				{
	// 					evidenceName: "",
	// 					evidenceSummary: "",
	// 					topicOrder: 0,
	// 				},
	// 			],
	// 		},
	// 	],
	// });
	const [cognitiveData, setCognitiveData] = useState<ICognitiveHomePageData | undefined>(undefined);
	const [graphData, setGraphData] = useState<any>([]);
	const [isEmpty, setIsEmpty] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fetchedStudyData, setFetchedStudyData] = useState<any>();
	// const [selectedStudyDetails, setSelectedStudyDetails] = useState<any>();
	const [sideDrawerCollapsed, setSideDrawerCollapsed] = useState<boolean>(true);
	const [highlightedCardId, setHighlightedCardId] = useState(null);

	useEffect(() => {
		fetchDropdownStudyData();
		if (store.cognitiveTab.cognitiveHomeData) {
			setCognitiveData(store.cognitiveTab.cognitiveHomeData);
			setIsEmpty(false);
			setSideDrawerCollapsed(false);
		}
	}, []);

	useEffect(() => {
		const updatedGraphData = fillGraphObjectData(emptyGraphObject, cognitiveData);
		setGraphData(updatedGraphData);
	}, [cognitiveData]);

	const handleCardHighlight = (id: any) => {
		setHighlightedCardId(id);
		setTimeout(() => {
			setHighlightedCardId(null); // Reset the highlighted card after 3 seconds
		}, 10000);
	};

	const fetchedStudyFilters = fetchedStudyData
		? [
				{
					optionsObject: fetchedStudyData.map((item: { studyId: number; studyName: string }) => ({
						id: item.studyId,
						// id: item.studyId.toString(),
						name: item.studyName,
						subgroup: [],
					})),
					filter: "Study Name",
					type: "select",
				},
		  ]
		: [
				{
					optionsObject: [{}],
					filter: "Study Name",
					type: "select",
				},
		  ];

	function fillGraphObjectData(emptyGraphObject: any, data: any) {
		const filledGraphObject = emptyGraphObject.reduce((acc: any, graphItem: any) => {
			const correspondingItem = data && data.cogFactorDetails && data.cogFactorDetails.find((factor: any) => factor.id === graphItem.id);
			if (correspondingItem) {
				const topicsSummary = correspondingItem.topics.map((topic: { evidenceName: string }) => topic.evidenceName).join(", ");
				const concatSummary = (context.envId && CognitiveModuleMessages[context.envId].GRAPH_SUMMARY_PREFIX) + ` ${topicsSummary}`;
				acc.push({
					...graphItem,
					fill: correspondingItem.priorityClass === (context.envId && CognitiveModuleMessages[context.envId].GRAPH_PRIORITY_CLASS_1) ? "#00b050" : "#ffc000",
					text: correspondingItem.priority.toString(),
					name: correspondingItem.name,
					summary: concatSummary,
					linkId: correspondingItem.id,
				});
			}
			return acc;
		}, []);
		return filledGraphObject;
	}

	// API Request to fetch Study Dropdown Data
	async function fetchDropdownStudyData() {
		try {
			const data: any = await getStudiesForDiscover();

			if (data) {
				setFetchedStudyData(data);
				//message.success("Successfully fetched Study Data");
			} else {
				console.log("No Study data available");
			}
		} catch (error) {
			message.error(ApiErrorMessages.UNEXPECTED_ERROR);
			console.error("Error fetching Study Dropdown Data:", error);
		} finally {
			//add condition or state
		}
	}

	// API Request to fetch Cognitive Factor Data
	async function fetchAllCognitiveFactor(studyIterId: number) {
		setIsLoading(true);
		try {
			const data: any = await GetAllCognitiveFactor(studyIterId);

			if (data && data.cogFactorDetails && data.cogFactorDetails.length > 0) {
				setCognitiveData(data);
				dispatch(setCognitiveHomeData(data));
				setSideDrawerCollapsed(false);
				// message.success("Cognitive data loaded");
			} else {
				message.error(ApiErrorMessages.NO_DATA_AVAILABLE);
				setIsEmpty(true);
			}
		} catch (error) {
			message.error(ApiErrorMessages.UNEXPECTED_ERROR);
		} finally {
			setIsLoading(false);
		}
	}

	function searchClick(value: any) {
		console.log("Cognitive searchClick value: ", value);
		if (!value.studyIterId) {
			message.error(context.envId && CognitiveModuleMessages[context.envId].SHOW_INSIGHTS_VALIDATION);
		} else {
			sessionStorage.setItem("studyIterId", value.studyIterId);
			setIsEmpty(false);
			// setSelectedStudyDetails(value);
			fetchAllCognitiveFactor(value.studyIterId);
		}
	}

	const handleCollapse = () => {
		setSideDrawerCollapsed(!sideDrawerCollapsed);
	};

	const menuItems =
		cognitiveData && cognitiveData.cogFactorDetails && cognitiveData.cogFactorDetails.length > 0
			? cognitiveData.cogFactorDetails.map((item) => ({
					id: item.id,
					name: item.name,
					// link: `/cognitive/${item.id}`,
			  }))
			: [];

	return (
		<Layout>
			<Header className="smoky-cloudy-header h-14 px-4 py-2 flex justify-between items-center">
				<h1 className="text-2xl font-bold text-white">Cognitive Finding Framework</h1>
			</Header>
			<Layout>
				{!isLoading && (
					<SideMenu
						menuItems={menuItems}
						collapsed={sideDrawerCollapsed}
						onCollapse={handleCollapse}
						siderWidth={"15%"}
						headerText="Summary of Cognitive Insights"
						pageScroll={true}
						handleCardHighlight={handleCardHighlight}
					/>
				)}
				<Content className="bg-white">
					<div className="header px-4 py-2">
						<p style={{ marginBottom: "-10px", fontStyle: "italic" }}>*Please select Study, Wave & Region to see insights</p>
						<div className="flex justify-between items-end">
							<CognitiveFilters
								showDrawer={() => {}}
								fetchTranscripts={() => {}}
								searchWordRequired={false}
								searchString={null}
								setSearchString={null}
								fetchedFilters={fetchedStudyFilters}
								searchClick={searchClick}
							/>
						</div>
					</div>
					<div className="overflow-y-auto" style={{ height: "calc(100vh - 24.5vh)" }}>
						{isEmpty ? (
							<></>
						) : (
							<>
								{!isLoading && cognitiveData ? (
									<>
										<Card className="mx-7 mt-4 decision-graph">
											<div>
												<p className="text-base mb-5">{cognitiveData.parentSummary}</p>
												<DecisionMakingGraph data={graphData} />
											</div>
										</Card>
										<HomePageCard loading={isLoading} data={cognitiveData.cogFactorDetails} highlightedCardId={highlightedCardId} />
									</>
								) : (
									<Loading containerClassName={"px-8"} skeletonActive={true} skeletonRows={0} spinClassName={"my-24"} />
								)}
							</>
						)}
					</div>
				</Content>
			</Layout>
		</Layout>
	);
}
