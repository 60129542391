import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Select, Button, message } from "antd";
import * as StudyServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/study";
import { IStudyData, ICreateStudyFormProps } from "../../model/interface/study";
import { ApiErrorMessages, SharedMessages, StudyModuleMessages } from "../../constants/messages";
import AppContext from "../../store";

const { Option } = Select;

const CreateStudyForm: React.FC<ICreateStudyFormProps> = ({ handleOk, handleCancel }) => {
	const context = useContext(AppContext);

	const [form] = Form.useForm();
	const [studyTypeOptions, setStudyTypeOptions] = useState<any[]>([]);
	const [studyList, setStudyList] = useState<any[]>([]);
	const initialiseStudyData: IStudyData = {
		studyTypeId: undefined!,
		studyName: "",
		studyTypeName: "",
		studyDescription: "",
	};
	form.setFieldsValue(initialiseStudyData);
	const userProfileName = context.userProfile ? context.userProfile.displayName ? context.userProfile.displayName.toLowerCase() : "default" : "default";

	useEffect(() => {
		form.resetFields();
		fetchDataForPage();
	}, []);

	async function fetchDataForPage() {
		try {
			const data: any = await StudyServiceHelper.getStudyTypes();
			if (data) {
				setStudyTypeOptions(data);
			}

			const studyListData: any = await StudyServiceHelper.getStudies();
			if (studyListData) {
				setStudyList(studyListData);
			}
		} catch (error) {
			console.error(ApiErrorMessages.FETCH_ERROR, error);
			message.error(ApiErrorMessages.FETCH_ERROR);
		}
	}

	const handleFinish = async (values: IStudyData) => {
		try {
			await StudyServiceHelper.addNewStudy(values);
			handleOk(values);
		} catch (error) {
			console.error(ApiErrorMessages.ADDING_ERROR, error);
			message.error(ApiErrorMessages.ADDING_ERROR);
		}
	};

	const validateStudyName = (rule: any, value: string) => {
		const studyNameExists = studyList.some((study) => study.studyName === value);
		if (studyNameExists) {
			return Promise.reject(new Error(context.envId && SharedMessages[context.envId].DUPLICATE_VALIDATION_ERROR));
		}

		const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
		if (specialCharsRegex.test(value)) {
			return Promise.reject(new Error(context.envId && SharedMessages[context.envId].SPECIAL_CHAR_VALIDATION_ERROR));
		}

		return Promise.resolve();
	};

	const validateStudyDescription = (rule: any, value: string) => {
		const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
		if (specialCharsRegex.test(value)) {
			return Promise.reject(new Error(context.envId && SharedMessages[context.envId].SPECIAL_CHAR_VALIDATION_ERROR));
		}

		return Promise.resolve();
	};

	return (
		<Form form={form} onFinish={handleFinish} layout="vertical">
			<div className="flex">
				<Form.Item
					// label={context.envId && StudyModuleMessages[context.envId].CREATE_STUDY_NAME_LABEL}
					label={context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].CREATE_STUDY_NAME_LABEL}
					name="studyName"
					rules={[{ required: true, message: context.envId && SharedMessages[context.envId].REQUIRED_FIELD_VALIDATION_ERROR }, { validator: validateStudyName }]}
					className="flex-1"
				>
					{/* <Input style={{ width: "100%" }} placeholder={context.envId && StudyModuleMessages[context.envId].CREATE_STUDY_NAME_LABEL} /> */}
					<Input style={{ width: "100%" }} placeholder={context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].CREATE_STUDY_NAME_LABEL} />
				</Form.Item>
			</div>

			<div className="flex">
				<Form.Item
					// label={context.envId && StudyModuleMessages[context.envId].CREATE_STUDY_TYPE_LABEL}
					label={context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].CREATE_STUDY_TYPE_LABEL}
					name="studyTypeId"
					rules={[{ required: true, message: context.envId && SharedMessages[context.envId].REQUIRED_FIELD_VALIDATION_ERROR }]}
					className="flex-1"
				>
					{/* <Select style={{ width: "200px" }} placeholder={context.envId && StudyModuleMessages[context.envId].CREATE_STUDY_TYPE_LABEL} defaultActiveFirstOption={true}></Select> */}
					<Select style={{ width: "200px" }} placeholder={context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].CREATE_STUDY_TYPE_LABEL} defaultActiveFirstOption={true}>
						{studyTypeOptions.map((option) => (
							<Option key={option.studyTypeId} value={option.studyTypeId}>
								{option.studyTypeName}
							</Option>
						))}
					</Select>
				</Form.Item>
			</div>

			<div>
				<Form.Item
					// label={context.envId && StudyModuleMessages[context.envId].CREATE_STUDY_DESCRIPTION_LABEL}
					label={context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].CREATE_STUDY_DESCRIPTION_LABEL}
					name="studyDescription"
					rules={[{ validator: validateStudyDescription }]}
				>
					{/* <Input.TextArea rows={10} style={{ width: "100%" }} placeholder={context.envId && StudyModuleMessages[context.envId].CREATE_STUDY_DESCRIPTION_LABEL} /> */}
					<Input.TextArea rows={10} style={{ width: "100%" }} placeholder={context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].CREATE_STUDY_DESCRIPTION_LABEL} />
				</Form.Item>
			</div>

			<div className="flex justify-end">
				<Form.Item>
					<Button type="default" onClick={handleCancel}>
						Cancel
					</Button>
					<Button type="primary" htmlType="submit" className="ml-3">
						Submit
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};

export default CreateStudyForm;
