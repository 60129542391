import React from "react";
import { Button, Menu, Dropdown, Spin, message, Form } from "antd";
import NodeGraph from "./components/NodeGraph";
import myTreeData from "./data.json";
import myTreeData2 from "./data2.json";
import * as NodeGraphServiceHelper from "../SearchEngine/ServiceHelpers/nodeGraph";
import * as StudyServiceHelper from "../SearchEngine/ServiceHelpers/study";
import { icons } from "../../GlobalThemes";
import { DiscoverFilters } from "../../Components/DiscoverCards/DiscoverFilters";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import AppContext from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { selectStore, setDiscoverFilters, setDiscoverTreeData } from "../../state/slice/appSlice";
import { store } from "../../state/store";
import { DiscoverModuleMessages } from "../../constants/messages";

interface FetchedStudyFilter {
	optionsObject: {
		id: number;
		name: string;
		studyIterId?: number;
		subgroup: any[];
	}[];
	filter: string;
	type: string;
}

export function Discover() {
	const context = React.useContext(AppContext);
	const store = useSelector(selectStore);
	const dispatch = useDispatch();

	const [treeData, setTreeData] = React.useState<any>();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [fetchedStudyData, setFetchedStudyData] = React.useState<any>();
	const [selectedStudyDetails, setSelectedStudyDetails] = React.useState<any>();

	const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4", alignSelf: "center", flex: 1 }} spin />;
	const sortByName = (a: any, b: any) => a.name.localeCompare(b.name);
	const [form] = Form.useForm();
	const userProfileName = context.userProfile ? context.userProfile.displayName ? context.userProfile.displayName.toLowerCase() : "default" : "default";

	React.useEffect(() => {
		fetchDropdownStudyData();
	}, []);

	React.useEffect(() => {
		if (store.discoverTab.discoverTreeData) {
			setTreeData(store.discoverTab.discoverTreeData);
			setSelectedStudyDetails(store.discoverTab.discoverFilters);
		}
	}, []);

	// API Request to fetch NG Study Dropdown Data
	async function fetchDropdownStudyData() {
		try {
			setLoading(true);
			const data: any = await NodeGraphServiceHelper.getStudiesForDiscover();

			if (data) {
				const sortedData = data.sort((a: any, b: any) => a.studyName.localeCompare(b.studyName));
				setFetchedStudyData(sortedData);
				//message.success("Successfully fetched Study Data");
				// console.log("Fetched Study Dropdown Data -> ", data);
			} else {
				console.log("Fetch Study Data API returned empty data.");
			}
		} catch (error) {
			message.error("Error fetching Study Data");
			console.error("Error fetching Study Dropdown Data:", error);
		} finally {
			setLoading(false);
		}
	}

	// API Request to fetch NG Node Graph Data
	async function fetchNodeTreeData(value: any) {
		try {
			setLoading(true);
			const data: any =
				context.envId && context.envId === 2 ? await NodeGraphServiceHelper.getMedicalNodeJsons(value.map(Number)) : await NodeGraphServiceHelper.getNodeJsons(value);

			if (data) {
				setTreeData(data);
				dispatch(setDiscoverTreeData(data));
			} else {
				console.log("Fetch Node Tree Data API returned empty data.");
			}
		} catch (error) {
			message.error("Error fetching Node Graph Tree Data");
			console.error("Error fetching Node Tree Data:", error);
		} finally {
			setLoading(false);
		}
	}

	const fetchedStudyFilters = fetchedStudyData
		? [
				{
					optionsObject: fetchedStudyData.map((item: { studyId: number; studyName: string }) => ({
						id: item.studyId,
						// id: item.studyId.toString(),
						name: item.studyName,
						subgroup: [],
					})),
					filter: "Study Name",
					type: "select",
				},
		  ]
		: [
				{
					optionsObject: [{}],
					filter: "Study Name",
					type: "select",
				},
		  ];

	function searchClick(value: any) {
		// console.log("REMOVE LATER - Discover searchClick value: ", value);
		setSelectedStudyDetails(value);
		// if (Object.keys(value).length !== 0) {
		// 	dispatch(setDiscoverFilters(value));
		// }
		if (context.envId && context.envId !== 2) {
			let fetchS3Path = `${value.studyIterId}/node_graph/json/node_graph.json`;
			fetchNodeTreeData(fetchS3Path);
		} else {
			fetchNodeTreeData(value.studyIterId);
		}
	}

	const handleDownloadTaxonomy = async (studyIterIds: number[]) => {
		if (studyIterIds.length === 0) return;

		// Assuming message.loading returns a function to dismiss the message, and that's expected
		const loadingMessage = message.loading(`Preparing summaries, please wait...`, 0) as unknown as () => void;

		try {
			// Ensure that the response is treated as a string
			const response = await NodeGraphServiceHelper.getTaxonomyS3DownloadUrl(studyIterIds);

			if (response && typeof response === "string") {
				// Use the pre-signed URL to trigger the download
				const link = document.createElement("a");
				link.href = response;
				link.setAttribute("download", "Topic_Summaries_Data.xlsx"); // Consider using a dynamic file name if provided by the API
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				message.success("Downloaded");
			} else {
				throw new Error("Download URL not received from the server.");
			}
		} catch (error) {
			console.error(`Error downloading taxonomy file:`, error);
			message.error(`Error downloading file. Please try again later.`);
		} finally {
			loadingMessage();
		}
	};

	return (
		<div>
			<div className="header ">
				<div className="mt-2" style={{ marginBottom: "-2px", fontStyle: "italic" }}>
					{/* {"* "}{context.envId && DiscoverModuleMessages[context.envId].REQD_FIELDS_VALIDATION_ERROR} */}
					{"* "}{context.envId && DiscoverModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].REQD_FIELDS_VALIDATION_ERROR}
				</div>
				<div className="flex justify-between items-end">
					<DiscoverFilters
						showDrawer={() => {}}
						fetchTranscripts={() => {}}
						searchWordRequired={false}
						searchString={null}
						setSearchString={null}
						fetchedFilters={fetchedStudyFilters}
						searchClick={searchClick}
					/>

					<div className="pb-4">
						{context.envId === 2 && (
							<Form>
								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="green-button flex items-center ml-auto"
										icon={<DownloadOutlined />}
										onClick={() => handleDownloadTaxonomy(selectedStudyDetails.studyIterId)}
										disabled={selectedStudyDetails && selectedStudyDetails.studyIterId ? false : true}
									>
										Export Topic Summaries
									</Button>
								</Form.Item>
							</Form>
						)}
					</div>
				</div>
			</div>

			{loading ? (
				<div style={{ textAlign: "center", marginTop: "240px", flex: 1, justifyContent: "center", alignItems: "center" }}>
					<Spin indicator={antIcon} />
					<p>Loading...</p>
				</div>
			) : (
				treeData && <NodeGraph data={treeData} selectedStudyDetails={selectedStudyDetails} />
			)}
		</div>
	);
}
