const sentimentChartConfig = {
	chart: {
		type: "column",
	},
	title: {
		text: "Sentiment Analysis",
	},
	xAxis: {
		categories: [],
		crosshair: true,
	},
	yAxis: [
		{
			min: 0,
			title: {
				text: "Insights Count",
			},
		},
		{
			title: {
				text: "Avg. Sentiment Score",
			},
			opposite: true,
		},
	],
	tooltip: {
		shared: true,
	},
	legend: {
		enabled: false,
	},
	series: [
		{
			type: "column",
			name: "Insights Count",
			data: [],
		},
		{
			type: "line",
			name: "Average Sentiment Score",
			data: [],
			yAxis: 1,
			marker: {
				lineWidth: 2,
				fillColor: "white",
			},
		},
	],
	credits: {
		enabled: false,
	},
};

export default sentimentChartConfig;
