/**
 * This file contains environment-specific messages used in the application.
 *
 * Each environment ID corresponds to a set of messages that can be used to customize text based on the current environment.
 *
 * @remarks
 * This file is part of the constants of the application and provides a centralized location for all environment-specific messages.
 *
 * @param envId - The environment ID used to select the appropriate set of messages.
 *
 * @returns The set of messages corresponding to the provided environment ID.
 */

export const ApiErrorMessages = {
	FETCH_ERROR: "An error occurred while fetching data. Please try again.",
	NETWORK_ERROR: "A network error occurred. Please check your connection and try again.",
	SERVER_ERROR: "A server error occurred. Please try again later.",
	AUTH_ERROR: "You are not authorized to perform this action. Please log in and try again.",
	NOT_FOUND: "The requested resource could not be found. Please check the URL and try again.",
	UNEXPECTED_ERROR: "An unexpected error occurred. Please try again later.",
	NO_DATA_AVAILABLE: "No data is currently available. Please try again later.",
	ADDING_ERROR: "An error occurred while adding data. Please try again.",
	DELETING_ERROR: "An error occurred while deleting data. Please try again.",
	DOWNLOAD_ERROR: "An error occurred while downloading data. Please try again.",
};

export const SharedMessages = {
	1: {
		QUERY_PLACEHOLDER: "Search for transcript dialogues with keywords and tags",
		SPECIAL_CHAR_VALIDATION_ERROR: "Special characters are not allowed. Please use only alphanumeric characters and spaces.",
		REQUIRED_FIELD_VALIDATION_ERROR: "Required field. Please provide a value.",
		DUPLICATE_VALIDATION_ERROR: "Value already exists. Please provide a unique value.",
		TYPE_MISMATCH_VALIDATION_ERROR: "Type mismatch. Please provide a value of the same type.",
	},
	2: {
		QUERY_PLACEHOLDER: "Search with query, keywords, or tags",
		SPECIAL_CHAR_VALIDATION_ERROR: "Special characters are not allowed. Please use only alphanumeric characters and spaces.",
		REQUIRED_FIELD_VALIDATION_ERROR: "Required field. Please provide a value.",
		DUPLICATE_VALIDATION_ERROR: "Value already exists. Please provide a unique value.",
		TYPE_MISMATCH_VALIDATION_ERROR: "Type mismatch. Please provide a value of the same type.",
	},
	3: {
		QUERY_PLACEHOLDER: "Search for transcript dialogues with keywords and tags",
		SPECIAL_CHAR_VALIDATION_ERROR: "Special characters are not allowed. Please use only alphanumeric characters and spaces.",
		REQUIRED_FIELD_VALIDATION_ERROR: "Required field. Please provide a value.",
		DUPLICATE_VALIDATION_ERROR: "Value already exists. Please provide a unique value.",
		TYPE_MISMATCH_VALIDATION_ERROR: "Type mismatch. Please provide a value of the same type.",
	},
};

export const HomepageModuleMessages = {
	1: {
		HERO_TITLE: "Knowledge Engine",
		HERO_CONTENT:
			"Kural's engine uses generative AI to understand, interpret and synthesize conversations and expressions that are captured in transcripts, voice calls, or text documents. Kural enables users to go beyond traditional unstructured data and voice call analytics by allowing them to interact in real time & ask questions. \n\n Kural is still an AI intern and like all generative AI-based capabilities, she is meant to assist and enhance human capabilities, but her responses should never replace human judgment, expertise, or ethical considerations.",
	},
	2: {
		HERO_TITLE: "Medical Insights Engine",
		HERO_CONTENT:
			"Kural's engine uses generative AI to understand, interpret and synthesize unstructured contents and expressions that are captured in MSL Notes, MedInfo, AdBoards or social media data. Kural enables users to go beyond traditional data analytics by allowing them to interact in real time & ask questions.",
	},
	3: {
		HERO_TITLE: "Knowledge Engine",
		HERO_CONTENT:
			"Kural's engine uses generative AI to understand, interpret and synthesize conversations and expressions that are captured in transcripts, voice calls, or text documents. Kural enables users to go beyond traditional unstructured data and voice call analytics by allowing them to interact in real time & ask questions. \n\n Kural is still an AI intern and like all generative AI-based capabilities, she is meant to assist and enhance human capabilities, but her responses should never replace human judgment, expertise, or ethical considerations.",
	},
};

export const DiscoverModuleMessages = {
	1: { // envId
		1: { // userProfile === Global
			DROPDOWN_2_TEXT: "Wave",
			DROPDOWN_2_PLACEHOLDER: "Select Wave",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Wave & Region to see insights."
		},
		2: { // userProfile === Europe
			DROPDOWN_2_TEXT: "Wave",
			DROPDOWN_2_PLACEHOLDER: "Select Wave",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Wave & Region to see insights."
		},
		3: { // userProfile === <others>
			DROPDOWN_2_TEXT: "Wave",
			DROPDOWN_2_PLACEHOLDER: "Select Wave",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Wave & Region to see insights."
		},
	},
	2: { // envId === Medical
		1: { // userProfile === Global
			DROPDOWN_2_TEXT: "Therapy Area",
			DROPDOWN_2_PLACEHOLDER: "Select Therapy Area",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Therapy Area & Geography to see insights."
		},
		2: { // userProfile === Europe
			DROPDOWN_2_TEXT: "Clinical Trial",
			DROPDOWN_2_PLACEHOLDER: "Select Clinical Trial",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Clinical Trial & Geography to see insights."
		},
		3: { // userProfile === <others>
			DROPDOWN_2_TEXT: "Time Frame",
			DROPDOWN_2_PLACEHOLDER: "Select Time Frame",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Time Frame & Geography to see insights."
		},
	},
	3: { // envId
		1: { // userProfile === Global
			DROPDOWN_2_TEXT: "Wave",
			DROPDOWN_2_PLACEHOLDER: "Select Wave",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Wave & Region to see insights."
		},
		2: { // userProfile === Europe
			DROPDOWN_2_TEXT: "Wave",
			DROPDOWN_2_PLACEHOLDER: "Select Wave",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Wave & Region to see insights."
		},
		3: { //	userProfile === <others>
			DROPDOWN_2_TEXT: "Wave",
			DROPDOWN_2_PLACEHOLDER: "Select Wave",
			REQD_FIELDS_VALIDATION_ERROR: "Please select Study, Wave & Region to see insights."
		},
	},
};

export const SearchModuleMessages = {
	1: {
		FILTERS_TITLE: "Search Transcripts",
		SEARCH_IN_INFO_TOOLTIP:
			"**Transcripts:** Choose this option to search within market research transcripts files only such as docx, txt, mp3, etc... \n **Reports:** Choose this option to search within summary insight files only such as PDFs and PowerPoints.",
		ENABLE_DEEP_SEARCH_INFO_TOOLTIP:
			"Enable deep search to compare insights across various data segments like wave, geographies, specialties, channels, tiers or other available metadata types.",
		SEARCH_STREAMING_IN_PROGRESS: "Streaming in progress. Please wait...",
		STUDY_TYPE_MISMATCH_VALIDATION_ERROR: "Please select only one type of study at a time: either multiple Medical Notes or multiple Medical Reports.",
	},
	2: {
		FILTERS_TITLE: "Search Insights and Reports",
		SEARCH_IN_INFO_TOOLTIP:
			"**Notes:** Choose this option to search within structured insight files only such as Excels and CSVs. \n **Reports:** Choose this option to search within summary insight files only such as PDFs and PowerPoints.",
		ENABLE_DEEP_SEARCH_INFO_TOOLTIP:
			"Enable deep search to compare insights across various data segments like timeframes, geographies, specialties, channels, tiers or other available metadata types.",
		SEARCH_STREAMING_IN_PROGRESS: "Streaming in progress. Please wait...",
		STUDY_TYPE_MISMATCH_VALIDATION_ERROR: "Please select only one type of study at a time: either multiple Medical Notes or multiple Medical Reports.",
	},
	3: {
		FILTERS_TITLE: "Search Transcripts",
		SEARCH_IN_INFO_TOOLTIP:
			"**Transcripts:** Choose this option to search within market research transcripts files only such as docx, txt, mp3, etc... \n **Reports:** Choose this option to search within summary insight files only such as PDFs and PowerPoints.",
		ENABLE_DEEP_SEARCH_INFO_TOOLTIP:
			"Enable deep search to compare insights across various data segments like wave, geographies, specialties, channels, tiers or other available metadata types.",
		SEARCH_STREAMING_IN_PROGRESS: "Streaming in progress. Please wait...",
		STUDY_TYPE_MISMATCH_VALIDATION_ERROR: "Please select only one type of study at a time: either multiple Medical Notes or multiple Medical Reports.",
	},
};

export const CognitiveModuleMessages = {
	1: {
		SHOW_INSIGHTS_VALIDATION: "Please ensure you have selected a Study, Wave, and Region to view insights.",
		GRAPH_SUMMARY_PREFIX: "This cognitive factor has these sub-topics:",
		GRAPH_PRIORITY_CLASS_1: "Core Decision Factors",
		NO_WAVE_FOUND: "No wave data found for the selected study. Please select a different study or try again later.",
		NO_REGION_FOUND: "No region data found for the selected wave. Please select a different wave or try again later.",
	},
	2: {
		SHOW_INSIGHTS_VALIDATION: "Please ensure you have selected a Study, Wave, and Region to view insights.",
		GRAPH_SUMMARY_PREFIX: "This cognitive factor has these sub-topics:",
		GRAPH_PRIORITY_CLASS_1: "Core Decision Factors",
		NO_WAVE_FOUND: "No wave data found for the selected study. Please select a different study or try again later.",
		NO_REGION_FOUND: "No region data found for the selected wave. Please select a different wave or try again later.",
	},
	3: {
		SHOW_INSIGHTS_VALIDATION: "Please ensure you have selected a Study, Wave, and Region to view insights.",
		GRAPH_SUMMARY_PREFIX: "This cognitive factor has these sub-topics:",
		GRAPH_PRIORITY_CLASS_1: "Core Decision Factors",
		NO_WAVE_FOUND: "No wave data found for the selected study. Please select a different study or try again later.",
		NO_REGION_FOUND: "No region data found for the selected wave. Please select a different wave or try again later.",
	},
};

// { // envId
// 	1: { // userProfile === Global
		
// 	},
// 	2: { // userProfile === Europe

// 	},
// 	3: { // userProfile === <others>

// 	},
// },

export const StudyModuleMessages = {
	1: { // envId
		1: { // userProfile === Global
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Wave added successfully",
			COPIED_WAVE: "Wave copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new wave data",
			DELETE_WAVE: "Wave data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete wave data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Wave",
			COPY_BUTTON_TEXT: "Copy Previous Wave",
			COL1_TEXT:  "Wave",
		},
		2: { // userProfile === Europe
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Wave added successfully",
			COPIED_WAVE: "Wave copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new wave data",
			DELETE_WAVE: "Wave data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete wave data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Wave",
			COPY_BUTTON_TEXT: "Copy Previous Wave",
			COL1_TEXT:  "Wave",
		},
		3: { // userProfile === <others>
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Wave added successfully",
			COPIED_WAVE: "Wave copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new wave data",
			DELETE_WAVE: "Wave data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete wave data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Wave",
			COPY_BUTTON_TEXT: "Copy Previous Wave",
			COL1_TEXT:  "Wave",
		},
	},
	2: { // envId
		1: { // userProfile === Global
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Therapy Area added successfully",
			COPIED_WAVE: "Therapy Area copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new time frame data",
			DELETE_WAVE: "Therapy Area data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete time frame data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Therapy Area",
			COPY_BUTTON_TEXT: "Copy Previous Therapy Area",
			COL1_TEXT:  "Therapy Area",
		},
		2: { // userProfile === Europe
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Clinical Trial added successfully",
			COPIED_WAVE: "Clinical Trial copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new time frame data",
			DELETE_WAVE: "Clinical Trial data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete time frame data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Clinical Trial",
			COPY_BUTTON_TEXT: "Copy Previous Clinical Trial",
			COL1_TEXT:  "Clinical Trial",
		},
		3: { // userProfile === <others>
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Time Frame added successfully",
			COPIED_WAVE: "Time Frame copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new time frame data",
			DELETE_WAVE: "Time Frame data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete time frame data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Time Frame",
			COPY_BUTTON_TEXT: "Copy Previous Time Frame",
			COL1_TEXT:  "Time Frame",
		},
	},
	3: { // envId
		1: { // userProfile === Global
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Wave added successfully",
			COPIED_WAVE: "Wave copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new wave data",
			DELETE_WAVE: "Wave data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete wave data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Wave",
			COPY_BUTTON_TEXT: "Copy Previous Wave",
			COL1_TEXT:  "Wave",
		},
		2: { // userProfile === Europe
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Wave added successfully",
			COPIED_WAVE: "Wave copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new wave data",
			DELETE_WAVE: "Wave data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete wave data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Wave",
			COPY_BUTTON_TEXT: "Copy Previous Wave",
			COL1_TEXT:  "Wave",
		},
		3: { // userProfile === <others>
			CREATE_STUDY_NAME_LABEL: "Study Name",
			CREATE_STUDY_TYPE_LABEL: "Study Type",
			CREATE_STUDY_DESCRIPTION_LABEL: "Study Description",
			ADDED_WAVE: "Wave added successfully",
			COPIED_WAVE: "Wave copied successfully",
			FAILED_ADDING_WAVE: "Failed to add new wave data",
			DELETE_WAVE: "Wave data deleted successfully",
			FAILED_DELETE_WAVE: "Failed to delete wave data",
			DELETE_GUIDE: "Guide File deleted successfully",
			FAILED_DELETE_GUIDE: "Failed to delete guide file",
			DOWNLOAD_GUIDE: "Guide File downloaded successfully",
			FAILED_DOWNLOAD_GUIDE: "Failed to download guide file",
			ADD_BUTTON_TEXT: "Add Wave",
			COPY_BUTTON_TEXT: "Copy Previous Wave",
			COL1_TEXT:  "Wave",
		},
	},
};
