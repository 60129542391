import React from "react";
import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";

interface ITooltipInfoProps {
	content: string | undefined;
	overlayWidth?: number;
	position?: "top" | "bottom" | "left" | "right" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "rightTop" | "rightBottom" | "leftTop" | "leftBottom";
	translateX?: number; // New prop for horizontal translation
	translateY?: number; // New prop for vertical translation
}

export const InfoTooltip = ({ content, overlayWidth, position, translateX, translateY }: ITooltipInfoProps) => {
	const markdownContent = content ? <Markdown remarkPlugins={[breaks, gfm]}>{content}</Markdown> : null;

	return (
		<Tooltip
			title={markdownContent}
			placement={position}
			overlayStyle={{ maxWidth: overlayWidth || 300 }}
			overlayInnerStyle={{
				backgroundColor: "#f3f2f2",
				color: "#333",
				borderRadius: "8px",
				padding: "15px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				border: "1px solid #d9d9d9",
				transform: `translate(${translateX || 0}px, ${translateY || 0}px)`,
			}}
		>
			<InfoCircleTwoTone style={{ margin: "0 5px" }} />
		</Tooltip>
	);
};
