import WikiPage from "./Components/WikiPage";
import HomePage from "./Components/HomePage";
import { Route, Routes } from "react-router-dom";

export default function CognitivePage() {
	return (
		<Routes>
			<Route key="HomePage" path="/" element={<HomePage />} />
			<Route key="WikiPage" path=":id/*" element={<WikiPage />} />
		</Routes>
	);
}
