import { searchQuery, transcriptDataInter } from "../Search";
import * as SearchService from "./../../../services/search";

export const getAllTranscripts: (searchQuery: searchQuery) => Promise<unknown> = async (searchQuery: searchQuery) => {
	const data = await SearchService.getAllTranscripts2(searchQuery);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getFullTranscript: (transcript: string) => Promise<unknown> = async (s3path: string) => {
	const data = await SearchService.getFullTranscript(s3path);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getS3ImageByteArray: (s3path: string) => Promise<unknown> = async (s3path: string) => {
	const data = await SearchService.getS3ImageByteArrayData(s3path);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const addToStory: (story: { searchQuery: string; summaryJson: transcriptDataInter; storyIds: number[] }) => Promise<unknown> = async (story: {
	searchQuery: string;
	summaryJson: transcriptDataInter;
	storyIds: number[];
}) => {
	const data = await SearchService.addToStory(story);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getFilters: (type?: string) => Promise<unknown> = async (type?: string) => {
	const data = await SearchService.getFilters(type);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getNodeGraphAllTranscript: (searchQuery: searchQuery) => Promise<unknown> = async (searchQuery: searchQuery) => {
	const data = await SearchService.getNodeTranscripts("searchQuery");
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const saveSearchFeedback: (summary: {
	searchQuery: string;
	isLike: boolean;
	searchJson: transcriptDataInter;
	userFeedback: string | undefined;
}) => Promise<unknown> = async (summary) => {
	const data = await SearchService.saveSearchFeedbackData(summary);
	if (data === null || data === undefined) return null;
	return data;
};
