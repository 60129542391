const verticalBarChartConfig = {
	chart: {
		type: "column",
	},
	title: {
		text: "",
	},
	xAxis: {
		crosshair: true,
	},
	yAxis: {
		min: 0,
		title: {
			text: "Insights Count",
		},
	},
	plotOptions: {
		column: {
			pointPadding: 0.2,
			borderWidth: 0,
		},
	},
	legend: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
};

export default verticalBarChartConfig;
