import React, { useEffect, useState, useCallback, useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { TreeSelect, Spin, message } from "antd";

const { SHOW_PARENT } = TreeSelect;

interface IDatasetDropdownProps {
	data: {
		transcripts: any[];
		reports: any[];
	};
	handleFilters: (filterType: string, filterValue: string | boolean | string[] | number) => void;
	searchIn: boolean;
}

export function InsightsDatasetDropdown(props: IDatasetDropdownProps) {
	const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4", alignSelf: "center", flex: 1 }} spin />;

	const [value, setValue] = useState<string[] | undefined>(undefined);
	const [dropdownData, setDropdownData] = useState<any[]>(props.searchIn ? props.data?.reports : props.data?.transcripts);

	useEffect(() => {
		props.handleFilters("studyIds", value || []);
	}, [value]);

	useEffect(() => {
		setDropdownData(props.searchIn ? props.data?.reports : props.data?.transcripts);
	}, [props.searchIn, props.data?.reports, props.data?.transcripts]);

	const filterTreeNode = useCallback((input: any, treeNode: any) => {
		const title = treeNode.props.title.toLowerCase();
		return title.indexOf(input.toLowerCase()) >= 0;
	}, []);

	const dropdownRender = useCallback(
		(menu: any) => (
			<Spin spinning={!props.data} indicator={antIcon}>
				{menu}
			</Spin>
		),
		[props.data, antIcon]
	);

	const onChange = useCallback((newValue: string[]) => {
		// console.log("originalValue ", value);
		// console.log("onChange ", newValue);
		if (newValue && newValue.length > 0) {
			const firstSelectedStudy = newValue[0].split("_")[0];
			// console.log("firstSelectedStudy ", firstSelectedStudy);
			const updatedValue = newValue.filter((item) => item.split("_")[0] === firstSelectedStudy);
			// console.log("updatedValue ", updatedValue);
			if (updatedValue.length !== newValue.length) {
				message.warning("Only one study can be selected at a time.");
			}
			setValue(updatedValue);
		} else {
			setValue([]);
		}
	}, []);

	const tProps = useMemo(
		() => ({
			treeData: dropdownData,
			value,
			onChange,
			filterTreeNode,
			treeCheckable: true,
			treeLine: true,
			showSearch: true,
			showCheckedStrategy: SHOW_PARENT,
			placeholder: "Select Datasets",
			maxTagTextLength: 15,
			maxTagCount: "responsive" as const,
			style: {
				width: "100%",
			},
			dropdownRender,
		}),
		[dropdownData, value, onChange, filterTreeNode, dropdownRender]
	);

	return (
		<>
			<TreeSelect {...tProps} />
		</>
	);
}
