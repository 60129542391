import React, { useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import { Table, Input, Button, Form, InputNumber, Popconfirm, Typography, Menu, Dropdown, message, Modal, Spin, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { PlusCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, MoreOutlined, LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import GuideInput from "../OtherComponents/GuideInput";
import TranscriptInput from "../OtherComponents/TranscriptInput";
import DateInput from "../OtherComponents/DateInput";
import moment from "moment";
import * as StudyServiceHelper from "../../../SearchEngine/ServiceHelpers/study";
import AppContext from "../../../../store";
import { ApiErrorMessages, StudyModuleMessages } from "../../../../constants/messages";
import { IWaveData, IEditableCellProps, IAddNewWavePayload, IDeleteWavePayload } from "../../../../model/interface/study";

// Dummy data for SM Wave
// let initialData: IWaveData[] = [
//   {
//     key: 1,
//     waveId: 1,
//     waveName: "Q1-2020",
//     studyIterId: 1,
//     geoId: 1,
//     geography: "France",
//     startDate: "2023-06-20T00:00:00",
//     endDate: "2023-08-20T00:00:00",
//     guideId: 1,
//     guideName: "RA Market Landscape- Discussion Guide",
//     transcriptCount: 34,
//   },
//   {
//     key: 2,
//     waveId: 1,
//     waveName: "Q1-2020",
//     studyIterId: 2,
//     geoId: 2,
//     geography: "UK",
//     startDate: "2023-05-20T00:00:00",
//     endDate: "2023-06-26T00:00:00",
//     guideId: 2,
//     guideName: "Discussion Guide v2.0",
//     transcriptCount: 2,
//   },
// ];

export default function ManageStudy() {
	const context = React.useContext(AppContext);

	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [data, setData] = useState<IWaveData[] | []>([]);
	const [editingKey, setEditingKey] = useState(-1);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [geographyDropdownData, setGeographyDropdownData] = useState<Array<any>>([]);
	const [geoDropdownValue, setGeoDropdownValue] = useState("");
	const [updatedGuideName, setUpdatedGuideName] = useState("");
	const [tempData, setTempData] = useState<IWaveData[] | null>(null);
	const [studyName, setStudyName] = useState<string>("");
	const [studyTypeId, setStudyTypeId] = useState<number | undefined>(undefined);

	const isEditing = (record: IWaveData) => record.studyIterId === editingKey;
	const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4", alignSelf: "center", flex: 1 }} spin />;
	const userProfileName = context.userProfile ? context.userProfile.displayName ? context.userProfile.displayName.toLowerCase() : "default" : "default";

	function generateUniqueKey(initialData: IWaveData[]): number {
		let randomKey = Math.floor(Math.random() * (9999 - 0 + 1)) + 0;

		if (initialData != null) {
			while (initialData.some((wave) => wave.key === randomKey)) {
				randomKey = Math.floor(Math.random() * (9999 - 0 + 1)) + 0;
			}
		}
		return randomKey;
	}

	// useEffect to re fetch data at fixed intervals
	React.useEffect(() => {
		// do not fetch while editing
		if (editingKey === -1) {
			fetchManageStudyData();
			fetchStudyGeographyData();
			const intervalId = setInterval(() => {
				//        console.log("Refetching SM Wave Table Data with editingKey -> ", editingKey);
				fetchManageStudyData(); // Fetch data at each interval
			}, 10000); // Interval in milliseconds (10000ms = 10seconds)

			return () => {
				clearInterval(intervalId); // Clear interval on component unmount
			};
		}
	}, [editingKey]);

	// React.useEffect(() => {
	//   fetchManageStudyData();
	//   fetchStudyGeographyData();
	// }, []);

	React.useEffect(() => {
		//    console.log("Updated Data for Manage Study -> ", data);
	}, [data]);

	// API Request to fetch SM Wave Table Data
	async function fetchManageStudyData() {
		try {
			// setLoading(true);
			const currentStudyId = sessionStorage.getItem("studyId");
			//      console.log("Study Id for API request is -> ", currentStudyId);

			if (currentStudyId) {
				const data: any = await StudyServiceHelper.getStudyDetails(parseInt(currentStudyId, 10));

				if (data) {
					setData(data.wave);
					setStudyName(data.studyName);
					data.studyTypeId && setStudyTypeId(data.studyTypeId);
					setLoading(false);

					// message.success("Successfully fetched Study Wave Data");
					//console.log("Fetched Manage Study Data -> ", data);
				} else {
					console.log("Fetch Manage Study Data API call failed.");
				}
			} else {
				console.log("Study Id not found.");
				// console.log("Study Id not found in sessionStorage.");
			}
		} catch (error) {
			message.error(ApiErrorMessages.FETCH_ERROR);
			console.error("Error fetching Manage Study Data:", error);
		} finally {
			setLoading(false);
		}
	}

	// API Request to fetch SM Geography Data
	async function fetchStudyGeographyData() {
		try {
			const data: any = await StudyServiceHelper.getGeographies();
			if (data) {
				setGeographyDropdownData(data);
				//        console.log("Fetched Study Geography Data -> ", data);
			} else {
				console.log("Fetch Study Geography Data API returned empty data.");
			}
		} catch (error) {
			console.error("Error fetching Study Geography Data:", error);
		}
	}

	// API Request to Add New Wave Data
	async function addNewWaveData(updatedData: IWaveData[], action: string) {
		try {
			setLoading(true);
			if (action === "add" || action === "save") {
				const existingWaveNames = data ? data.map((item) => item.waveName) : [];
				const duplicateWaveNames = updatedData && existingWaveNames ? updatedData.filter((item) => existingWaveNames.includes(item.waveName)) : [];

				// Throw an error if duplicate wave names are found
				if (duplicateWaveNames.length > 0) {
					throw new Error("Duplicate wave names not allowed.");
				}

				// Add regex check for special characters in wave names
				const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;

				if (updatedData.some((item) => specialCharsRegex.test(item.waveName))) {
					throw new Error("Special characters in wave name are not allowed.");
				}
			}

			const newWavePayload: IAddNewWavePayload = {
				waveId: updatedData[0].waveId ? updatedData[0].waveId : 0,
				waveName: updatedData[0].waveName,
				studyId: parseInt(sessionStorage.getItem("studyId") ?? "0", 10),
				geoData: updatedData.map((item) => {
					const a = item.startDate ? new Date(item.startDate) : new Date();
					a.setDate(a.getDate() + 16 > 30 ? a.getDate() - 14 : a.getDate() + 16);
					const b = item.endDate ? new Date(item.endDate) : new Date();
					b.setDate(b.getDate() + 16 > 30 ? b.getDate() - 14 : b.getDate() + 16);
					return {
						studyIterId: item.studyIterId ?? 0,
						geoId: item.geoId ?? 0,
						geoName: item.geography ?? "",
						start_dt: item.startDate ? a.toISOString() : "", // converting "MMM YYYY" to "15 MMM YYYY" for proper conversion acc to timezone
						end_dt: item.endDate ? b.toISOString() : "",
					};
				}),
			};
			//      console.log("New Wave Payload after transformation is -> ", newWavePayload);

			const response: any = await StudyServiceHelper.addNewWaveRow(newWavePayload);
			if (response) {
				//        console.log("New wave data added successfully:", response);
				if (action === "save" || action === "saveGeo") {
					message.success("Data saved successfully");
				} else if (action === "add") {
					// message.success(context.envId && StudyModuleMessages[context.envId].ADDED_WAVE);
					message.success(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].ADDED_WAVE);
				} else if (action === "copy") {
					// message.success(context.envId && StudyModuleMessages[context.envId].COPIED_WAVE);
					message.success(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].COPIED_WAVE);
				}
				return response;
			} else {
				// message.error(context.envId && StudyModuleMessages[context.envId].FAILED_ADDING_WAVE);
				message.error(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].FAILED_ADDING_WAVE);
				return response;
			}
		} catch (error) {
			console.error("Error adding new wave data:", error);

			let errorMessage = ApiErrorMessages.ADDING_ERROR;
			if (error instanceof Error) {
				errorMessage = error.message;
			}
			message.error(errorMessage);

			return false;
		} finally {
			setLoading(false);
		}
	}

	// API Request to Delete Wave Data
	async function deleteEachWave(data: IWaveData, shouldDeleteWave: boolean) {
		try {
			const deleteWavePayload: IDeleteWavePayload = {
				waveId: shouldDeleteWave ? data.waveId ?? 0 : 0,
				studyIterIds: [data.studyIterId],
			};
			//      console.log("New Wave Payload after transformation is -> ", deleteWavePayload);

			const response: any = await StudyServiceHelper.deleteWaveRow(deleteWavePayload);
			if (response) {
				//        console.log("Wave data deleted successfully:", response);
				// message.success(context.envId && StudyModuleMessages[context.envId].DELETE_WAVE);
				message.success(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].DELETE_WAVE);
				return response;
			} else {
				// message.error(context.envId && StudyModuleMessages[context.envId].FAILED_DELETE_WAVE);
				message.error(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].FAILED_DELETE_WAVE);
				return response;
			}
		} catch (error) {
			console.error("Error deleting wave data:", error);
			message.error(ApiErrorMessages.DELETING_ERROR);
			return false;
		}
	}

	const handleDateRangeChange = async (studyIterId: number, updatedDateRange: Array<any>) => {
		//    console.log("Inside SM Manage Study -> Updated Date Range -> ", updatedDateRange);
		try {
			const row = (await form.validateFields()) as IWaveData;
			const newData = [...data];
			const index = newData.findIndex((item) => studyIterId === item.studyIterId);

			if (index > -1) {
				const item = newData[index];

				const updatedStartDate = updatedDateRange[0];
				const updatedEndDate = updatedDateRange[1];

				newData.splice(index, 1, { ...item, startDate: updatedStartDate, endDate: updatedEndDate });

				setData(newData);
			} else {
				console.error("Wave key not found in data.");
			}
		} catch (err) {
			console.error("Save failed:", err);
		}
	};

	const handleGuideUpload = (record: IWaveData, updatedGuideId: number, updatedGuideName: string) => {
		//    console.log("Inside SM Manage Study -> Updated Guide Data -> ", record, updatedGuideId, updatedGuideName);

		const indexToUpdate = data.findIndex((item) => item.studyIterId === record.studyIterId);

		const updatedData = [...data];
		updatedData[indexToUpdate] = { ...data[indexToUpdate], guideId: updatedGuideId, guideName: updatedGuideName };
		setData(updatedData);
		setUpdatedGuideName(updatedGuideName);
		//    console.log("Inside SM Manage Study -> New Updated Data -> ", data);
		// fetchManageStudyData();
	};

	const EditableCell: React.FC<IEditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, dropdownOptions, ...restProps }) => {
		// Handle menu item click in dropdown
		const handleMenuClick = (e: any) => {
			setGeoDropdownValue(e.key);
			setDropdownVisible(false);
		};

		const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

		// If inputType is dropdown, render the Dropdown component
		if (inputType === "dropdown" && dropdownOptions) {
			const menu = (
				<Menu onClick={handleMenuClick}>
					{dropdownOptions.map((option) => (
						<Menu.Item key={option.geoName}>{option.geoName}</Menu.Item>
						// <Menu.Item key={option.key}>{option.value}</Menu.Item>
					))}
				</Menu>
			);

			return (
				<>
					{editing ? (
						<Form.Item name={dataIndex} style={{ margin: 0 }}>
							<Dropdown overlay={menu} open={dropdownVisible} onVisibleChange={(visible) => setDropdownVisible(visible)}>
								<Input value={geoDropdownValue} onChange={(e) => setGeoDropdownValue(e.target.value)} />
							</Dropdown>
						</Form.Item>
					) : (
						children
					)}
				</>
			);
		} else if (inputType === "dateRange") {
			return (
				<>
					{editing ? (
						<Form.Item name={dataIndex} style={{ margin: 0 }}>
							<DateInput startDate={record.startDate} endDate={record.endDate} studyIterId={record.studyIterId} handleDateRangeChange={handleDateRangeChange} />
						</Form.Item>
					) : (
						children
					)}
				</>
			);
		} else if (inputType === "guideUpload") {
			return (
				<>
					{editing ? (
						<Form.Item name={dataIndex} style={{ margin: 0 }}>
							{record.guideName && (
								<div style={{ display: "flex", alignItems: "center" }}>
									<Tooltip title={record.guideName}>
										<span className="guide-cell-text">{record.guideName}</span>
									</Tooltip>
									<Popconfirm title="Sure to delete?" onConfirm={() => deleteUploadedGuide(record)}>
										{context.userName === record.createdBy && (
											<a>
												<DeleteOutlined size={20} style={{ color: "#27A6A4", fontWeight: "bold", marginLeft: "20px", marginBottom: "10px" }} />
											</a>
										)}
									</Popconfirm>
								</div>
							)}
						</Form.Item>
					) : (
						children
					)}
				</>
			);
		}

		return (
			<>
				{editing ? (
					<Form.Item
						name={dataIndex}
						style={{ margin: 0 }}
						rules={[
							{
								required: true,
								message: `Please Input ${title}!`,
							},
						]}
					>
						{inputNode}
					</Form.Item>
				) : (
					children
				)}
			</>
		);
	};

	const edit = (record: Partial<IWaveData> & { key: React.Key }) => {
		form.setFieldsValue({
			waveId: record.waveId,
			waveName: record.waveName,
			studyIterId: record.studyIterId,
			geoId: record.geoId,
			geography: record.geography,
			startDate: record.startDate,
			endDate: record.endDate,
			guideId: 0,
			guideName: "",
			transcriptCount: 0,
			...record,
		});
		// console.log("----------------------", record.key);
		// setEditingKey(record.key);
		// setEditingKey(record.studyIterId);
		if (record.studyIterId !== undefined) {
			setEditingKey(record.studyIterId);
		} else {
			setEditingKey(0);
		}
	};

	const cancel = (studyIterId: number) => {
		if (tempData != null) {
			setData(tempData);
			setTempData(null);
		}

		if (studyIterId === 0) {
			const updatedData = data && data.filter((item) => item.studyIterId !== studyIterId);
			setData(updatedData);
		}

		setEditingKey(-1);
	};

	const save = async (waveKey: React.Key) => {
		try {
			const row = (await form.validateFields()) as IWaveData;
			const newData = [...data];
			const index = newData.findIndex((item) => waveKey === item.studyIterId);

			if (index > -1) {
				const item = newData[index];

				const selectedGeoData = geographyDropdownData.find((option) => option.geoName === geoDropdownValue);
				const startDateString = row.startDate && moment.isMoment(row.startDate) ? row.startDate.format("YYYY-MM") : item.startDate;
				const endDateString = row.endDate && moment.isMoment(row.endDate) ? row.endDate.format("YYYY-MM") : item.endDate;
				const updatedGuide = updatedGuideName ? updatedGuideName : item.guideName;

				newData.splice(index, 1, {
					...item,
					waveName: row.waveName ? row.waveName : item.waveName,
					geoId: selectedGeoData.geoId,
					geography: geoDropdownValue,
					startDate: startDateString,
					endDate: endDateString,
					guideName: updatedGuide,
				});
				const tempData = [
					{
						...item,
						waveName: row.waveName ? row.waveName : item.waveName,
						geoId: selectedGeoData.geoId,
						geography: geoDropdownValue,
						startDate: startDateString,
						endDate: endDateString,
						guideName: updatedGuide,
					},
				];

				// POST request API call to Add New Wave
				const saveAction = row.waveName ? (row.waveName === item.waveName ? "saveGeo" : "save") : "saveGeo";
				let response: boolean = await addNewWaveData(tempData, saveAction);

				if (response) {
					setData(newData);
					setTempData(null);
					fetchManageStudyData();
				} else {
					setLoading(false);
				}
				setEditingKey(-1);
			} else {
				console.error("Wave key not found in data.");
			}
		} catch (err) {
			console.error("Save failed:", err);
		}
	};

	const addNewWave = async () => {
		try {
			setLoading(true);
			// const newWave = data!=null?`New - Wave ${data.length + 1}`:`New - Wave ${1}`;
			const newWave =
				data && data.length > 0
					? (() => {
							let waveNumber = 2;
							// let newName = context.envId === 2 ? `New - Time Frame ${waveNumber}` : `New - Wave ${waveNumber}`;
							let newName = context.envId === 2 ? `New - ${context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].COL1_TEXT} ${waveNumber}` : `New - Wave ${waveNumber}`;

							while (data.some((item) => item.waveName === newName)) {
								waveNumber++;
								// newName = context.envId === 2 ? `New - Time Frame ${waveNumber}` : `New - Wave ${waveNumber}`;
								newName = context.envId === 2 ? `New - ${context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].COL1_TEXT} ${waveNumber}` : `New - Wave ${waveNumber}`;
							}

							return newName;
					  })()
					: context.envId === 2
					// ? "New - Time Frame 1"
					? `New - ${context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].COL1_TEXT} 1`
					: "New - Wave 1";

			const newData = [
				{
					key: data != null ? generateUniqueKey(data) : 1,
					waveName: newWave,
					waveId: 0,
					geography: geographyDropdownData[0].geoName,
					geoId: geographyDropdownData[0].geoId,
					guideName: "",
					transcriptCount: 0,
					startDate: Date().toLocaleString(),
					endDate: Date().toLocaleString(),
					studyIterId: 0,
				},
			];
			const updatedData = data != null ? [...data, newData[0]] : [newData[0]];

			//      console.log("Inside addNewWave function. New Data value -> ", updatedData);

			let response: boolean = await addNewWaveData(newData, "add");

			if (response) {
				setData(updatedData);
				setTempData(null);
				fetchManageStudyData();
			} else {
				setLoading(false);
			}
		} catch (err) {
			console.error("Save failed:", err);
			setLoading(false);
		}
	};

	const handleCopyWave = async () => {
		try {
			if (data.length > 0) {
				setLoading(true);
				const lastWaveName = data[data.length - 1].waveName;

				const updatedData = [...data];
				const newData: IWaveData[] = [];
				data.map((el) => {
					if (el.waveName === lastWaveName) {
						updatedData.push({ ...el, key: generateUniqueKey(data), waveName: lastWaveName + " copied", waveId: 0, studyIterId: 0 });
						newData.push({ ...el, key: generateUniqueKey(data), waveName: lastWaveName + " copied", waveId: 0, studyIterId: 0 });
					}
				});

				let response: boolean = await addNewWaveData(newData, "copy");

				if (response) {
					setData(JSON.parse(JSON.stringify(updatedData)));
					//          console.log("Handle Copy Wave updatedData value ->", updatedData);
					setTempData(null);
					fetchManageStudyData();
				} else {
					setLoading(false);
				}
			}
		} catch (err) {
			console.error("Save failed:", err);
			setLoading(false);
		}
	};

	// Function to add a new geography to the selected wave
	const addNewGeography = (record: IWaveData) => {
		if (record.waveName) {
			const temp: IWaveData[] = [];
			const updatedData: IWaveData[] = [];

			const editableRowCount = data.filter((item: any) => item.waveName === record.waveName).length;
			let editableRowFirstIndex = data.findIndex((item: any) => item.waveName === record.waveName);
			const editableRowLastIndex = editableRowFirstIndex + (editableRowCount - 1);
			updatedData.push(...data.slice(0, editableRowFirstIndex));

			temp.push(...data.filter((el) => el.waveName === record.waveName));
			//@ts-ignore
			temp[0].rowSpan = temp[0].rowSpan + 1;
			const newKey = generateUniqueKey(data);
			temp.push({
				key: newKey,
				waveId: record.waveId,
				waveName: record.waveName,
				geography: geographyDropdownData[0].geoName,
				startDate: new Date().toISOString(),
				endDate: new Date().toISOString(),
				guideId: 0,
				guideName: "",
				transcriptCount: 0,
				studyIterId: 0,
			});

			updatedData.push(...temp.filter((el) => el.waveName === record.waveName));
			updatedData.push(...data.slice(editableRowLastIndex + 1));

			// Old logic to resolve re-ordering issue
			// updatedData.push(...data.filter((el) => el.waveName !== record.waveName));
			// updatedData.push(...temp.filter((el) => el.waveName === record.waveName));

			// const newRowIndex = updatedData.findIndex((item) => item.studyIterId === 0);

			setData(JSON.parse(JSON.stringify(updatedData)));
			setEditingKey(newKey);
			edit(updatedData[editableRowLastIndex + 1]);
		}
	};

	const deleteGeography = async (key: number) => {
		console.log("Inside deleteGeography function.");
		const updatedData = data.filter((item) => item.studyIterId !== key);
		let shouldDeleteWave = false;

		const indexToRemove = data.findIndex((item) => item.studyIterId === key);
		if (indexToRemove !== -1) {
			const dataToRemove = data[indexToRemove];
			if (updatedData.filter((item) => item.waveId === dataToRemove.waveId).length === 0) shouldDeleteWave = true;

			// POST request API call to Delete Wave Row
			const response: boolean = await deleteEachWave(dataToRemove, shouldDeleteWave);
			if (response) {
				setData(updatedData);
			}
		}
	};

	const deleteUploadedGuide = async (record: IWaveData) => {
		try {
			//        console.log("Inside deleteUploadedGuide function. Deleting this record -> ", record);

			// API Request to Delete Uploaded Guide
			const response: any = await StudyServiceHelper.deleteUploadedGuide(record.guideId ?? 0);
			// const response: any = await StudyServiceHelper.deleteUploadedGuide(43);

			if (response) {
				//            console.log("Guide File deleted successfully:", response);
				// message.success(context.envId && StudyModuleMessages[context.envId].DELETE_GUIDE);
				message.success(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].DELETE_GUIDE);

				// Update the data array
				const indexToDelete = data.findIndex((item) => item.studyIterId === record.studyIterId);

				if (indexToDelete !== -1) {
					const newData = [...data];
					newData[indexToDelete] = { ...newData[indexToDelete], guideId: 0, guideName: null };
					setData(newData);
				}
			} else {
				// message.error(context.envId && StudyModuleMessages[context.envId].FAILED_DELETE_GUIDE);
				message.error(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].FAILED_DELETE_GUIDE);
			}
		} catch (error) {
			console.error("Error deleting uploaded guide file:", error);
			message.error(ApiErrorMessages.DELETING_ERROR);
		}
	};

	const handleGuideNameClick = async (record: IWaveData) => {
		const loadingMessage = message.loading(`Downloading ${record.guideName}...`, 1000);
		try {
			const response: any = await StudyServiceHelper.downloadS3File(record.dgS3Path ?? "");

			if (response) {
				loadingMessage();
				// console.log("From s3", response);
				//downloads the document automatically
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", record.guideName ?? "");
				document.body.appendChild(link);
				link.click();

				// message.success(context.envId && StudyModuleMessages[context.envId].DOWNLOAD_GUIDE);
				message.success(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].DOWNLOAD_GUIDE);
			} else {
				loadingMessage();
				// message.error(context.envId && StudyModuleMessages[context.envId].FAILED_DOWNLOAD_GUIDE);
				message.error(context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].FAILED_DOWNLOAD_GUIDE);
			}
		} catch (error) {
			loadingMessage();
			console.error("Error downloading uploaded guide file:", error);
			message.error(ApiErrorMessages.DOWNLOAD_ERROR);
		} finally {
			loadingMessage();
		}
	};

	const renderWaveColumn = (data: IWaveData[], addNewGeography: (record: IWaveData) => void) => (wave: string, record: IWaveData, index: number) => {
		// console.log("Render Wave Column ->", wave, record, index);
		const rowSpan =
			index > 0 && wave === data[index - 1].waveName
				? 0
				: data.filter((item) => item.waveName === wave).length === 0
				? 1
				: data.filter((item) => item.waveName === wave).length;

		return {
			children: (
				<div>
					<EditableCell
						editing={isEditing(record)} // Assuming you have the 'isEditing' function
						dataIndex="waveName"
						title="Wave"
						inputType="text"
						record={record}
						index={0}
					>
						<div className="wave-cell-text">{wave}</div>
					</EditableCell>

					<div style={{ marginLeft: "10px" }}>
						<Button type="text" size="small" onClick={() => addNewGeography(record)}>
							<PlusCircleOutlined
								style={{
									fontSize: "16px",
									color: "black",
									fontWeight: "bold",
									backgroundColor: "transparent",
								}}
							/>
						</Button>
					</div>
				</div>
			),
			props: { rowSpan },
		};
	};

	const renderGeographyColumn = (initialData: IWaveData[]) => (geography: string, record: IWaveData) => {
		if (geography !== "null") {
			return (
				<EditableCell
					editing={isEditing(record)} // Assuming you have the 'isEditing' function
					dataIndex="geography"
					title="Geography"
					inputType="dropdown"
					record={record}
					index={0}
					dropdownOptions={geographyDropdownData}
				>
					<span className="geo-cell-text">{geography}</span>
				</EditableCell>
			);
		}
		return <></>;
	};

	const renderDateColumn = (data: IWaveData[]) => (record: IWaveData) => {
		const { startDate, endDate } = record;

		if (startDate != null || endDate != null) {
			const startDateObj = new Date(startDate ?? "");
			const endDateObj = new Date(endDate ?? "");

			// Format the dates to Mon Year format
			const startMonthYear = startDateObj.toLocaleString("en-US", {
				month: "short",
				year: "numeric",
			});

			const endMonthYear = endDateObj.toLocaleString("en-US", {
				month: "short",
				year: "numeric",
			});

			return (
				<EditableCell editing={isEditing(record)} dataIndex="startDate" title="Fielding Dates" inputType="dateRange" record={record} index={0}>
					<span className="date-cell-text">
						{startMonthYear}
						<span
							style={{
								color: "#B2B0B6",
								marginLeft: "10px",
								marginRight: "10px",
							}}
						>
							&#8640;
						</span>
						{endMonthYear}
					</span>
				</EditableCell>
			);
		}

		return <></>;
	};

	const renderGuideColumn = (initialData: IWaveData[]) => (record: IWaveData) => {
		if (record.guideName !== null && record.guideName !== undefined) {
			return (
				<EditableCell editing={isEditing(record)} dataIndex="guideName" title="Guide" inputType="guideUpload" record={record} index={0}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Tooltip title={record.guideName}>
							<span className="guide-cell-text" onClick={() => handleGuideNameClick(record)}>
								{record.guideName}
							</span>
						</Tooltip>
						<Popconfirm title="Sure to delete?" onConfirm={() => deleteUploadedGuide(record)}>
							{context.userName === record.createdBy && (
								<a>
									<DeleteOutlined size={20} style={{ color: "#27A6A4", fontWeight: "bold", marginLeft: "20px", marginBottom: "10px" }} />
								</a>
							)}
						</Popconfirm>
					</div>
				</EditableCell>
			);
		}
		return <GuideInput studyName={studyName} waveData={record} handleGuideUpload={handleGuideUpload} disabled={editingKey !== -1} studyTypeId={studyTypeId} />;
	};

	const renderTranscriptColumn = (initialData: IWaveData[]) => (transcriptCount: number, record: IWaveData) => {
		const currentStudyIterId = record.studyIterId ? record.studyIterId : 0;
		if (currentStudyIterId !== 0)
			return (
				<TranscriptInput
					transcriptCount={transcriptCount}
					studyIterId={currentStudyIterId}
					studyName={studyName}
					waveData={record}
					processedTranscriptCount={record.processedTranscriptCount ?? 0}
					disabled={editingKey !== -1}
					envFlag={context.envId}
					studyTypeId={studyTypeId}
				/>
			);
	};

	const handleMenuClick = (event: any, waveRecord: IWaveData) => {
		if (event.key === "edit") {
			edit(waveRecord);
			setTempData(data); // in case user cancels and not save the new data
			setGeoDropdownValue(waveRecord.geography || "");
		} else if (event.key === "delete") {
			// console.log("Inside handleMenuClick function with delete event key with record data -> ", waveRecord);
			// deleteGeography(waveRecord.studyIterId);
			showDeleteConfirm(waveRecord);
			setTempData(null);
		}
	};

	const showDeleteConfirm = (record: IWaveData) => {
		// console.log("Inside showDeleteConfirm function with record data -> ", record);
		Modal.confirm({
			title: "Are you sure you want to delete this geography?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				deleteGeography(record.studyIterId);
			},
		});
	};

	const columns: ColumnsType<IWaveData> =
		context.envId && context.envId === 2
			? [
					{
						// title: "Time Frame",
						title: context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].COL1_TEXT,
						dataIndex: "waveName",
						width: 400,
						render: renderWaveColumn(data, addNewGeography),
					},
					{
						title: "Geography",
						dataIndex: "geography",
						width: 325,
						render: renderGeographyColumn(data),
					},
					{
						title: "Dates",
						dataIndex: "startDate",
						width: 375,
						render: (value, record) => renderDateColumn(data)(record),
					},
					{
						title: "Files",
						dataIndex: "transcriptCount",
						width: 600,
						render: renderTranscriptColumn(data),
					},
					{
						title: "",
						dataIndex: "operation",
						render: (_: any, record: IWaveData) => {
							const editable = isEditing(record);
							// console.log("Operations Column Editable value ->", editable);
							return editable ? (
								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									<Typography.Link onClick={() => save(record.studyIterId)} style={{ marginRight: "20px" }}>
										<CheckCircleOutlined style={{ color: "black", fontWeight: "bold" }} />
									</Typography.Link>

									<Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.studyIterId)}>
										<a>
											<CloseCircleOutlined style={{ color: "black", fontWeight: "bold" }} />
										</a>
									</Popconfirm>
								</div>
							) : (
								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									<Dropdown
										overlay={
											<Menu onClick={(event) => handleMenuClick(event, record)}>
												<Menu.Item key="edit" disabled={record.pendingTranscriptCount !== 0}>
													<span style={{ marginRight: "30px" }}>Edit</span>
												</Menu.Item>
												<Menu.Item key="delete" disabled={record.pendingTranscriptCount !== 0 || context.userName !== record.createdBy}>
													<span style={{ marginRight: "30px" }}>Delete</span>
												</Menu.Item>
											</Menu>
										}
										placement="bottomRight"
										trigger={["click"]}
									>
										<Typography.Link disabled={editingKey !== -1} onClick={() => setEditingKey(-1)}>
											<MoreOutlined style={{ fontSize: "24px", fontWeight: "bold" }} />
										</Typography.Link>
									</Dropdown>
								</div>
							);
						},
					},
			  ]
			: [
					{
						title: "Wave",
						dataIndex: "waveName",
						width: 250,
						render: renderWaveColumn(data, addNewGeography),
					},
					{
						title: "Geography",
						dataIndex: "geography",
						width: 250,
						render: renderGeographyColumn(data),
					},
					{
						title: "Fielding Dates",
						dataIndex: "startDate",
						width: 250,
						render: (value, record) => renderDateColumn(data)(record),
					},
					{
						title: "Guide",
						dataIndex: "guideName",
						width: 500,
						render: (value, record) => renderGuideColumn(data)(record),
					},
					{
						title: "Transcripts",
						dataIndex: "transcriptCount",
						width: 500,
						render: renderTranscriptColumn(data),
					},
					{
						title: "",
						dataIndex: "operation",
						render: (_: any, record: IWaveData) => {
							const editable = isEditing(record);
							// console.log("Operations Column Editable value ->", editable);
							return editable ? (
								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									<Typography.Link onClick={() => save(record.studyIterId)} style={{ marginRight: "20px" }}>
										<CheckCircleOutlined style={{ color: "black", fontWeight: "bold" }} />
									</Typography.Link>

									<Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.studyIterId)}>
										<a>
											<CloseCircleOutlined style={{ color: "black", fontWeight: "bold" }} />
										</a>
									</Popconfirm>
								</div>
							) : (
								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									<Dropdown
										overlay={
											<Menu onClick={(event) => handleMenuClick(event, record)}>
												<Menu.Item key="edit" disabled={record.pendingTranscriptCount !== 0}>
													<span style={{ marginRight: "30px" }}>Edit</span>
												</Menu.Item>
												<Menu.Item key="delete" disabled={record.pendingTranscriptCount !== 0 || context.userName !== record.createdBy}>
													<span style={{ marginRight: "30px" }}>Delete</span>
												</Menu.Item>
											</Menu>
										}
										placement="bottomRight"
										trigger={["click"]}
									>
										<Typography.Link disabled={editingKey !== -1} onClick={() => setEditingKey(-1)}>
											<MoreOutlined style={{ fontSize: "24px", fontWeight: "bold" }} />
										</Typography.Link>
									</Dropdown>
								</div>
							);
						},
					},
			  ];

	return (
		<div className="p-5">
			<Button onClick={addNewWave} style={{ marginBottom: 25, marginRight: 15, marginTop: 10 }}>
				{/* {context.envId && context.envId === 2 ? "Add Time Frame" : "Add Wave"} */}
				{context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].ADD_BUTTON_TEXT}
			</Button>
			<Button
				onClick={handleCopyWave}
				style={{ marginBottom: 25, marginRight: 15, marginTop: 10 }}
				// disabled
			>
				{/* {context.envId && context.envId === 2 ? "Copy Previous Time Frame" : "Copy Previous Wave"} */}
				{context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].COPY_BUTTON_TEXT}
			</Button>

			<div style={{ height: "100%", overflow: "auto" }}>
				{loading ? (
					<div style={{ textAlign: "center", marginTop: "220px", flex: 1, justifyContent: "center", alignItems: "center" }}>
						<Spin indicator={antIcon} />
						<p>Loading...</p>
					</div>
				) : (
					<Form form={form} component={false}>
						<Table columns={columns} dataSource={data} scroll={{ x: "false", y: 600 }} pagination={false} />
					</Form>
				)}
			</div>
		</div>
	);
}
