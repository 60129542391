import React from "react";
import BgImg from "./LandingBanner.jpg";
import AppContext from "../../../../store";
import { HomepageModuleMessages } from "../../../../constants/messages";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";

/**
 * This is the top banner which is rendered on the landing page
 *
 * @returns heading and description in a banner form on landing page
 */

export default function LandingBanner() {
	const context = React.useContext(AppContext);
	const markdownContent =
		context.envId && HomepageModuleMessages[context.envId].HERO_CONTENT ? (
			<Markdown remarkPlugins={[breaks, gfm]}>{HomepageModuleMessages[context.envId].HERO_CONTENT}</Markdown>
		) : null;

	return (
		<div className="min-h-1/3 px-4 bg-banner text-text-gray bg-cover bg-right" style={{ backgroundImage: `url(${BgImg})`, minHeight: "25vh" }}>
			<div className="flex flex-col justify-center h-full w-1/2 py-8 pl-2">
				<h2 className="text-xl font-bold mb-4 text-text-gray">{context.envId && HomepageModuleMessages[context.envId].HERO_TITLE}</h2>
				<p className="font-normal font-base tracking-wide">{markdownContent}</p>
			</div>
		</div>
	);
}
