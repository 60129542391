// NodeGraph.tsx
import React, { useState, useCallback, useRef, useEffect } from "react";
import Tree, { NodeSvgShape } from "react-d3-tree";
import { linkHorizontal, linkVertical } from "d3-shape";
import { Transcripts } from "../../../../Components/NodeGraphTranscripts";
import "../../styles.css";
import { PlusOutlined, MinusOutlined, ZoomInOutlined, ZoomOutOutlined, UndoOutlined, InfoOutlined } from "@ant-design/icons";
import { message, Button, Tooltip, Popover } from "antd";
import * as NodeGraphServiceHelper from "../../../SearchEngine/ServiceHelpers/nodeGraph";
import AppContext from "../../../../store";

const svgRect: NodeSvgShape = {
	shape: "rect",
	shapeProps: {
		width: 0,
		height: 0,
		x: -20,
		y: 20,
		stroke: "#2F80ED",
	},
};

const NodeLabel: React.FC<any> = ({ className, nodeData, selectedNode }) => {
	const [collapsed, setCollapsed] = useState(false);
	const [hovered, setHovered] = useState(false);
	const context = React.useContext(AppContext);

	const hasChildren = nodeData.children && nodeData.children.length > 0;
	const hoverText = nodeData.description ? nodeData.description : null; // Generate the text to be displayed on hover
	const isLeafNode = nodeData.leaf_node === "true";
	const isEmergingLeafNode = context.envId && context.envId === 2 ? false : nodeData.leaf_node === "true" && nodeData.color_code === "emerging";

	const handleMouseEnter = () => {
		setHovered(true);
	};

	const handleMouseLeave = () => {
		setHovered(false);
	};

	const handleToggleCollapse = () => {
		// console.log("button state:" + collapsed)

		if (collapsed) setCollapsed(false);
		else setCollapsed(true);

		// console.log("updated button state:" + collapsed)
	};

	const maxWords = isLeafNode ? 5 : 8;
	const nodeName = nodeData.name;
	const truncatedNodeName = nodeName && nodeName.split(" ").length > maxWords ? `${nodeName.split(" ").slice(0, maxWords).join(" ")}...` : nodeName;

	const depth = nodeData.depth || 0;
	const backgroundColor = isEmergingLeafNode ? "#FF8C8B" : isLeafNode ? "white" : depth === 0 ? "#F9F0FF" : depth === 1 ? "#eff8fe" : "#E6F7FF";
	const color = isLeafNode ? "black" : depth === 0 ? "#722ED1" : depth === 1 ? "#09558C" : "#1890FF";
	const nodeHeight = isLeafNode ? 30 : 40;
	const nodeFontWeight = isLeafNode ? 400 : 600;

	return (
		<div
			// className={className + " node-container"}
			className={
				className +
				` node-container ${
					selectedNode && selectedNode.id === nodeData.id_kural
						? isEmergingLeafNode
							? "emerging-leaf-selected-node"
							: isLeafNode
							? "leaf-selected-node"
							: "selected-node"
						: ""
				}`
			}
			style={{
				backgroundColor,
				height: nodeHeight,
				fontWeight: nodeFontWeight,
			}}
			onClick={handleToggleCollapse}
		>
			{/* {console.log("------------------------------ selectedNode nodeData.id", selectedNode, nodeData)} */}
			<div className="node-name" style={{ color }} title={nodeName}>
				{truncatedNodeName}
			</div>
			{!isLeafNode && (
				<>
					<div className="node-button" onClick={handleToggleCollapse}>
						<button
							// disabled={!hasChildren}
							style={{
								height: "15px",
								width: "15px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								backgroundColor: "white",
								border: "1px solid #2D8B93",
								borderRadius: "2px",
								boxShadow: "0px 1px 2px 0px #00000040",
							}}
						>
							{nodeData.depth != 0 ? (
								collapsed ? (
									<MinusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
								) : (
									<PlusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
								)
							) : nodeData.depth == 0 ? (
								<PlusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
							) : (
								<MinusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
							)}
						</button>
					</div>
					{/* <div
            className="info-icon"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <InfoCircleOutlined
              style={{ fontSize: "12px", color: "#2D8B93" }}
            />
            {hovered && <div className="info-text">{hoverText}</div>}
          </div> */}
				</>
			)}
			{isLeafNode && (
				<div className="node-number-box">
					<span>{nodeData.no_of_transcripts}</span>
				</div>
			)}
		</div>
	);
};

const drawDiagonalPath = (linkData: any, orientation: any) => {
	const { source, target } = linkData;
	return orientation === "horizontal"
		? linkHorizontal()({
				source: [source.y, source.x - 20],
				target: [target.y - 180, target.x - 20],
		  })
		: linkVertical()({
				source: [source.x, source.y],
				target: [target.x, target.y],
		  });
};

interface NodeGraphProps {
	data: any;
	selectedStudyDetails: any;
}

const ReadMore: React.FC<{ children: string }> = ({ children }) => {
	const text = children;
	const [isReadMore, setIsReadMore] = useState(true);

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const wordLimit = 840;

	return (
		<p className="pt-2">
			{text.length > wordLimit ? (isReadMore ? text.slice(0, wordLimit) + "..." : text) : text}
			{text.length > wordLimit && (
				<span onClick={toggleReadMore} className="text-text-teal font-normal cursor-pointer">
					{isReadMore ? <>Show more</> : <>Show less</>}
				</span>
			)}
		</p>
	);
};

const NodeGraph: React.FC<NodeGraphProps> = ({ data, selectedStudyDetails }) => {
	interface ISelectedNode {
		id: string;
		name: string;
		summary: string;
		refetch: boolean;
	}

	interface ITranscriptPayload {
		nodeId: string;
		studyId: number;
		waveId: number;
		geoId: number;
		studyIterId: number;
	}

	interface IMedicalTranscriptPayload {
		nodeId: string;
		nodeName: string;
	}

	const context = React.useContext(AppContext);
	const rootChildren = data.children; // Get the children of the root node
	const treeRef = useRef<any>(null);
	const summaryRef = useRef<HTMLDivElement>(null);

	const [showSummary, setShowSummary] = useState(false);
	const [transcriptDataArray, setTranscriptDataArray] = useState<any[]>([]);
	const [scale, setScale] = useState<number>(1);
	const [translate, setTranslate] = useState<{ x: number; y: number }>({ x: 200, y: 300 });
	const [isDragging, setIsDragging] = useState(false);
	const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);
	const [selectedNode, setSelectedNode] = useState<ISelectedNode | null>(null);
	const [nodeSummaryPollingId, setNodeSummaryPollingId] = useState<number>(-1);
	const [isFetchingNodeSummary, setIsFetchingNodeSummary] = useState<boolean>(false);

	// API Request to fetch NG Node Transcript Data
	async function fetchTranscriptData() {
		// const infoMessage = message.info("Fetching Transcript data...");
		try {
			if (!selectedNode) {
				return;
			}

			let data: any = [];

			if (context.envId && context.envId === 2) {
				const query: IMedicalTranscriptPayload = {
					nodeId: selectedNode.id,
					nodeName: selectedNode.name,
				};

				data = await NodeGraphServiceHelper.getMedicalTranscriptDetailsForNode(query);
			} else {
				const query: ITranscriptPayload = {
					nodeId: selectedNode.id,
					studyId: parseInt(selectedStudyDetails.studyId, 10),
					waveId: parseInt(selectedStudyDetails.waveId, 10),
					geoId: parseInt(selectedStudyDetails.regionId, 10),
					studyIterId: selectedStudyDetails.studyIterId,
				};

				data = await NodeGraphServiceHelper.getTranscriptDetailsForNode(query);
			}

			if (data && data.searchResults) {
				setTranscriptDataArray(data.searchResults);
				if (data.summaryId !== undefined) {
					setNodeSummaryPollingId(data.summaryId);
				}
				// infoMessage();
				//message.success("Successfully fetched Transcript Data");
				// console.log("Fetched Transcript Data -> ", data);
			} else {
				// infoMessage();
				message.error("No transcript data found");
				console.log("Fetch Transcript Data API returned empty data or missing searchResults.");
			}
		} catch (error) {
			// infoMessage();
			message.error("Error fetching transcript data");
			console.error("Error fetching Transcript Data:", error);
		}
	}

	async function fetchPollingNodeSummaryStatus() {
		if (nodeSummaryPollingId === -1 || isFetchingNodeSummary) return;

		setIsFetchingNodeSummary(true);
		try {
			const data: any = await NodeGraphServiceHelper.getMedicalPollingNodeSummary(nodeSummaryPollingId);

			switch (data.status) {
				case "COMPLETED":
					setSelectedNode((prevState) => ({
						...prevState!,
						summary: data.summary,
						refetch: false,
					}));
					setNodeSummaryPollingId(-1);
					setIsFetchingNodeSummary(false);
					return;
				case "FAILED":
					message.error("Failed to generate node summary");
					setSelectedNode((prevState) => ({
						...prevState!,
						summary: "Failed to generate node summary, please try again later.",
						refetch: false,
					}));
					setNodeSummaryPollingId(-1);
					setIsFetchingNodeSummary(false);
					break;
				case "NOTSTARTED":
				case "INPROGRESS":
					setTimeout(() => fetchPollingNodeSummaryStatus(), 5000);
					break; // Early return to prevent resetting state
				default:
					console.warn(`Unexpected status: ${data.status}`);
			}
		} catch (error) {
			console.error("Error fetching node summary status:", error);
			message.error("Error fetching node summary status");
		} finally {
			setNodeSummaryPollingId(-1);
			setIsFetchingNodeSummary(false);
		}
	}

	useEffect(() => {
		selectedNode && selectedNode.refetch && fetchTranscriptData();
	}, [selectedNode]);

	useEffect(() => {
		if (nodeSummaryPollingId !== -1 || !isFetchingNodeSummary) {
			fetchPollingNodeSummaryStatus();
		}
	}, [nodeSummaryPollingId, isFetchingNodeSummary]);

	const handleOnClickNode = (nodeData: any) => {
		// console.log("Node Clicked.", nodeData.summary);
		if (nodeData.summary) {
			const newNode: ISelectedNode = {
				id: nodeData.id_kural,
				name: nodeData.name,
				summary: nodeData.summary,
				refetch: true,
			};
			setSelectedNode(newNode);
			setShowSummary(true); // Show the summary on node click
			setNodeSummaryPollingId(-1); // Reset summaryId when selecting a new node

			// Set a timeout to allow time for the summary pane to render
			setTimeout(() => {
				if (summaryRef.current) {
					summaryRef.current.scrollIntoView({
						behavior: "smooth",
						block: "start",
					});
				}
			}, 100);
		} else {
			setShowSummary(false); // Hide the summary on node click
			setSelectedNode(null);
		}
	};

	const handleZoom = useCallback((newScale: number) => {
		setScale((prevScale) => {
			const updatedScale = Math.min(Math.max(newScale, 0.1), 3); // Clamp between 0.1 and 3
			if (treeRef.current) {
				treeRef.current.setState({ scale: updatedScale }, () => {
					treeRef.current.setState({ transitionDuration: 500 });
				});
			}
			return updatedScale;
		});
	}, []);

	const handleZoomIn = useCallback(() => handleZoom(scale * 1.2), [scale, handleZoom]);
	const handleZoomOut = useCallback(() => handleZoom(scale / 1.2), [scale, handleZoom]);
	const handleZoomReset = useCallback(() => handleZoom(1), [handleZoom]);

	const handleKeyDown = useCallback((event: KeyboardEvent) => {
		const panSpeed = 50;
		switch (event.key) {
			case "ArrowUp":
				setTranslate((prev) => ({ ...prev, y: prev.y + panSpeed }));
				break;
			case "ArrowDown":
				setTranslate((prev) => ({ ...prev, y: prev.y - panSpeed }));
				break;
			case "ArrowLeft":
				setTranslate((prev) => ({ ...prev, x: prev.x + panSpeed }));
				break;
			case "ArrowRight":
				setTranslate((prev) => ({ ...prev, x: prev.x - panSpeed }));
				break;
		}
	}, []);

	const handleMouseDown = useCallback((e: React.MouseEvent) => {
		setIsDragging(true);
		setDragStart({ x: e.clientX, y: e.clientY });
	}, []);

	const handleMouseMove = useCallback(
		(e: React.MouseEvent) => {
			if (isDragging && dragStart) {
				const dx = e.clientX - dragStart.x;
				const dy = e.clientY - dragStart.y;
				setTranslate((prev) => ({ x: prev.x + dx, y: prev.y + dy }));
				setDragStart({ x: e.clientX, y: e.clientY });
			}
		},
		[isDragging, dragStart]
	);

	const handleMouseUp = useCallback(() => {
		setIsDragging(false);
		setDragStart(null);
	}, []);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	const instructionsContent = (
		<div>
			<p>
				<strong>Keyboard Controls:</strong>
			</p>
			<ul>
				<li>Use arrow keys to pan the chart</li>
				<li>Hold and drag with the mouse to pan</li>
			</ul>
			<p>
				<strong>Zoom Controls:</strong>
			</p>
			<ul>
				<li>Use the buttons below to zoom in/out</li>
			</ul>
		</div>
	);

	return (
		<div
			id="treeWrapper"
			style={{ width: "100%", height: "75vh", overflowY: "auto", cursor: isDragging ? "grabbing" : "grab" }}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseUp}
		>
			<Tree
				ref={treeRef}
				data={data}
				nodeSvgShape={svgRect}
				pathFunc={drawDiagonalPath}
				separation={{ siblings: 0.5, nonSiblings: 0.5 }}
				orientation="horizontal"
				translate={translate}
				scaleExtent={{ min: 0.1, max: 3 }}
				zoom={scale}
				zoomable={false}
				allowForeignObjects={true}
				nodeLabelComponent={{
					render: <NodeLabel className="myLabelComponentInSvg" selectedNode={selectedNode} />,
					foreignObjectWrapper: {
						width: 200,
						height: 55,
						y: -50,
						x: -190,
					},
				}}
				initialDepth={0.02}
				depthFactor={350}
				shouldCollapseNeighborNodes={false}
				onClick={handleOnClickNode}
				transitionDuration={500}
			/>

			<div
				style={{
					position: "sticky",
					bottom: 20,
					right: 15,
					zIndex: 1000,
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end", // Align controls to the right
				}}
			>
				<Popover content={instructionsContent} title="Chart Interactions" trigger="click" placement="topRight">
					<Button className="graph-control-button" icon={<InfoOutlined />} shape="circle" />
				</Popover>
				<Tooltip title="Zoom Out" placement="topRight">
					<Button className="graph-control-button" icon={<ZoomOutOutlined />} onClick={handleZoomOut} />
				</Tooltip>
				<Tooltip title="Reset Zoom" placement="topRight">
					<Button className="graph-control-button" icon={<UndoOutlined />} onClick={handleZoomReset} />
				</Tooltip>
				<Tooltip title="Zoom In" placement="topRight">
					<Button className="graph-control-button" icon={<ZoomInOutlined />} onClick={handleZoomIn} />
				</Tooltip>
			</div>

			{/* Bottom Summary and Transcript Footer component */}
			{showSummary && selectedNode && (
				<div ref={summaryRef} className="node-summary">
					<div className="node-summary-header">
						<span>{selectedNode.name}</span>
					</div>
					<div className="node-summary-divider" />
					<div className="node-summary-body">
						<span className="font-semibold text-xs">Summary:</span>
						<br />
						<span className="text-xs/3">
							<ReadMore>{selectedNode.summary}</ReadMore>
						</span>
					</div>
					<div className="node-summary-divider" />
					<div className="node-summary-body">
						<span className="font-semibold text-xs">References:</span>
						<br />
					</div>
					{transcriptDataArray.map((transcriptData, index) => (
						<div key={index}>
							<Transcripts data={transcriptData} />
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default NodeGraph;
