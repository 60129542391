import {
	// Button,
	// DatePicker,
	// Input,
	// Pagination,
	Select,
	List,
	message,
	Skeleton,
	// Tag,
	Empty,
	// Slider,
	Switch,
	Alert,
} from "antd";
import { Transcripts } from "../../Components/SearchEngineCards/Transcripts";
import { ResponsiveLine } from "@nivo/line";
import { icons } from "../../GlobalThemes";
import React, { useState, useEffect } from "react";
import { FilterInterface } from "../Dashboard";
import { Drawer as AntDrawer } from "antd";
import Drawer from "../Dashboard/Components/Drawer";
import { FilterCard } from "../../Components/FilterCard";
import { Choropleth, ResponsiveChoropleth } from "@nivo/geo";
import { usFeatures } from "../../libraries/report-types/charts/geoMap/geoUSFeatures";
import TranscriptsWrapper from "../../Components/TransciptsWrapper";
import SummaryCard from "../../Components/SummaryCard/SummaryCard";
import { fetchedFilters, searchFilterActiveStatus, words } from "./data";
import { ISearchFilterValues, SearchFilters } from "../../Components/SearchEngineCards/SearchFilters";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import * as SearchServiceHelper from "./../SearchEngine/ServiceHelpers/search";
import * as OldStoriesServiceHelper from "./../SearchEngine/ServiceHelpers/oldStories";
import moment from "moment";
import * as Messages from "../../Components/Messages";
import AppContext from "../../store";
import { ResponsiveBar } from "@nivo/bar";
import { Collapse } from "antd";
import { getSearchEmployee } from "../../services/search";
import StreamingComponent from "./StreamingComponent";
import { useDispatch, useSelector } from "react-redux";
import {
	selectStore,
	setSearchSummaryFilterInSync,
	setSearchFilters,
	setSearchStreamedTitle,
	setSearchStreamedData,
	setSearchLikeStatus,
	setSearchDislikeStatus,
	setSearchRefData,
} from "../../state/slice/appSlice";
import { SearchModuleMessages } from "../../constants/messages";
import { IFetchedFilter } from "../../model/interface/search";

export interface searchQuery {
	deepSearch?: boolean;
	testButton1?: boolean;
	testButton2?: boolean;
	testButton3?: boolean;
	testButton4?: boolean;
	testButton5?: boolean;
	searchWord?: string;
	startDate?: string | any;
	endDate?: string | any;
	speaker?: string;
	showResults?: boolean;
	storyId?: number;
	callTypeIds?: number[];
	therapyStatusIds?: number[];
	emotions?: number[];
	emotionsGroup?: number[];
	sentiment?: number[];
	topics?: number[];
	totStart?: string;
	totEnd?: string;
	studyIds?: number | string[];
	metaDataIds?: string[];
	study?: number | string;
	additional_filters?: string[];
	wave?: string;
	region?: string;
	studyId?: number | string[];
	pptSearch?: boolean;
	userProfile?: string;
}

var trace1 = {
	x: [1, 2, 3, 4, 5],
	y: [1, 6, 3, 6, 1],
	mode: "markers",
	type: "scatter",
	name: "Team A",
	text: ["A-1", "A-2", "A-3", "A-4", "A-5"],
	marker: { size: 12 },
};

var trace2 = {
	x: [1.5, 2.5, 3.5, 4.5, 5.5],
	y: [4, 1, 7, 1, 4],
	mode: "markers",
	type: "scatter",
	name: "Team B",
	text: ["B-a", "B-b", "B-c", "B-d", "B-e"],
	marker: { size: 12 },
};

var data = [trace1, trace2];

var layout = {
	xaxis: {
		range: [0.75, 5.25],
	},
	yaxis: {
		range: [0, 8],
	},
	title: "Data Labels Hover",
};

export interface transcriptDataInter {
	// filterOptions: {
	// 	filterName: string;
	// 	type: string;
	// 	options: string[];
	// }[];
	numberOfPatients?: number;
	numberOfSearchResults?: number;
	emotionsCounts: any;
	statesResults: any;
	gptSummary?: any;
	gptSummaryText?: string;
	headers?: any;
	searchResults: {
		id: number;
		s3path: string;
		callDate: string;
		callId: string;
		callTime: string;
		callTypeIds: string;
		content?: string;
		context: string;
		context_preview: string;
		patientId: number;
		dialogueNo: number;
		name: string;
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory: boolean;
		storiesAdded: {
			storyId: any;
			storyName: string;
		}[];
		speaker: string;
		elasticSearchId: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
		storyDialogues: any;
		wave: string;
		geography: string;
		fileName: string;
		source: string;
		studyName: string;
		studyId: number;
		FileType?: string;
	}[];
}

/**
 * This page is the main search tab in Search Engine Page. This page renders searched transcripts in a list.
 *
 * @returns List of Searched Transcripts.
 */

export function Search() {
	const context = React.useContext(AppContext);
	const dispatch = useDispatch();
	const store = useSelector(selectStore);

	const [showResultSummary, setShowResultSummary] = useState<boolean>(false);
	const [expanded, setExpanded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [transcriptData, setTranscriptData] = useState<transcriptDataInter[]>([]);
	const [fetchedFilters, setFetchedFilters] = React.useState<IFetchedFilter[]>([]);
	const dataCopy = React.useRef<transcriptDataInter[]>([]);
	const [pinnedStories, setPinnedStories] = useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);
	const [transcriptLoading, setTranscriptLoading] = useState<boolean>(false);
	const [streamedSummaryLoading, setStreamedSummaryLoading] = useState<boolean>(false);
	const [searchTranscriptQuery, setSearchTranscriptQuery] = useState<searchQuery>({});
	const [searchWordRequired, setsearchWordRequired] = useState<boolean>(false);
	const [searchFilterData, setSearchFilterData] = useState<any>();
	const [selectLevel, setSelectLevel] = useState<any>();
	const [therapyStatus, setTherapyStatus] = useState<any>();
	const [startDate, setStartDate] = useState<any>();
	const [endDate, setEndDate] = useState<any>();
	const [metaData, setMetaData] = useState<any>();
	const [streamedData, setStreamedData] = useState<string>("Loading Data...");
	const [streamingProcess, setStreamingProcess] = useState<boolean>(false);
	const [streamQuery, setStreamQuery] = useState<any>(null);
	const [storyType, setStoryType] = useState<number>();
	const [emptyTextField, setEmptyTextField] = useState("View transcripts after searching with criteria");
	const [searchString, setSearchString] = React.useState("");
	const [filters, setFilters] = React.useState<FilterInterface>({
		Timeframe_Display: undefined,
		Start_Date: "",
		End_Date: "",
		Call_Type: undefined,
		Therapy_Status: undefined,
		Call_Intent: undefined,
		Adherence: undefined,
		Sentiment: undefined,
		Topics: undefined,
		Emotions: new Array<string>(),
		totStart: undefined,
		totEnd: undefined,
	});
	const [isSearchStreamClosed, setIsSearchStreamClosed] = useState(true);

	useEffect(() => {
		if (store.searchTab.searchStreamedData !== "" && streamedData === "Loading Data...") {
			setSearchString(store.searchTab.searchStreamedTitle);
			setStreamedData(store.searchTab.searchStreamedData);
			setTranscriptData(store.searchTab.searchRefData);
			setStoryType(store.searchTab.searchFilters.pptSearch ? 2 : 1);
		}
	}, [store.searchTab]);

	useEffect(() => {
		storyType && fetchStoryData();
	}, [storyType]);

	useEffect(() => {
		if (transcriptData.length > 0) {
			// Dispatch setSearchStreamedData and setSearchRefData
			dispatch(setSearchStreamedTitle(searchString));
			dispatch(setSearchStreamedData(streamedData));
			dispatch(setSearchLikeStatus(false));
			dispatch(setSearchDislikeStatus(false));
			dispatch(setSearchRefData(transcriptData));
		}
	}, [transcriptData]);

	useEffect(() => {
		let data: ISearchFilterValues = {};

		// Check if searchTranscriptQuery is truthy
		if (searchTranscriptQuery) {
			// AssignppearchTranscriptQuery;
			data = searchTranscriptQuery;
			// Dispatch setSearchFilters
			if (Object.keys(data).length !== 0) {
				dispatch(setSearchFilters(data));
			}
		}
	}, [searchTranscriptQuery]);

	async function* readStream(response: Response): AsyncGenerator<string> {
		const reader = (await response.body)!.getReader();
		let buffer = "";
		try {
			while (true) {
				const { done, value } = await reader.read();

				if (done) {
					// The stream has ended
					break;
				}

				// Concatenate the received chunk with the buffer
				const val = new TextDecoder("utf-8").decode(value);
				buffer += val;

				// Split the buffer into lines
				const lines = buffer.split("\n");
				// Iterate over complete lines (excluding the last one)
				for (let i = 0; i < lines.length - 1; i++) {
					const line = lines[i].trim();

					if (line.startsWith("data:")) {
						// Yield the line without the "data: " prefix
						yield line.slice("data:".length).trim();
					}
				}

				// The last line might be incomplete, so update the buffer
				buffer = lines[lines.length - 1];
			}
		} finally {
			// Ensure the reader is closed when done
			reader.releaseLock();
			setTranscriptLoading(false);
			setIsSearchStreamClosed(true);
		}
	}

	useEffect(() => {
		if (streamQuery && streamQuery.searchWord) {
			setTranscriptLoading(true);
			setStreamedSummaryLoading(true);
			setIsSearchStreamClosed(false);

			setStreamedData("");
			setTranscriptData([{ emotionsCounts: [], statesResults: [], searchResults: [] }]);

			const fetchData = async () => {
				try {
					const response = await fetch(process.env.REACT_APP_API_URL + "search/stream", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"X-Api-Key": "54ebade0-e168-41b1-a671-d4b44213bbdb",
						},
						credentials: "include",
						body: JSON.stringify(streamQuery),
					});

					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}

					for await (const line of readStream(response)) {
						let summ_flag = true;
						if (line === "[TEXT_SUMMARY]" || line === "[FRAMEWORK_SUMMARY]" || line === "[DONE]" || line === "[END]") {
							// console.log("MAJOR Event: ", line); // Commented out console.log
							continue;
						}

						if (line === "[REFERENCES]") {
							// console.log("Skip Messages: ", message); // Commented out console.log
							summ_flag = false;
							continue;
						}

						const newData = JSON.parse(line);

						if (newData.event === "text_summary" || newData.event === "framework_summary") {
							// console.log("Summ Line: ", newData.data);
							setStreamedData((prevData) => prevData + newData.data);
						} else if (newData.event === "references") {
							// console.log("Ref Line: ", newData);
							const RefData = JSON.parse(newData.data);
							// console.log("Ref Data JSON: ", RefData); // Commented out console.log
							setTranscriptData([RefData]);
						}

						setStreamedSummaryLoading(false);
					}
				} catch (error) {
					// Handle any errors that occur during the fetchData function
					console.error("An error occurred while fetching data:", error);
				}
			};

			fetchData();
			fetchStoryData();
		}
	}, [streamingProcess]);

	const handleFilters2 = (filterValue: string | boolean) => {
		setSelectLevel(filterValue);
	};

	const handleFilters3 = (filterValue: string | boolean) => {
		//    console.log(filterValue);
		setTherapyStatus(filterValue);
	};

	var loadingState = true;
	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};

	const clearFilters = () => {
		const obj = {
			Timeframe_Display: undefined,
			Start_Date: "",
			End_Date: "",
			Call_Type: undefined,
			Therapy_Status: undefined,
			Call_Intent: undefined,
			Adherence: undefined,
			Sentiment: undefined,
			Topics: undefined,
			Emotions: new Array<string>(),
			totStart: undefined,
			totEnd: undefined,
		};
		setFilters(obj);
		setVisible(false);
		setTranscriptFilters(obj);
	};

	async function fetchFilters() {
		const metaData: any = await getSearchEmployee(-1);
		if (metaData) {
			setMetaData(metaData);

			// context.setDates(metaData[0].startDate,metaData[0].startDate,metaData[0].endDate);
		}
		if (context.envId !== 3) {
			const type = context.envId === 1 ? "search" : undefined;
			const data: any = await SearchServiceHelper.getFilters(type);
			// data.push({
			//   filter: "TOT",
			//   optionsObject: [],
			//   type: "slider",
			// });
			if (data) {
				// console.log("Check fetchFilters",data);
				// console.log("MetaData:"+ metaData[0]);
				setFetchedFilters(data);
			}
		}
	}

	async function fetchStoryData() {
		const data: any = await OldStoriesServiceHelper.getAllStories(storyType);

		if (data) {
			setFetchedStoryData(data);
		}
	}

	// function updateStoriesData() {
	//   fetchStoryData();
	// }

	//   const fetchTranscripts = async (query: searchQuery) => {
	//     setStreamQuery(query);
	//     setTranscriptLoading(true);
	//     Messages.loading("Fetching...", "key1");
	//     const data: any = await SearchServiceHelper.getAllTranscripts(query);
	//     setTranscriptLoading(false);
	//     const key = "Key 1";

	//     if (data) {
	//       setStreamingProcess(true);
	//       setTranscriptData([data]);
	// //      console.log(transcriptData);
	//       dataCopy.current = [data];
	//       Messages.success("Success", "key1");
	//     } else {
	//       Messages.error("Fetching failed", "key1");
	//       setTranscriptData([]);
	//     }
	//   };

	async function addNewStory(addData: { StoryName: string }) {
		const key = "updatable";
		Messages.loading("Adding Story", key);
		// console.log(addData);
		const data: any = await OldStoriesServiceHelper.addNewStory(addData);
		if (data) {
			var temp: any = [];
			var d = data["Date Created"];
			data["Date Created"] = moment(d).format("DD MMM YYYY");
			temp = [data, ...fetchedStoryData];
			setFetchedStoryData(temp);
			Messages.success("Successfully Created New Story", key);
		} else Messages.error("Failed", key);
	}

	async function addStoryToTranscriptData(transcript: {
		id: any;
		name: any;
		tags: any;
		storiesAdded: any;
		source: number;
		studyId: number;
		trId: number;
		studyName: string;
		geography: string;
		wave: string;
		fileName: string;
		dialogueNo: any;
		callId: any;
		callDate: any;
		callTime: any;
		s3path: any;
		addedToStory: any;
		score: number;
		dialogueAppearedAt: any;
		dialogueLength: any;
		transcriptLength: any;
		transcripts: any;
		patientId: any;
		speaker: any;
		content: any;
		context: string;
		context_preview: string;
		elasticSearchId: any;
		storyDialogues: any;
		therapyStatusId: string;
		notAddedStories: any;
		FileType: string;
	}) {
		const key = "updatable";
		Messages.loading("Adding Transcript to Story", key);
		// console.log("Trans", transcript);
		const data: any = await OldStoriesServiceHelper.addTransToStory(transcript);
		// console.log("Fetched : ", data);
		if (data) {
			Messages.success("Successfully Added Transcript to Story", key);

			// const temp = [...transcriptData];
			const temp = JSON.parse(JSON.stringify(transcriptData)); // Deep copy of transcriptData
			//      console.log("transc", transcriptData, temp, transcript);

			const idx = temp[0].searchResults.findIndex((t: any) => t.callId === transcript.callId && t.dialogueNo === transcript.dialogueNo);

			temp[0].searchResults[idx].storiesAdded = data;
			if (data.length === 0) {
				temp[0].searchResults[idx].addedToStory = false;
			}
			setTranscriptData(temp);

			return true;
		} else {
			Messages.error("Failed", key);
			return false;
		}
	}

	useEffect(() => {
		fetchFilters();
		// setSearchFilterData(["Good"]);
		// fetchStoryData();
	}, []);

	const addToStorySearch = (transcriptData: any, storyId: any) => {
		var request = {
			transcriptData: transcriptData,
			storyId: storyId,
		};
		var add: any = [];
		storyId.forEach((story: any, index: number) => {
			add.push({
				storyId: story,
				storyName: fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)].storyName,
			});
		});

		// var updatedStoryDialogues = transcriptData.storyDialogues.map((dialogue: any) => {
		// 	return {
		// 		story_id: dialogue.story_id,
		// 		selected_call_id: dialogue.selected_call_id, //transcriptData.elasticSearchId,
		// 		selected_dialogue_no: dialogue.selected_dialogue_no,
		// 		selected_index: dialogue.selected_index,
		// 		previous_call_id: dialogue.previous_call_id,
		// 		previous_dialogue_no: dialogue.previous_dialogue_no,
		// 		previous_index: dialogue.previous_index,
		// 		source: dialogue.source,
		// 		search_query: dialogue.search_query,
		// 	};
		// });
		// var updatedStoryDialogues = transcriptData.storyDialogues.map((dialogue: any) => {
		// 	return {
		// 		story_id: dialogue.story_id,
		// 		selected_call_id: dialogue.selected_call_id, //transcriptData.elasticSearchId,
		// 		selected_dialogue_no: dialogue.selected_dialogue_no,
		// 		selected_index: dialogue.selected_index,
		// 		previous_call_id: dialogue.previous_call_id,
		// 		previous_dialogue_no: dialogue.previous_dialogue_no,
		// 		previous_index: dialogue.previous_index,
		// 		source: dialogue.source,
		// 		search_query: dialogue.search_query,
		// 	};
		// });

		var trans = {
			id: transcriptData.id,
			name: transcriptData.name,
			tags: transcriptData.tags,
			storiesAdded: add,
			source: 1,
			studyId: transcriptData.studyId,
			trId: transcriptData.trId,
			studyName: transcriptData.studyName,
			geography: transcriptData.geography,
			wave: transcriptData.wave,
			fileName: transcriptData.fileName,
			dialogueNo: transcriptData.dialogueNo,
			callId: transcriptData.callId,
			callDate: transcriptData.callDate,
			callTime: transcriptData.callTime,
			s3path: transcriptData.s3path,
			addedToStory: true,
			score: transcriptData.score,
			dialogueAppearedAt: transcriptData.dialogue_appeared_at,
			dialogueLength: transcriptData.dialogueLength,
			transcriptLength: transcriptData.transcriptLength,
			transcripts: transcriptData.transcripts,
			patientId: transcriptData.patientId,
			speaker: transcriptData.speaker,
			content: transcriptData.content,
			context: transcriptData.context,
			context_preview: transcriptData.context_preview,
			elasticSearchId: transcriptData.elasticSearchId,
			storyDialogues: transcriptData.storyDialogues,
			callTypeId: transcriptData.callTypeId,
			therapyStatusId: transcriptData.therapyStatusId,
			notAddedStories: transcriptData.notAddedStories,
			FileType: transcriptData.FileType,
		};
		const d = addStoryToTranscriptData(trans);
		return d;
	};

	const changePinnedStories = (stories: any) => {
		setPinnedStories(stories);
	};
	React.useEffect(() => {
		// applyFilters();
		//    console.log("Checking Filter:" , filters);
	}, [filters]);

	const addSearchFilters = (query: searchQuery) => {
		// console.log("TEST SEARCH QUERY - ", query);
		var searchFilter: searchQuery = { ...searchTranscriptQuery };
		searchFilter.searchWord = query.searchWord;
		searchFilter.studyIds = query.studyIds;
		searchFilter.metaDataIds = query.metaDataIds;
		searchFilter.pptSearch = query.pptSearch;
		searchFilter.userProfile = context.userProfile ? context.userProfile.profileName : "";
		searchFilter.speaker = context.envId === 3 ? query.speaker : undefined;
		// console.log(
		// 	query.startDate,
		// 	moment(query.startDate, "MMM YYYY").format()
		// );
		// console.log(metaData);
		if (query.startDate && context.envId !== 3) {
			// searchFilter.startDate = moment(query.startDate).format();
			searchFilter.startDate = moment(query.startDate).startOf("month").add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "months");
		} else if (query.startDate && context.envId === 3) {
			searchFilter.startDate = moment(query.startDate).add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "days");
		} else {
			searchFilter.startDate = undefined;
			searchFilter.endDate = undefined;
		}
		searchFilter.deepSearch = query.deepSearch;
		searchFilter.testButton1 = query.testButton1;
		searchFilter.testButton2 = query.testButton2;
		// searchFilter.testButton3 = query.testButton3; // used for pptSearch on backend
		searchFilter.testButton4 = query.testButton4;
		searchFilter.testButton5 = query.testButton5;
		setStoryType(searchFilter.pptSearch ? 2 : 1); //2 for Reports and 1 for Trans
		checksearchWord(searchFilter);
		setSearchTranscriptQuery(searchFilter);
		dispatch(setSearchSummaryFilterInSync(true));
	};

	function findId(filter: string, value: string) {
		const grpIdx: number[] = [];
		const idx: number[] = [];
		fetchedFilters.map((f) => {
			f.optionsObject.map((group) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx.push(group.id);
						grpIdx.push(group.id);
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (Array.isArray(value) && value.find((f) => f === group.name)) {
							grpIdx.push(group.id);
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find((sg) => sg.name === v);
								if (ob) {
									grpIdx.push(group.id);
									idx.push(ob.id);
								}

								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	}

	const colors: Record<string, string> = {
		Inbound: "rgb(224,198,255)",
		Outbound: "rgb(114,50,207)",
		Agent: "rgb(169,218,253)",
		Patient: "rgb(169,218,253)",
		Positive: "rgb(38,195,141)",
		Neutral: "rgb(222,220,222)",
		Negative: "rgb(230,84,40)",
	};

	const getColor = (bar: any) => colors[bar.id];

	const setTranscriptFilters = (filter: any) => {
		//    console.log("Filter", filter);
		var transcriptFilters: searchQuery = searchTranscriptQuery;
		const callType = findId("Call_Type", filter.Call_Type);
		transcriptFilters.callTypeIds = callType.grpIdx;

		const therapyStatus = findId("Therapy_Status", filter.Therapy_Status);
		transcriptFilters.therapyStatusIds = therapyStatus.grpIdx;

		const sentiment = findId("Sentiment", filter.Sentiment);
		transcriptFilters.sentiment = sentiment.grpIdx;

		const topics = findId("Topics", filter.Topics);
		transcriptFilters.topics = topics.grpIdx;

		const emotions = findId("Emotions", filter.Emotions);
		transcriptFilters.emotionsGroup = emotions.grpIdx;
		transcriptFilters.emotions = emotions.idx;

		transcriptFilters.totStart = filter.totStart;
		transcriptFilters.totEnd = filter.totEnd;

		//    console.log("TranscriptFilter", transcriptFilters);
		setFilters(filter);
		checksearchWord(transcriptFilters);
		setSearchTranscriptQuery(transcriptFilters);
	};

	const checksearchWord = (query: searchQuery) => {
		if (query.searchWord === undefined || query.searchWord === "") {
			setsearchWordRequired(true);
		} else {
			setsearchWordRequired(false);
			setEmptyTextField("No Data");
			//      console.log(query);
			setStreamQuery(query);
			setStreamingProcess(!streamingProcess);
			// fetchTranscripts(query);
		}
	};

	//  console.log("transcriptData:", transcriptData);
	let findValueForTherapyStatusGraph = (therapyStatus: any, bound: any) => {
		// fitlerVal is an array
		let count = 0;

		transcriptData[0].searchResults?.map((i: any) => {
			if (i.tags[1] === therapyStatus && i.tags[0] === bound) ++count;
		});

		return count;
	};

	let findValueForSentimentGraph = (patient: any, sentiment: any, useTopic: boolean) => {
		if (useTopic === true) {
			let count = 0;
			transcriptData[0].searchResults?.map((i: any) => {
				if (i.tags[3] === sentiment && i.tags[4] === patient) ++count;
			});

			return count;
		} else {
			let count = 0;
			transcriptData[0].searchResults?.map((i: any) => {
				if (i.tags[3] === sentiment && i.speaker === patient) ++count;
			});

			return count;
		}
	};

	let findValueForToT = (ll: any, ul: any, therapyStatus?: any) => {
		if (therapyStatus) {
			let count = 0;

			transcriptData[0].searchResults?.map((i: any) => {
				if (i.tags[2] > ll && i.tags[2] < ul && i.tags[1] === therapyStatus) ++count;
			});
			return count;
		} else {
			let count = 0;

			transcriptData[0].searchResults?.map((i: any) => {
				if (i.tags[2] > ll && i.tags[2] < ul) ++count;
			});
			return count;
		}
	};

	// let temp:any = [];

	// let findGreatestVal: any = transcriptData[0].emotionsCounts.map((i:any) => {
	//   let t: any
	//   t.name = i.text;
	//   t.value = i.value;

	//   });

	// let findValueForTherapyDistributionGraph = (time: any) => {
	//   let count = 0;
	//   transcriptData[0].searchResults?.map((i: any) => {
	//     if (i.tags[4] === sentiment && i.speaker === patient) ++count;
	//   });

	//   return count;
	// };

	// let findValueForConceptGraph = (keyword: any) => {
	//   let count = 0;
	//   transcriptData[0].searchResults?.map((i: any) => {
	//     if (i.tags[4] === sentiment && i.speaker === patient) ++count;
	//   });

	//   return count;
	// };

	let temp: any = [];

	if (transcriptData.length > 0 && transcriptData[0].emotionsCounts) {
		let objs: any = transcriptData[0].emotionsCounts;

		if (objs.length > 0) {
			objs.sort((a: any, b: any) => b.value - a.value);
			objs.map((i: any, index: any) => {
				if (index < 10) {
					temp.push(i);
				}
			});
		}
	}

	let arrForFilteringChart: any = [];

	const summaryDummyData =
		transcriptData.length <= 0
			? null
			: "Calls can refer to different things, such as the Apple TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede Álvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena[1]. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets[1].Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device[2]. Troubleshooting tips are available if issues arise while using the calling feature[2]. Recent calls cannot be managed or deleted from the PC[2].Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls[3]. The service is available on Apple devices and other smartphones[3].";

	if (transcriptData.length > 0) {
		let tempArr: any = [
			"Dosing Information",
			"Closing",
			"Speciality Pharmacy",
			"Common Side Effects",
			"Next Call Confirmation",
			"Introduction",
			"Lab Monitoring Guidance",
			"Nurse Educator Role",
			"Others",
			"Patient Questionnaire",
			"Safety Recording",
			"Shipment",
			"Diet Related",
		];

		for (let i = 0; i <= tempArr.length; i++) {
			if (
				findValueForSentimentGraph(tempArr[i], "Negative", true) +
					findValueForSentimentGraph(tempArr[i], "Positive", true) +
					+findValueForSentimentGraph(tempArr[i], "Neutral", true) >
				0
			) {
				let obj: any = {};
				obj.Topic = tempArr[i];
				obj.Positive = findValueForSentimentGraph(tempArr[i], "Positive", true);
				obj["positive Color"] = "hsl(251 70%, 50%)";
				obj.Neutral = findValueForSentimentGraph(tempArr[i], "Neutral", true);
				obj["neutral Color"] = "hsl(41, 70%, 50%)";
				obj.Negative = findValueForSentimentGraph(tempArr[i], "Negative", true);
				obj["negative Color"] = "hsl(41, 70%, 50%)";
				arrForFilteringChart.push(obj);
			}
		}
	}

	return (
		<div>
			<AntDrawer title="Filters" placement="right" onClose={onClose} open={visible} destroyOnClose={true}>
				<Drawer
					filters={filters}
					setFilters={setTranscriptFilters}
					filterOptions={fetchedFilters}
					clearFilters={clearFilters}
					filterActiveStatus={searchFilterActiveStatus}
				/>
			</AntDrawer>

			<Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
				<Collapse.Panel
					style={{ paddingBottom: "0px" }}
					header={<h2 className="text-xl font-semibold ">{context.envId && SearchModuleMessages[context.envId].FILTERS_TITLE}</h2>}
					key="1"
				>
					<div className="w-screen p-4 shadow-lg" style={{ zIndex: 99, position: "relative", paddingRight: "3rem" }}>
						<FilterCard filters={filters} setFilters={setTranscriptFilters} filterActiveStatus={searchFilterActiveStatus} />
						<SearchFilters
							showDrawer={showDrawer}
							fetchTranscripts={addSearchFilters}
							searchWordRequired={searchWordRequired}
							searchString={searchString}
							setSearchString={setSearchString}
							fetchedFilters={fetchedFilters}
							isSearchStreamClosed={isSearchStreamClosed}
						/>
						{!store.searchTab.searchSummaryFilterInSync && (
							<Alert className="mt-4" message="Filters or query changed. Please click Search when ready to see updated results." type="warning" showIcon closable />
						)}
					</div>
				</Collapse.Panel>
			</Collapse>

			<div className="bg-body  p-4  flex" style={{ height: "calc(100vh - 18rem)" }}>
				<div
					className="bg-white shadow-lg shadow-gray-900"
					style={{
						overflowY: "auto",
						// height: `${transcriptData.length === 0 ? "fill-available" : "max-content"}`,
						flexBasis: showResultSummary ? "65%" : "100%",
					}}
				>
					{streamedSummaryLoading ? (
						<div>
							<Skeleton active className="py-4 px-8 border-b" />
						</div>
					) : (
						<div
							className="bg-white shadow-md shadow-gray-900 "
							style={{
								overflowY: "auto",
								zIndex: 99,
								position: "relative",
								// height: `${transcriptData.length === 0 ? "fill-available" : "max-content"}`,
								flexBasis: showResultSummary ? "65%" : "100%",
							}}
						>
							<SummaryCard
								data={streamedData ? streamedData : "No search results found."}
								// data={transcriptData.length>0 && transcriptData[0]["gptSummary"]?transcriptData[0]["gptSummary"]:""}
								apiResponseData={transcriptData.length > 0 ? transcriptData[0] : null}
								// data={summaryDummyData}
								searchString={searchString}
								isloaded={transcriptData.length > 0 ? true : false}
							></SummaryCard>
						</div>
					)}
					<TranscriptsWrapper
						header={`References`}
						expanded={expanded}
						toggleExpand={setExpanded}
						showSummary={showResultSummary}
						toggleSummary={setShowResultSummary}
						loading={transcriptLoading}
						noSideSummary={transcriptData.length > 0 ? false : true}
						goodSearch={transcriptData.length > 0 ? transcriptData[0].searchResults?.filter((x: any) => x.tags[0] === "Good").length : null}
						badSearch={transcriptData.length > 0 ? transcriptData[0].searchResults?.filter((x: any) => x.tags[0] === "Bad").length : null}
						averageSearch={transcriptData.length > 0 ? transcriptData[0].searchResults?.filter((x: any) => x.tags[0] === "Average").length : null}
					>
						{transcriptLoading ? (
							<div>
								<Skeleton active className="py-4 px-8 border-b" />
								<Skeleton active className="py-4 px-8 border-b" />
								<Skeleton active className="py-4 px-8 border-b" />
							</div>
						) : (
							<div className={transcriptData.length > 0 ? "" : "flex items-center justify-around h-96"}>
								<List
									itemLayout="vertical"
									size="large"
									pagination={{
										hideOnSinglePage: true,
										style: {
											marginLeft: "2rem",
											marginBottom: "1.2rem",
											float: "left",
											paddingBottom: "1rem",
										},
										onChange: (page) => {
											// console.log(page);
										},
										pageSize: 20,
									}}
									dataSource={transcriptData.length > 0 ? (transcriptData[0].searchResults ? transcriptData[0].searchResults : []) : []}
									locale={{
										emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
									}}
									renderItem={(result, index) => (
										<>
											{/* {console.log("Trans",result)} */}
											<Transcripts
												data={result}
												expanded={expanded}
												addToStoryApi={addToStorySearch}
												apiResponseData={transcriptData.length > 0 ? transcriptData[0] : null}
												fetchedStoryData={fetchedStoryData}
												fetchStoryData={fetchStoryData}
												addNewStory={addNewStory}
												pinnedStoriesData={pinnedStories}
												changePinnedStories={changePinnedStories}
												transcriptData={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
												searchString={searchString}
												setSearchString={setSearchString}
												tagName={"Search"}
												storyType={storyType}
											/>
										</>
									)}
								/>
							</div>
						)}
					</TranscriptsWrapper>
				</div>
				{showResultSummary ? (
					<div className="bg-white shadow-md shadow-gray-900 ml-4 p-4" style={{ flexBasis: "40%", overflowY: "auto" }}>
						<p className="font-semibold text-base">
							Search Result Summary
							{/* <p className="mt-7 font-semibold text-sm"> */}
							{/* </p> */}
						</p>
						<p className="font-semibold mt-5 text-sm mb-3">Therapy Status Distribution</p>
						<div className="flex h-60 border-2">
							<ResponsiveBar
								data={[
									{
										"Therapy Status": "Discontinued",
										Inbound: findValueForTherapyStatusGraph("Discontinued", "Inbound"),
										"inbound Color": "rgb(224,198,255)",
										Outbound: findValueForTherapyStatusGraph("Discontinued", "Outbound"),
										"outbound Color": "hsl(267, 100%, 88.8%)",
									},
									{
										"Therapy Status": "Therapy",
										Inbound: findValueForTherapyStatusGraph("On Therapy", "Inbound"),
										"inbound Color": "rgb(224,198,255)",
										Outbound: findValueForTherapyStatusGraph("On Therapy", "Outbound"),
										"outbound Color": "hsl(41, 70%, 50%)",
									},
									{
										"Therapy Status": "On Therapy Repeat",
										Inbound: findValueForTherapyStatusGraph("On Therapy Repeat", "Inbound"),
										"inbound Color": "rgb(224,198,255)",
										Outbound: findValueForTherapyStatusGraph("On Therapy Repeat", "Outbound"),
										"outbound Color": "hsl(41, 70%, 50%)",
									},
									{
										"Therapy Status": "In-Process",
										Inbound: findValueForTherapyStatusGraph("In Process", "Inbound"),
										"inbound Color": "rgb(224,198,255)",
										Outbound: findValueForTherapyStatusGraph("In Process", "Outbound"),
										"outbound Color": "hsl(41, 70%, 50%)",
									},
									{
										"Therapy Status": "Unknown",
										Inbound: findValueForTherapyStatusGraph("Unknown", "Inbound"),
										"inbound Color": "rgb(224,198,255)",
										Outbound: findValueForTherapyStatusGraph("Unknown", "Outbound"),
										"outbound Color": "hsl(41, 70%, 50%)",
									},
									{
										"Therapy Status": "Never Start",
										Inbound: findValueForTherapyStatusGraph("Never Start", "Inbound"),
										"inbound Color": "rgb(224,198,255)",
										Outbound: findValueForTherapyStatusGraph("Never Start", "Outbound"),
										"outbound Color": "hsl(41, 70%, 50%)",
									},
								]}
								keys={["Inbound", "Outbound"]}
								indexBy="Therapy Status"
								margin={{
									top: 20,
									right: 20,
									bottom: 90,
									left: 35,
								}}
								padding={0.3}
								valueScale={{ type: "linear" }}
								indexScale={{ type: "band", round: true }}
								// colors={{ scheme: "nivo" }}
								colors={getColor}
								defs={[
									{
										id: "dots",
										type: "patternDots",
										background: "inherit",
										color: "#38bcb2",
										size: 4,
										padding: 1,
										stagger: true,
									},
									{
										id: "lines",
										type: "patternLines",
										background: "inherit",
										color: "#eed312",
										rotation: -45,
										lineWidth: 6,
										spacing: 10,
									},
								]}
								borderColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								axisTop={null}
								axisRight={null}
								// gridYValues = {[10,20,30,40,50,60,70,80]}
								axisBottom={{
									format: (v) => {
										return v.length > 14 ? (
											<tspan>
												{v.substring(0, 12) + ".."}
												<p>{v}</p>
											</tspan>
										) : (
											v
										);
									},
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: <tspan>Therapy Status</tspan>,
									legendPosition: "middle",
									legendOffset: 40,
								}}
								axisLeft={{
									format: (e) => Math.floor(e) === e && e,
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: "food",
									legendPosition: "middle",
									legendOffset: -40,
								}}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								legends={[
									{
										dataFrom: "keys",
										anchor: "bottom",
										direction: "row",
										justify: false,
										translateX: 30,
										translateY: 80,
										itemsSpacing: 2,
										itemWidth: 100,
										itemHeight: 20,
										itemDirection: "left-to-right",
										itemOpacity: 0.85,
										symbolSize: 20,
										effects: [
											{
												on: "hover",
												style: {
													itemOpacity: 1,
												},
											},
										],
									},
								]}
								role="application"
								ariaLabel="Therapy Status Distribution"
							/>
						</div>
						<p className="font-semibold mt-5 text-sm mb-3">
							Discussion Sentiment
							<span className="font-normal float-right">
								Select Level
								<Select
									clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
									className="ml-4 -mt-2 float-right w-32"
									placeholder="Select Quality"
									allowClear
									defaultValue={selectLevel ? selectLevel : "Speaker"}
									onChange={(value: string) => {
										handleFilters2(value);
									}}
								>
									<Select.Option value="Topic">Topic</Select.Option>
									<Select.Option value="Speaker">Speaker</Select.Option>
								</Select>
							</span>
						</p>
						<div className="flex h-60 border-2">
							{selectLevel === "Topic" ? (
								<ResponsiveBar
									layout="horizontal"
									colors={getColor}
									data={arrForFilteringChart.map((i: any) => i)}
									keys={["Positive", "Neutral", "Negative"]}
									indexBy="Topic"
									margin={{
										top: 10,
										right: 110,
										bottom: 30,
										left: 160,
									}}
									padding={0.3}
									valueScale={{ type: "linear" }}
									indexScale={{ type: "band", round: true }}
									defs={[
										{
											id: "dots",
											type: "patternDots",
											background: "inherit",
											color: "#38bcb2",
											size: 4,
											padding: 1,
											stagger: true,
										},
										{
											id: "lines",
											type: "patternLines",
											background: "inherit",
											color: "#eed312",
											rotation: -45,
											lineWidth: 6,
											spacing: 10,
										},
									]}
									borderColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 0,
										tickPadding: 5,
										tickRotation: 0,
										legend: "",
										legendPosition: "middle",
										legendOffset: 38,
									}}
									axisLeft={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										// legend: "food",
										legendPosition: "middle",
										legendOffset: -40,
									}}
									labelSkipWidth={12}
									labelSkipHeight={12}
									labelTextColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									legends={[
										{
											dataFrom: "keys",
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 120,
											translateY: 0,
											itemsSpacing: 2,
											itemWidth: 100,
											itemHeight: 20,
											itemDirection: "left-to-right",
											itemOpacity: 0.85,
											symbolSize: 20,
											effects: [
												{
													on: "hover",
													style: {
														itemOpacity: 1,
													},
												},
											],
										},
									]}
									role="application"
									ariaLabel="Therapy Status Distribution"
								/>
							) : (
								<ResponsiveBar
									layout="horizontal"
									colors={getColor}
									data={[
										{
											Sentiment: "Patient",
											Positive: findValueForSentimentGraph("Patient", "Positive", false),
											"positive Color": "hsl(251, 70%, 50%)",
											Neutral: findValueForSentimentGraph("Patient", "Neutral", false),
											"neutral Color": "hsl(41, 70%, 50%)",
											Negative: findValueForSentimentGraph("Patient", "Negative", false),
											"negative Color": "hsl(41, 70%, 50%)",
										},
										{
											Sentiment: "Agent",
											Positive: findValueForSentimentGraph("Agent", "Positive", false),
											"positive Color": "hsl(251, 70%, 50%)",
											Neutral: findValueForSentimentGraph("Agent", "Neutral", false),
											"neutral Color": "hsl(41, 70%, 50%)",
											Negative: findValueForSentimentGraph("Agent", "Negative", false),
											"negative Color": "hsl(41, 70%, 50%)",
										},
									]}
									keys={["Positive", "Neutral", "Negative"]}
									indexBy="Sentiment"
									margin={{
										top: 20,
										right: 130,
										bottom: 50,
										left: 60,
									}}
									padding={0.3}
									valueScale={{ type: "linear" }}
									indexScale={{ type: "band", round: true }}
									defs={[
										{
											id: "dots",
											type: "patternDots",
											background: "inherit",
											color: "#38bcb2",
											size: 4,
											padding: 1,
											stagger: true,
										},
										{
											id: "lines",
											type: "patternLines",
											background: "inherit",
											color: "#eed312",
											rotation: -45,
											lineWidth: 6,
											spacing: 10,
										},
									]}
									borderColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 0,
										tickPadding: 5,
										tickRotation: 0,
										legend: "",
										legendPosition: "middle",
										legendOffset: 38,
									}}
									axisLeft={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										// legend: "food",
										legendPosition: "middle",
										legendOffset: -40,
									}}
									labelSkipWidth={12}
									labelSkipHeight={12}
									labelTextColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									legends={[
										{
											dataFrom: "keys",
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 120,
											translateY: 0,
											itemsSpacing: 2,
											itemWidth: 100,
											itemHeight: 20,
											itemDirection: "left-to-right",
											itemOpacity: 0.85,
											symbolSize: 20,
											effects: [
												{
													on: "hover",
													style: {
														itemOpacity: 1,
													},
												},
											],
										},
									]}
									role="application"
									ariaLabel="Therapy Status Distribution"
								/>
							)}
						</div>

						<p className="font-semibold mt-5 text-sm mb-3">
							Time on Therapy Distribution
							<span className="font-normal float-right">
								Show Therapy Status
								<Switch className="ml-2" onChange={handleFilters3} />
							</span>
						</p>
						<div className="flex h-60 border-2">
							{therapyStatus ? (
								<ResponsiveLine
									data={[
										{
											id: "On Therapy",
											color: "hsl(331, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, "On Therapy"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, "On Therapy"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, "On Therapy"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, "On Therapy"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, "On Therapy"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, "On Therapy"),
												},
											],
										},
										{
											id: "Discontinued",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, "Discontinued"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, "Discontinued"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, "Discontinued"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, "Discontinued"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, "Discontinued"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, "Discontinued"),
												},
											],
										},
										{
											id: "On Therapy Repeat",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, "On Therapy Repeat"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, "On Therapy Repeat"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, "On Therapy Repeat"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, "On Therapy Repeat"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, "On Therapy Repeat"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, "On Therapy Repeat"),
												},
											],
										},
										{
											id: "In Process",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, "In Process"),
												},
												{
													x: "60 days",
													y: findValueForToT(-99999, 30, "In Process"),
												},
												{
													x: "90 days",
													y: findValueForToT(-99999, 30, "In Process"),
												},
												{
													x: "180 days",
													y: findValueForToT(-99999, 30, "In Process"),
												},
												{
													x: "360 days",
													y: findValueForToT(-99999, 30, "In Process"),
												},
												{
													x: "360+ days",
													y: findValueForToT(-99999, 30, "In Process"),
												},
											],
										},
										{
											id: "Unknown",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, "Unknown"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, "Unknown"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, "Unknown"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, "Unknown"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, "Unknown"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999, "Unknown"),
												},
											],
										},
										{
											id: "Never Start",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, "Never Start"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, "Never Start"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, "Never Start"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, "Never Start"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, "Never Start"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, "Never Start"),
												},
											],
										},
									]}
									margin={{
										top: 20,
										right: 130,
										bottom: 60,
										left: 60,
									}}
									xScale={{ type: "point" }}
									yScale={{
										type: "linear",
										min: "auto",
										max: "auto",
										stacked: false,
										reverse: false,
									}}
									yFormat=" >-.2f"
									axisTop={null}
									axisRight={null}
									enableGridX={false}
									pointSize={10}
									pointColor={{ theme: "background" }}
									pointBorderWidth={2}
									pointBorderColor={{ from: "serieColor" }}
									pointLabelYOffset={-12}
									useMesh={true}
									axisLeft={{
										format: (e) => Math.floor(e) === e && e,
									}}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: <tspan className="font-semibold">Time on Therapy</tspan>,
										legendPosition: "middle",
										legendOffset: 40,
									}}
									legends={[
										{
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 100,
											translateY: 0,
											itemsSpacing: 0,
											itemDirection: "left-to-right",
											itemWidth: 80,
											itemHeight: 20,
											itemOpacity: 0.75,
											symbolSize: 12,
											symbolShape: "circle",
											symbolBorderColor: "rgba(0, 0, 0, .5)",
											effects: [
												{
													on: "hover",
													style: {
														itemBackground: "rgba(0, 0, 0, .03)",
														itemOpacity: 1,
													},
												},
											],
										},
									]}
								/>
							) : (
								<ResponsiveLine
									data={[
										{
											id: "Total",
											color: "hsl(331, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999),
												},
											],
										},
									]}
									margin={{
										top: 20,
										right: 80,
										bottom: 60,
										left: 50,
									}}
									xScale={{ type: "point" }}
									yScale={{
										type: "linear",
										min: "auto",
										max: "auto",
										stacked: false,
										reverse: false,
									}}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: <tspan className="font-semibold">Time on Therapy</tspan>,
										legendPosition: "middle",
										legendOffset: 40,
									}}
									colors="#3287C4"
									yFormat=" >-.2f"
									axisTop={null}
									// gridYValues = {[10,20,30,40,50,60,70,80]}
									axisLeft={{
										format: (e) => Math.floor(e) === e && e,
									}}
									axisRight={null}
									enableGridX={false}
									pointSize={10}
									pointColor={{ theme: "background" }}
									pointBorderWidth={2}
									pointBorderColor={{ from: "serieColor" }}
									pointLabelYOffset={-12}
									useMesh={true}
									legends={[
										{
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 100,
											translateY: 0,
											itemsSpacing: 0,
											itemDirection: "left-to-right",
											itemWidth: 80,
											itemHeight: 20,
											itemOpacity: 0.75,
											symbolSize: 12,
											symbolShape: "circle",
											symbolBorderColor: "rgba(0, 0, 0, .5)",
											effects: [
												{
													on: "hover",
													style: {
														itemBackground: "rgba(0, 0, 0, .03)",
														itemOpacity: 1,
													},
												},
											],
										},
									]}
								/>
							)}
						</div>

						<p className="font-semibold mt-5 text-sm mb-3">Location of Calls</p>
						<div className="border-2" style={{ height: "240px", overflow: "hidden" }}>
							<div style={{ height: "476px" }}>
								<ResponsiveChoropleth
									data={transcriptData.length > 0 ? transcriptData[0].statesResults : []}
									features={usFeatures.features}
									margin={{
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
									}}
									colors={["#D8ECFB", "#99D3FD", "#3B9FE7", "#2A71A4"]}
									domain={[
										0,
										Math.max.apply(
											Math,
											transcriptData[0].statesResults.map(function (o: any) {
												return o.value;
											})
										),
									]}
									unknownColor="#666666"
									label="properties.name"
									projectionScale={140}
									projectionTranslation={[0.52, 0.24]}
									projectionRotation={[0, 0, 0]}
									enableGraticule={false}
									graticuleLineColor="#dddddd"
									borderWidth={0.5}
									borderColor="#152538"
								/>
							</div>
						</div>
						<p className="mt-7 font-semibold text-sm">Key Words and Concepts</p>
						<div className="flex h-60 mt-2 border-2">
							<ResponsiveBar
								layout="horizontal"
								colors={"#3B9FE7"}
								data={temp.reverse()}
								keys={["value"]}
								indexBy="text"
								margin={{
									top: 15,
									right: 30,
									bottom: 15,
									left: 200,
								}}
								padding={0.3}
								valueScale={{ type: "linear" }}
								// indexScale={{ type: "band", round: true }}
								// defs={[
								//   {
								//     id: "dots",
								//     type: "patternDots",
								//     background: "inherit",
								//     color: "#38bcb2",
								//     size: 4,
								//     padding: 1,
								//     stagger: true,
								//   },
								//   {
								//     id: "lines",
								//     type: "patternLines",
								//     background: "inherit",
								//     color: "#eed312",
								//     rotation: -45,
								//     lineWidth: 6,
								//     spacing: 10,
								//   },
								// ]}
								borderColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								enableGridY={false}
								axisTop={null}
								axisRight={null}
								axisBottom={null}
								axisLeft={{
									// format: (v) => {
									//   return v.length > 16 ? (
									//     <tspan>
									//       {v.substring(0, 16) + "..."}
									//       <p>{v}</p>
									//     </tspan>
									//   ) : (
									//     v
									//   );
									// },
									tickSize: 0,
									tickPadding: 5,
									tickRotation: 0,
									// legend: "food",
									legendPosition: "middle",
									legendOffset: -40,
								}}
								labelSkipWidth={12}
								labelSkipHeight={20}
								labelTextColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								role="application"
								ariaLabel="Therapy Status Distribution"
							/>
						</div>
					</div>
				) : null}
			</div>
		</div>
		// </div>
	);
}
