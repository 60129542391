import { User } from "@styled-icons/remix-fill/User";
import { Grid } from "@styled-icons/ionicons-sharp/Grid";
import { QuestionCircleFill } from "@styled-icons/bootstrap/QuestionCircleFill";
import { UserTie } from "@styled-icons/fa-solid/UserTie";
import { Search } from "@styled-icons/boxicons-regular/Search";
import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight";
import { ShieldCross } from "@styled-icons/remix-line/ShieldCross";
import { Notepad } from "@styled-icons/boxicons-regular/Notepad";
import { User as User2 } from "@styled-icons/boxicons-regular/User";
import { Filter } from "@styled-icons/bootstrap/Filter";
import { UpArrow } from "@styled-icons/boxicons-solid/UpArrow";
import { DownArrow } from "@styled-icons/boxicons-solid/DownArrow";
import { ClockOutline } from "@styled-icons/evaicons-outline/ClockOutline";
import { Call } from "@styled-icons/fluentui-system-regular/Call";

import { TriangleUp } from "@styled-icons/entypo/TriangleUp";
import { TriangleDown } from "@styled-icons/entypo/TriangleDown";
import { Close } from "@styled-icons/evil/Close";
import { GraphBar } from "@styled-icons/foundation/GraphBar";
import { PlusSquare } from "@styled-icons/feather/PlusSquare";
import { MinusSquare } from "@styled-icons/feather/MinusSquare";
import { Alert } from "@styled-icons/foundation/Alert";
import { Plus } from "@styled-icons/bootstrap/Plus";
import { PlusCircleFill } from "@styled-icons/bootstrap/PlusCircleFill";
import { PinAngle } from "@styled-icons/bootstrap/PinAngle";
import { MinusCircle } from "@styled-icons/boxicons-regular/MinusCircle";

import { Icon as Published } from "./published";
import { Icon as Draft } from "./draft";
import { Icon as LassoIcon } from "./lasso-icon";
import { DeleteBin6 } from "@styled-icons/remix-fill/DeleteBin6";
import { DeleteBin6 as HollowDeleteBin6 } from "@styled-icons/remix-line/DeleteBin6";
import { SyncOutline } from "@styled-icons/evaicons-outline/SyncOutline";
import { Check } from "@styled-icons/boxicons-regular/Check";
import { Edit } from "@styled-icons/material/Edit";
import { Sort } from "@styled-icons/fa-solid/Sort";
import { SortDown } from "@styled-icons/fa-solid/SortDown";
import { SortUp } from "@styled-icons/fa-solid/SortUp";
import { Time } from "@styled-icons/boxicons-regular/Time";
import { Check2, EmojiSmile, PinFill } from "styled-icons/bootstrap";
import { FileExcel2 } from "@styled-icons/remix-line/FileExcel2";
import { ArrowGoBack } from "@styled-icons/remix-line/ArrowGoBack";
import { ArrowGoForward } from "@styled-icons/remix-line/ArrowGoForward";
import { Icon as Highlight } from "./highlight";
import { IconDisabled as HighlightDisabled } from "./highlight";
import { Icon as ClearHighlight } from "./clearHighlight";
import { Icon as Tags } from "./tags";
import { Briefcase, EditAlt, Menu, Minus } from "styled-icons/boxicons-regular";
import { KeyboardArrowDown } from "@styled-icons/material/KeyboardArrowDown";
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { Add } from "@styled-icons/fluentui-system-filled/Add";
import { Close as Close2 } from "@styled-icons/evil/Close";
import { AlertCircleOutline } from "@styled-icons/evaicons-outline/AlertCircleOutline";
import { HourglassSplit } from "@styled-icons/bootstrap/HourglassSplit";
import { Publish } from "@styled-icons/material-rounded/Publish";
import { ArrowLeftOutlined, DeleteOutlined, DoubleLeftOutlined, DoubleRightOutlined, EyeOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { Swap } from "@styled-icons/evaicons-solid/Swap";
import { ArrowDown } from "@styled-icons/evaicons-solid/ArrowDown";
import { Icon as Dashboard1 } from "./dashboard1";
import { Icon as Dashboard2 } from "./dashboard2";
import { CalendarEvent } from "@styled-icons/remix-line/CalendarEvent";
import { EmotionHappy } from "@styled-icons/remix-line/EmotionHappy";
import { EmotionNormal } from "@styled-icons/remix-line/EmotionNormal";
import { EmotionUnhappy } from "@styled-icons/remix-line/EmotionUnhappy";
import { Questionnaire } from "@styled-icons/remix-line/Questionnaire";
import { InsertChartOutlined } from "@styled-icons/material-sharp/InsertChartOutlined";
import { ThumbsUp } from "styled-icons/feather";
import { Handshake } from "@styled-icons/fluentui-system-regular/Handshake";
import { ShareAlt } from "@styled-icons/boxicons-regular/ShareAlt";
import { RoadMap } from "styled-icons/remix-line";
import { CircleWithCross } from "@styled-icons/entypo/CircleWithCross";
import { PageExportCsv } from "@styled-icons/foundation/PageExportCsv";
import { ArrowRepeat } from "@styled-icons/bootstrap/ArrowRepeat";
import { PlusCircleOutline } from "@styled-icons/evaicons-outline/PlusCircleOutline";
import { DashCircleFill } from "@styled-icons/bootstrap/DashCircleFill";
// TODO // import { CellularDataUnavailable } from "@styled-icons/fluentui-system-filled/CellularDataUnavailable";
// import { CellularDataUnavailable } from "@styled-icons/fluentui-system-filled/CellularDataUnavailable";
import { FilterAlt } from "@styled-icons/material/FilterAlt";
import { Filter as ModifiedFilter } from "@styled-icons/fa-solid/Filter";
import { CommunicationPerson } from "@styled-icons/fluentui-system-filled/CommunicationPerson";
import { SupportAgent } from "@styled-icons/material/SupportAgent";
import { PersonCall } from "@styled-icons/fluentui-system-regular/PersonCall";
import { Question } from "@styled-icons/remix-line/Question";
import { CommentDiscussion } from "@styled-icons/octicons/CommentDiscussion";
import { CardList } from "@styled-icons/bootstrap/CardList";
import { Patient } from "@styled-icons/fluentui-system-filled/Patient";
import { PieChartAlt2 } from "@styled-icons/boxicons-solid/PieChartAlt2";
import { ThumbsUpDown } from "@styled-icons/material-rounded/ThumbsUpDown";
import { BarChartHorizontal } from "@styled-icons/remix-fill/BarChartHorizontal";
import { LineChartDown } from "@styled-icons/boxicons-regular/LineChartDown";
import { LineGraph } from "@styled-icons/entypo/LineGraph";
import { SearchSettings } from "@styled-icons/fluentui-system-filled/SearchSettings";
import { Settings2Outline } from "@styled-icons/evaicons-outline/Settings2Outline";
import { Settings2 } from "@styled-icons/evaicons-solid/Settings2";
import { DocumentOnePage } from "@styled-icons/fluentui-system-regular/DocumentOnePage";
import { ExclamationTriangleFill } from "@styled-icons/bootstrap/ExclamationTriangleFill";

import { CheckCircleFill } from "@styled-icons/bootstrap/CheckCircleFill";
import { ArrowUp } from "@styled-icons/bootstrap/ArrowUp";
import { Lasso } from "@styled-icons/fluentui-system-filled/Lasso";
import { MyLocation } from "@styled-icons/material-sharp/MyLocation";

export const icons = [
	{
		id: "NavbarUser",
		icon: <User size={28} />,
	},
	{
		id: "Lasso",
		icon: <Lasso size={28} />,
	},
	{
		id: "ArrowUp",
		icon: <ArrowUp size={28} />,
	},
	{
		id: "ExclamationTriangle",
		icon: <ExclamationTriangleFill size={18} />,
	},
	{
		id: "NavbarUserSmall",
		icon: <User size={16} />,
	},
	{
		id: "PatientDetails",
		icon: <User size={16} />,
	},
	{
		id: "PatientDetailsHollow",
		icon: <User2 size={23} />,
	},
	{
		id: "NavbarGrid",
		icon: <Grid size={24} />,
	},
	{
		id: "NavbarQuestion",
		icon: <QuestionCircleFill size={24} />,
	},
	{
		id: "NavbarPulse",
		icon: <MyLocation size={24} />,
	},
	{
		id: "Dashboard1",
		icon: <Dashboard1 />,
	},
	{
		id: "Dashboard2",
		icon: <Dashboard2 />,
	},
	{
		id: "Nurse Educator Program Dashboard - Small",
		icon: <UserTie size={20} />,
	},
	{
		id: "Search Engine",
		icon: <Search size={48} />,
	},
	{
		id: "Search Engine - Small",
		icon: <Search size={20} />,
	},
	{
		id: "Create Study",
		icon: <Add size={48} />,
	},
	{
		id: "Filter",
		icon: <ModifiedFilter size={18} />,
	},
	{
		id: "Filter-Small",
		icon: <ModifiedFilter size={14} />,
	},
	{
		id: "FilterGreen",
		icon: <ModifiedFilter size={18} color="#27A6A4" />,
	},
	{
		id: "AngleRight",
		icon: <ChevronRight size={22} style={{ marginTop: "2px" }} />,
	},
	{
		id: "High Need Patients",
		icon: <ShieldCross size={40} />,
	},
	{
		id: "High Need Patients-Small",
		icon: <ShieldCross size={20} />,
	},
	{
		id: "Program Management",
		icon: <Notepad size={40} />,
	},
	{
		id: "Patient Services Program Dashboard",
		icon: <User2 size={40} />,
	},
	{
		id: "TriangleUp",
		icon: <TriangleUp size={20} />,
	},
	{
		id: "ArrowLeftOutlined",
		icon: <ArrowLeftOutlined size={24} />,
	},
	{
		id: "TriangleDown",
		icon: <TriangleDown size={20} />,
	},
	{
		id: "Close",
		icon: <Close size={16} style={{ marginBottom: "2px" }} />,
	},
	{
		id: "Report User",
		icon: <User2 size={23} />,
	},
	{ id: "Report Patient", icon: <Patient size={23} /> },
	{ id: "Report Topic", icon: <CommentDiscussion size={23} /> },
	{
		id: "Report Call Participation",
		icon: <PersonCall size={23} />,
	},
	{
		id: "Report Topic List",
		icon: <CardList size={23} />,
	},
	{
		id: "Report Thumbs",
		icon: <ThumbsUpDown size={23} />,
	},
	{
		id: "Report Line Chart",
		icon: <LineGraph size={23} />,
	},
	{
		id: "Report Bar Chart",
		icon: <BarChartHorizontal size={23} />,
	},
	{
		id: "Report Question",
		icon: <Question size={23} />,
	},
	{
		id: "Report Agent Activity",
		icon: <SupportAgent size={24} />,
	},
	{ id: "Report Pie", icon: <PieChartAlt2 size={23} /> },
	{
		id: "Up Arrow Filled",
		icon: <UpArrow size={23} color="#00AA67" />,
	},
	{
		id: "Up Arrow Filled Black",
		icon: <UpArrow size={23} />,
	},
	{
		id: "Down Arrow Filled",
		icon: <DownArrow size={23} color="#B21111" style={{ marginBottom: "0.8px" }} />,
	},
	{
		id: "Down Arrow Filled Black",
		icon: <DownArrow size={23} />,
	},
	{
		id: "Report Clock",
		icon: <ClockOutline size={23} />,
	},
	{
		id: "Report Call",
		icon: <Call size={23} />,
	},
	{
		id: "Report Query",
		icon: <Questionnaire size={24} />,
	},
	{
		id: "Report Nps",
		icon: <InsertChartOutlined size={23} />,
	},
	{
		id: "Report Engagement",
		// icon: <ShareAlt size={23} />,
		icon: <CommunicationPerson size={23} />,
	},
	{
		id: "Bar Graph",
		icon: <GraphBar size={23} />,
	},
	{
		id: "Bar Graph Green",
		icon: <GraphBar size={23} color="#27A6A4" />,
	},
	{
		id: "Accordion Expand",
		icon: <PlusSquare size={20} style={{ marginTop: "4px" }} />,
	},
	{
		id: "Accordion Collapse",
		icon: <MinusSquare size={20} style={{ marginTop: "4px" }} />,
	},
	{
		id: "Plus",
		icon: <Plus size={20} />,
	},
	{
		id: "Plus Filled Green",
		icon: <PlusCircleFill size={20} color="#27A6A4" />,
	},
	{
		id: "Pin Angle",
		icon: <PinAngle size={20} color="gray" />,
	},
	{
		id: "Pin Filled",
		icon: <PinFill size={20} color="gray" />,
	},
	{
		id: "Check 2",
		icon: <Check2 size={20} />,
	},
	{
		id: "Edit Underline",
		icon: <EditAlt size={20} color="white" style={{ marginRight: "10px" }} />,
	},
	{
		id: "PlusOutline",
		icon: <Plus size={26} />,
	},
	{
		id: "MinusOutline",
		icon: <Minus size={26} />,
	},
	{ id: "Alert", icon: <Alert size={100} /> },
	{ id: "In Draft", icon: <Draft /> },
	{ id: "LassoIcon", icon: <LassoIcon /> },
	{ id: "Published", icon: <Published /> },
	{ id: "Publishing", icon: <ArrowRepeat size={22} /> },
	{ id: "TableDelete", icon: <DeleteBin6 size={24} /> },
	{ id: "Delete", icon: <HollowDeleteBin6 size={24} /> },
	{ id: "DeleteOutlined", icon: <DeleteOutlined size={24} /> },
	{
		id: "Pending",
		icon: <SyncOutline size={20} style={{ marginLeft: "4px", marginRight: "4px" }} />,
	},
	{ id: "Complete", icon: <Check size={26} /> },
	{
		id: "Edit",
		icon: <Edit size={24} />,
	},
	{
		id: "File",
		icon: <EyeOutlined size={24} />,
	},
	{
		id: "Unsorted",
		icon: <Sort size={18} />,
	},
	{
		id: "LeftCircleFilled",
		icon: <DoubleLeftOutlined style={{ color: "black" }} />,
	},
	{
		id: "RightCircleFilled",
		icon: <DoubleRightOutlined style={{ color: "black" }} />,
	},
	{
		id: "Descending",
		icon: <SortDown size={18} />,
	},
	{
		id: "Ascending",
		icon: <SortUp size={18} />,
	},
	{ id: "Time", icon: <Time size={26} /> },
	{ id: "Excel", icon: <FileExcel2 size={24} /> },
	{
		id: "ExportCsv",
		icon: <PageExportCsv size={24} style={{ marginRight: "2px" }} />,
	},
	{ id: "ArrowForward", icon: <ArrowGoForward size={24} /> },
	{ id: "ArrowBack", icon: <ArrowGoBack size={24} /> },
	{ id: "Highlight", icon: <Highlight /> },
	{ id: "HighlightDisabled", icon: <HighlightDisabled /> },

	{ id: "ClearHighlight", icon: <ClearHighlight /> },
	{ id: "DocumentOnePage", icon: <DocumentOnePage size={20} /> },

	{ id: "Tags", icon: <Tags /> },
	{ id: "ArrowDown", icon: <KeyboardArrowDown size={20} /> },
	{ id: "ArrowRight", icon: <KeyboardArrowRight size={20} /> },
	{
		id: "Add",
		icon: (
			<Add
				size={10}
				style={{
					marginLeft: "5px",
					marginBottom: "3px",
				}}
			/>
		),
	},
	{
		id: "Remove",
		icon: (
			<Close2
				size={14}
				style={{
					marginLeft: "5px",
					marginBottom: "3px",
				}}
			/>
		),
	},
	{
		id: "AlertCircle",
		icon: <AlertCircleOutline size={40} color="#FFB60B" />,
	},
	{
		id: "MinusCircle",
		icon: <MinusCircle style={{ color: "#BA8805" }} size={20} />,
	},
	{
		id: "HourGlass",
		icon: <HourglassSplit size={20} />,
	},
	{
		id: "Publish",
		icon: <Publish size={20} />,
	},
	{
		id: "Swap",
		icon: <Swap size={15} color="#716E79" />,
	},
	{
		id: "Spinner",
		icon: <LoadingOutlined style={{ color: "#27a4a6", fontSize: 30, marginRight: "1rem" }} spin />,
	},
	{
		id: "ArrowDownFilled",
		icon: <ArrowDown size={20} />,
	},
	{ id: "Patient Journey", icon: <CalendarEvent size={24} /> },
	{ id: "EmotionHappy", icon: <EmotionHappy size={24} color={"#26C38D"} /> },
	{ id: "EmotionHappyBlack", icon: <EmotionHappy size={24} /> },
	{
		id: "EmotionNormal",
		icon: <EmotionNormal size={24} color={"#87848D"} />,
	},
	{
		id: "EmotionUnhappy",
		icon: <EmotionUnhappy size={24} color={"#E65428"} />,
	},
	{
		id: "Thumbs Up",
		icon: <ThumbsUp size={23} />,
	},
	{
		id: "Politeness Smile",
		icon: <EmojiSmile size={23} />,
	},
	{
		id: "Sympathy Shake",
		icon: <Handshake size={23} />,
	},
	{
		id: "Menu",
		icon: <Menu size={23} color="white" />,
	},
	{
		id: "Briefcase",
		icon: <Briefcase size={23} />,
	},
	{
		id: "Roadmap",
		icon: <RoadMap size={23} />,
	},
	{
		id: "FormClear",
		icon: <CircleWithCross size={16} color="#27a4a6" />,
	},
	// TODO // { id: "NoChartData", icon: <CellularDataUnavailable size={50} /> },
	// { id: "NoChartData", icon: <CellularDataUnavailable size={50} /> },
	{
		id: "Search Settings",
		icon: <Settings2Outline size={24} style={{ marginBottom: "0px" }} />,
	},
	{
		id: "Minus Circle",
		icon: <DashCircleFill size={16} style={{ fill: "#BD1515" }} />,
	},
	{
		id: "Check Circle Fill",
		icon: <CheckCircleFill size={16} style={{ fill: "#92C65B" }} />,
	},
];

export enum userTypeEnum {
	"Power",
	"Business",
	"Analyst",
}

// export const dashboardCards = {
//   [userTypeEnum[0]]: [
//     {
//       programName: "Apps",
//       cards: [
//         {
//           name: "Discover",
//           link: "/discover/",
//           type: 1,
//           image: 1,
//         },
//         {
//           name: "Search Engine",
//           link: "/search",
//           type: 1,
//           image: 2,
//         },
//         {
//           name: "Simulations",
//           link: "",
//           type: 1,
//           image: 3,
//         },
//         {
//           name: "Cognitive",
//           link: "",
//           type: 1,
//           image: 4,
//         },
//         {
//           name: "Messaging",
//           link: "",
//           type: 1,
//           image: 5,
//         },
//         {
//           name: "...",
//           link: "",
//           type: 1,
//           image: 6,
//         },
//       ],
//     },
//   ],
//   [userTypeEnum[1]]: [
//     {
//       programName: "Apps",
//       cards: [
//         {
//           name: "Search Engine",
//           link: "/search",
//           type: 1,
//           image: 1,
//         },
//         {
//           name: "Discover",
//           link: "/discover/",
//           type: 1,
//           image: 2,
//         },
//        ],
//     },
//     ],
//   [userTypeEnum[2]]: [
//     {
//       programName: "Apps",
//       cards: [
//         {
//           name: "Search Engine",
//           link: "/search",
//           type: 1,
//           image: 2,
//         },
//         {
//           name: "Discover",
//           link: "/discover/",
//           type: 1,
//           image: 2,
//         },
//        ],
//     },
//   ],
// };
export const millifyConfig = {
	precision: 1,
	lowercase: true,
	decimalSeparator: ".",
};

export enum StoryType {
	Transcripts = 1,
	Reports = 2,
}
