import { Route, Routes } from "react-router-dom";
import LandingPage from "./Components/LandingPage";

export default function InsightsDashboard() {
	return (
		<Routes>
			<Route key="HomePage" path="/" element={<LandingPage />} />
		</Routes>
	);
}
