import { Spin } from 'antd';

export const LoadingOverlay = () => (
	<div
		style={{
			position: 'absolute',
			top: 150,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1000,
		}}>
		<Spin size='large' />
	</div>
);
