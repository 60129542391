import React from "react";
import Skeleton from "antd/lib/skeleton";
import Spin from "antd/lib/spin";
import { LoadingOutlined } from "@ant-design/icons";
import { ILoadingProps } from "../../model/interface/components";

const Loading: React.FC<ILoadingProps> = ({ skeletonRows = 1, skeletonActive, spinnerText = "Loading...", spinnerSize = "large", containerClassName, spinClassName }) => {
	const loadingIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4" }} spin />;

	return (
		<div className={containerClassName}>
			<Skeleton active={skeletonActive} paragraph={{ rows: skeletonRows }} />
			<div className={`${spinClassName} text-center flex justify-center items-center`}>
				<Spin indicator={loadingIcon} size={spinnerSize} />
				<p className="ml-2">{spinnerText}</p>
			</div>
		</div>
	);
};

export default Loading;
