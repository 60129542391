// import { searchQuery } from "../Search";
import { filterValues } from "../../NodeGraph/NodeGraph";
import * as NodeGraph from "./../../../services/nodeGraph";

export const getNodeJsons: (s3path: string) => Promise<unknown> = async (s3path: string) => {
	const data = await NodeGraph.getNodeJsonsData(s3path);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getStudiesForDiscover: () => Promise<unknown> = async () => {
	const data = await NodeGraph.getStudiesDataForDiscover();
	if (data === null || data === undefined) return null;
	return data;
};

export const getWavesForStudyId: (studyId: number) => Promise<unknown> = async (studyId: number) => {
	const data = await NodeGraph.getWavesDataForStudyId(studyId);
	if (data === null || data === undefined) return null;
	return data;
};

export const getGeoData: (waveId: number) => Promise<unknown> = async (waveId: number) => {
	const data = await NodeGraph.getGeographyDataForStudyId(waveId);
	if (data === null || data === undefined) return null;
	return data;
};

export const getTranscriptDetailsForNode: (filters: { nodeId: string; studyId: number; waveId: number; geoId: number; studyIterId: number }) => Promise<unknown> = async (
	filters
) => {
	const data = await NodeGraph.getTranscriptDetailsForNodeData(filters);
	if (data === null || data === undefined) return null;
	return data;
};

export const getExperiments: () => Promise<unknown> = async () => {
	const data = await NodeGraph.getExperimentData();
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

// ENDPOINTS FOR MEDICAL ENV ONLY
export const getMedicalGeoData: (waveIds: number[]) => Promise<unknown> = async (waveIds: number[]) => {
	const data = await NodeGraph.getMedicalGeoDataForWaveId(waveIds);
	if (data === null || data === undefined) return null;
	return data;
};

export const getMedicalNodeJsons: (studyIterId: number[]) => Promise<unknown> = async (studyIterId: number[]) => {
	const data = await NodeGraph.getMedicalNodeJsonsData(studyIterId);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getMedicalTranscriptDetailsForNode: (filters: { nodeId: string; nodeName: string }) => Promise<unknown> = async (filters) => {
	const data = await NodeGraph.getMedicalTranscriptDetailsForNodeData(filters);
	if (data === null || data === undefined) return null;
	return data;
};

export const getMedicalPollingNodeSummary: (summaryId: number) => Promise<unknown> = async (summaryId: number) => {
	const data = await NodeGraph.getMedicalPollingNodeSummaryData(summaryId);
	if (data === null || data === undefined) return null;
	return data;
};

export const getTaxonomyS3DownloadUrl: (studyIterIds: number[]) => Promise<unknown> = async (studyIterIds: number[]) => {
	const data = await NodeGraph.getTaxonomyS3DownloadUrlData(studyIterIds);
	if (data == null) return null;
	return data;
};
