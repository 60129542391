export const emptyGraphObject = [
	{
		id: 1,
		cx: 63,
		cy: 22,
		fill: null,
		text: null,
		lines: [{ x: 43, y: 22 }],
		name: null,
		summary: null,
		linkId: null,
	},
	{
		id: 2,
		cx: 84,
		cy: 20,
		fill: null,
		text: null,
		lines: [{ x: 104, y: 20 }],
		name: null,
		summary: null,
		linkId: null,
	},
	{
		id: 3,
		cx: 101,
		cy: 47,
		fill: null,
		text: null,
		lines: [{ x: 121, y: 47 }],
		name: null,
		summary: null,
		linkId: null,
	},
	{
		id: 4,
		cx: 49,
		cy: 50,
		fill: null,
		text: null,
		lines: [{ x: 29, y: 50 }],
		name: null,
		summary: null,
		linkId: null,
	},
	{
		id: 5,
		cx: 45,
		cy: 36,
		fill: null,
		text: null,
		lines: [{ x: 25, y: 36 }],
		name: null,
		summary: null,
		linkId: null,
	},
	{
		id: 6,
		cx: 105,
		cy: 33,
		fill: null,
		text: null,
		lines: [{ x: 125, y: 33 }],
		name: null,
		summary: null,
		linkId: null,
	},
	{
		id: 7,
		cx: 102,
		cy: 63,
		fill: null,
		text: null,
		lines: [{ x: 123, y: 63 }],
		name: null,
		summary: null,
		linkId: null,
	},
	{
		id: 8,
		cx: 50,
		cy: 65,
		fill: null,
		text: null,
		lines: [{ x: 27, y: 65 }],
		name: null,
		summary: null,
		linkId: null,
	},
];