import List from "antd/lib/list";
import icon1 from "./icons/1.svg";
import icon2 from "./icons/2.svg";
import icon3 from "./icons/3.svg";
import icon4 from "./icons/4.svg";
import icon5 from "./icons/5.svg";
import WikiEvidenceCard from "./WikiEvidenceCard";
import CognitiveCard from "../../../../Components/CognitiveCard";
import "../../style.css";

// Map card positions to icons
const iconMap: { [key: number]: any } = {
	1: icon1,
	2: icon2,
	3: icon3,
	4: icon4,
	5: icon5,
};

function WikiPageCard(props: { loading?: boolean; data: any; topics: any }) {
	function createConfigObject(data: any) {
		return {
			title: (
				<div className="flex content-center">
					{data.card_position && iconMap[data.card_position] && <img src={iconMap[data.card_position]} />}
					<span className="ml-3">{data.main_header}</span>
				</div>
			),
			headStyle: { padding: 0 },
			className: "homepage-card m-2 pt-10",
			loading: props.loading,
			hoverable: true,
			contentStyle: "flex",
			left: [
				{ type: "text", value: { text: data.left_text } },
				{ type: "image", value: { image: data.left_image } },
			],
			right: [
				{ type: "text", value: { text: data.right_text } },
				{ type: "image", value: { image: data.right_image } },
			],
		};
	}

	return (
		<div className="m-2">
			<List
				grid={{ gutter: 16, column: 1 }}
				dataSource={props.data}
				renderItem={(item: any) => {
					const config = createConfigObject(item);
					return (
						<List.Item>
							{item.main_header === "Evidence" ? (
								<WikiEvidenceCard data={item} icon={iconMap} loading={props.loading} topics={props.topics} />
							) : (
								<CognitiveCard config={config} />
							)}
						</List.Item>
					);
				}}
			/>
		</div>
	);
}

export default WikiPageCard;
