import * as CognitiveService from "./../../../services/cognitive";

export const GetAllCognitiveFactor: (studyIterId: number) => Promise<unknown> = async (studyIterId) => {
	const data = await CognitiveService.GetAllCognitiveFactor(studyIterId);
	if (data == null) return null;
	return data;
};

export const GetCognitiveFactorsDetails: (cogFactorId: number, studyIterId: number) => Promise<unknown> = async (cogFactorId, studyIterId) => {
	const data = await CognitiveService.GetCognitiveFactorsDetails(cogFactorId, studyIterId);
	if (data == null) return null;
	return data;
};

export const GetReferencesForEvidence: (cogFactorId: number, studyIterId: number, evidenceName: string) => Promise<unknown> = async (cogFactorId, studyIterId, evidenceName) => {
	const data = await CognitiveService.GetReferencesForEvidence(cogFactorId, studyIterId, evidenceName);
	if (data == null) return null;
	return data;
};
