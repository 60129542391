import { Alert, Button, DatePicker, Input, Modal, Radio, Select, Switch, Tooltip, message } from "antd";
import { CloseOutlined, CheckOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { icons } from "../../GlobalThemes";
import moment from "moment";
import AppContext from "../../store";
import { searchQuery } from "../../Pages/SearchEngine/Search";
import { getSearchEmployee } from "../../services/search";
import { selectStore, setSearchSummaryFilterInSync, setSearchFilters } from "../../state/slice/appSlice";
import { StudyTreeDropdown } from "./Components/StudyTreeDropdown";
import { SharedMessages, SearchModuleMessages, StudyModuleMessages } from "../../constants/messages";
import { InfoTooltip } from "../InfoTooltip";
// import { getAllTranscripts } from "../../Pages/SearchEngine/ServiceHelpers/search";
// import type { CheckboxChangeEvent } from "antd/es/checkbox";

export interface ISearchFilterValues {
	startDate?: string;
	endDate?: string;
	studyIds?: number | string[];
	speaker?: string;
	searchWord?: string | undefined;
	metaDataIds?: string[];
	pptSearch?: boolean;
	userProfile?: string;
	deepSearch?: boolean;
	testButton1?: boolean;
	testButton2?: boolean;
	testButton3?: boolean;
	testButton4?: boolean;
	testButton5?: boolean;
}

export interface IAdvanceSearch {
	deepSearch: boolean;
	testButton1: boolean;
	testButton2: boolean;
	testButton4: boolean;
	testButton5: boolean;
}

/**
 * Renders the search filters at the top of search page, which includes speaker type, time period and search bar.
 *
 * @remarks - This function is the part of search tab and will be rendered at the top of the page
 *
 * @param showDrawer - The props used to show and hide the drawer for advanced filters
 * @param fetchTranscripts - This function is  used to fetch the transcripts based on the added inputs
 * @param searchWordRequired - This props check search word is present or not
 *
 * @returns the filters at search tab
 */

export function SearchFilters(props: {
	showDrawer: () => void;
	fetchTranscripts: (queries: searchQuery) => void;
	searchWordRequired: boolean;
	searchString?: string;
	setSearchString?: any;
	fetchedFilters?: any;
	startDate?: any;
	endDate?: any;
	isSearchStreamClosed: boolean;
}) {
	const { RangePicker } = DatePicker;
	const { searchWord } = useParams();
	const { Option, OptGroup } = Select;
	const context = useContext(AppContext);
	const store = useSelector(selectStore);
	const dispatch = useDispatch();
	var filterData: ISearchFilterValues = {
		// var filterData = {
		startDate: "",
		endDate: "",
		studyIds: [],
		speaker: "",
		searchWord: "",
		metaDataIds: [],
		pptSearch: false,
		userProfile: "",
	};
	var oldDateFormat = "MMM YYYY";
	var dateFormat = "DD/MM/YYYY";
	const userProfileName = context.userProfile ? context.userProfile.displayName ? context.userProfile.displayName.toLowerCase() : "default" : "default";

	const [searchFilterData, setSearchFilterData] = useState<searchQuery>(filterData);
	const [filtersFetch, setFiltersFetch] = useState<any>(props.fetchedFilters);
	const [dateRanges, setDateRanges] = React.useState<Array<any>>([]);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [searchedItems, setSearchedItems] = useState<any[]>([]);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [metaDataDropdownData, setMetaDataDropdownData] = useState<any[]>([]);
	const [advanceSearch, setAdvanceSearch] = React.useState<IAdvanceSearch>({
		deepSearch: false,
		testButton1: false,
		testButton2: false,
		testButton4: false,
		testButton5: false,
	});
	const [isSearchDisabled, setIsSearchDisabled] = React.useState<boolean>(false);
	const [showKeywordError, setShowKeywordError] = React.useState<boolean>(false);

	useEffect(() => {
		store.searchTab.searchFilters && setAdvanceSearch({ ...advanceSearch, ...store.searchTab.searchFilters });
		store.searchTab.searchFilters && setSearchFilterData({ ...searchFilterData, ...store.searchTab.searchFilters });
	}, []);

	const handleFilters = (filterType: string, filterValue: string | boolean | string[] | number, filterInSyncWithSummary: boolean = true) => {
		// Add regex check for special characters to filterValue
		const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
		if (typeof filterValue === "string" && specialCharsRegex.test(filterValue)) {
			setErrorMessage(context.envId && SharedMessages[context.envId].SPECIAL_CHAR_VALIDATION_ERROR);
			return;
		} else {
			setErrorMessage(undefined);
		}

		if (filterType === "searchWord") {
			setShowKeywordError(filterValue ? false : true);
			setErrorMessage(filterValue ? undefined : context.envId && SharedMessages[context.envId].REQUIRED_FIELD_VALIDATION_ERROR);
		}

		// Update advanceFilters
		const updatedAdvanceFilters = { ...advanceSearch, [filterType]: filterValue };

		// Update searchFilterData
		let updatedFilters = { ...searchFilterData };
		if (filterType === "dateRange") {
			//@ts-ignore
			updatedFilters = { ...updatedFilters, startDate: filterValue[0], endDate: filterValue[1] };
		} else {
			updatedFilters = { ...updatedFilters, [filterType]: filterValue };
		}

		let combinedFilters = { ...store.searchTab.searchFilters, [filterType]: filterValue };

		if (filterType === "pptSearch") {
			updatedFilters.studyIds = [];
			updatedFilters.metaDataIds = [];

			combinedFilters.studyIds = [];
			combinedFilters.metaDataIds = [];
		}

		// Check for the environment and set the pptSearch flag accordingly
		if (context.envId === 2) {
			if (filterType === "studyIds") {
				const selectedStudies = props.fetchedFilters[0]?.optionsObject?.filter((filter: any) => (filterValue as string[]).includes(filter.id));

				const studyTypes = new Set(selectedStudies?.map((study: any) => study.studyTypeId));

				if (studyTypes.size === 1) {
					const studyTypeId = studyTypes.values().next().value;
					updatedFilters = { ...updatedFilters, pptSearch: studyTypeId === 4 };
					combinedFilters = { ...combinedFilters, pptSearch: studyTypeId === 4 };
				} else if (studyTypes.size > 1) {
					message.warning(context.envId && SearchModuleMessages[context.envId].STUDY_TYPE_MISMATCH_VALIDATION_ERROR);
					return;
				}
			}
		}

		setAdvanceSearch(updatedAdvanceFilters);
		setSearchFilterData(updatedFilters);
		dispatch(setSearchFilters(combinedFilters));
		!filterInSyncWithSummary &&
			store.searchTab.searchStreamedData !== "Loading data..." &&
			store.searchTab.searchRefData.length > 0 &&
			dispatch(setSearchSummaryFilterInSync(false));
	};

	const disableSelectedDates = (current: any) => {
		if (!searchedItems || searchedItems.length === 0) {
			// Handle the case where searchedItems is undefined or empty
			return false; // Or whatever default behavior you need
		}

		// Now you can safely access properties of searchedItems
		return current < moment(searchedItems[0].startDate, oldDateFormat) || current > moment(searchedItems[0].endDate, oldDateFormat).endOf("month");
	};

	async function fetchSearchMetaData() {
		var data;
		// if (searchFilterData["studyIds"] != undefined) {
		// 	data = await getSearchEmployee(searchFilterData["studyIds"]);
		// } else {
		// 	data = await getSearchEmployee(-1);
		// }

		let studyIds = searchFilterData["studyIds"];

		if (studyIds !== undefined && studyIds !== null) {
			if (Array.isArray(studyIds)) {
				// If studyIds is an array and it's not empty
				if (studyIds.length > 0) {
					let studyIdsString = studyIds.join("|");
					data = await getSearchEmployee(studyIdsString);
				} else {
					// Handle the case when studyIds array is empty
					data = await getSearchEmployee(-1);
				}
			} else {
				// If studyIds is not an array but a single number
				data = await getSearchEmployee(studyIds);
			}
		} else {
			// If studyIds is either undefined or null
			data = await getSearchEmployee(-1);
		}

		if (data) {
			setSearchedItems(data);
			// setOptionStatus(true);
		}
	}

	useEffect(() => {
		const groupMap = new Map();
		const transformedFilterGroups = [];

		for (const item of searchedItems) {
			const [category, subcategory] = item.name.split(":");
			if (category && subcategory) {
				let group = groupMap.get(category.trim());
				if (!group) {
					group = {
						key: item.id,
						label: category.trim(),
						children: [],
					};
					groupMap.set(category.trim(), group);
					transformedFilterGroups.push(group);
				}
				group.children.push({
					key: item.id,
					value: item.id,
					label: subcategory.trim(),
					...item,
				});
			}
		}

		setMetaDataDropdownData(transformedFilterGroups);
	}, [searchedItems]);

	useEffect(() => {
		// console.log("Filetrs:" + props.fetchedFilters);
		setFiltersFetch(props.fetchedFilters);
	}, [props.fetchedFilters]);

	useEffect(() => {
		fetchSearchMetaData(); // Call fetchSearchMetaData whenever searchFilterData changes
	}, [searchFilterData.studyIds]);

	useEffect(() => {
		if (searchWord != "" && searchWord != null) {
			//console.log("URL Search Word", searchWord); // Commented out console.log
			handleFilters("searchWord", searchWord || "");
			onClickSearch();
		}
	}, [searchWord]);

	function ModalContent(props: {
		advanceSearch: IAdvanceSearch;
		setAdvanceSearch: React.Dispatch<React.SetStateAction<IAdvanceSearch>>;
		handleFilters: (filterType: string, filterValue: string | boolean | string[] | number, filterInSyncWithSummary: boolean) => void;
	}) {
		return (
			<>
				<div className="text-2xl text-text-gray-100 font-normal my-4">Search Settings</div>
				<div className="text-base text-text-gray-50 my-6">Please select the search settings.</div>
				<div className="w-full ">
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">
							Enable Deep Search
							<InfoTooltip
								content={context.envId && SearchModuleMessages[context.envId].ENABLE_DEEP_SEARCH_INFO_TOOLTIP}
								overlayWidth={450}
								position="bottom"
								translateX={65}
							/>
						</div>

						<Switch
							onChange={(checked) => {
								// setDeepSearch(checked);
								props.handleFilters("deepSearch", checked, false);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							checked={props.advanceSearch.deepSearch}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Long Summary</div>

						<Switch
							onChange={(checked) => {
								// setTestButton1(checked);
								props.handleFilters("testButton1", checked, false);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							checked={props.advanceSearch.testButton1}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Use GPT-4</div>

						<Switch
							onChange={(checked) => {
								// setTestButton2(checked);
								props.handleFilters("testButton2", checked, false);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							checked={props.advanceSearch.testButton2}
						/>
					</div>

					{context.envId === 1 && (
						<div className="flex align-middle justify-between px-8 py-2">
							<div className="font-base text-text-gray-90 font-semibold">Report Research</div>

							<Switch
								onChange={(checked) => {
									// setTestButton4(checked);
									props.handleFilters("testButton4", checked, false);
								}}
								checkedChildren={
									<div
										style={{
											position: "absolute",
											top: -2,
										}}
									>
										<CheckOutlined />
									</div>
								}
								unCheckedChildren={
									<div
										style={{
											position: "absolute",
											top: -2,
										}}
									>
										<CloseOutlined />
									</div>
								}
								checked={props.advanceSearch.testButton4}
							/>
						</div>
					)}
				</div>
			</>
		);
	}

	const onClickSearch = () => {
		setShowKeywordError(searchFilterData.searchWord ? false : true);
		setErrorMessage(searchFilterData.searchWord ? undefined : context.envId && SharedMessages[context.envId].REQUIRED_FIELD_VALIDATION_ERROR);

		props.fetchTranscripts(searchFilterData);
		props.setSearchString(searchFilterData.searchWord);
	};

	return (
		<div>
			<Modal
				// title="Search Settings"
				open={modalVisible}
				onOk={() => setModalVisible(false)}
				onCancel={() => setModalVisible(false)}
				footer={null}
				bodyStyle={{ paddingBottom: "30px" }}
			>
				<ModalContent advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} handleFilters={handleFilters} />
			</Modal>
			<div className="flex justify-between items-center pb-4">
				{context.envId === 1 ? (
					<>
						{/* TREE STUDY DROPDOWN */}
						<div className="flex items-center mr-3 w-6/12">
							<span className="mr-6">Datasets:</span>
							<StudyTreeDropdown data={props.fetchedFilters[0]?.treeOptionsObject} handleFilters={handleFilters} searchIn={searchFilterData.pptSearch || false} />
						</div>
					</>
				) : (
					<>
						{/* STUDY DROPDOWN */}
						<div className="flex items-center mr-3 w-3/12">
							{context.envId === 3 ? (
								<>
									<span className="mr-2">Speaker Type:</span>
									<Select
										clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
										className="w-96"
										placeholder="Select Speaker Type"
										allowClear
										onChange={(value: string) => {
											handleFilters("speaker", value, false);
										}}
									>
										<Select.Option value="Agent">Agent</Select.Option>
										<Select.Option value="Patient">Patient</Select.Option>
									</Select>
								</>
							) : (
								// All envIds except 1 and 3
								<>
									<span className="mr-2">Study Name:</span>
									<Select
										clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
										className="w-80"
										placeholder="Select Study Name"
										// @ts-ignore
										value={store.searchTab.searchFilters ? store.searchTab.searchFilters.studyIds : searchFilterData.studyIds}
										allowClear
										mode="multiple"
										onChange={(value: string[]) => {
											handleFilters("studyIds", value, false);
										}}
									>
										{props.fetchedFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["id"]}>
												{filter["name"]}
											</Select.Option>
										))}
									</Select>
								</>
							)}
						</div>

						{/* TIME PERIOD DROPDOWN */}
						<div className="flex items-center mr-3 w-3/12">
							<span className="mr-2">Time Period:</span>
							{context.envId === 3 ? (
								<RangePicker
									className="w-80"
									format={dateFormat}
									defaultPickerValue={[moment(context.endDate, "DD MMM YYYY").subtract(1, "months"), moment(context.endDate, "DD MMM YYYY")]}
									disabledDate={disableSelectedDates}
									onChange={(e: any) => {
										if (e === null) {
											// handleFilters("startDate", "");
											// handleFilters("endDate", "");
											handleFilters("dateRange", ["", ""], false);
										} else {
											// handleFilters("startDate", moment(e[0]).format("DD MMM YYYY"));
											// handleFilters("endDate", moment(e[1]).format("DD MMM YYYY"));
											handleFilters("dateRange", [moment(e[0]).format("DD MMM YYYY"), moment(e[1]).format("DD MMM YYYY")], false);
										}
									}}
									allowClear
								/>
							) : (
								<RangePicker
									className="w-80"
									clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
									disabledDate={disableSelectedDates}
									defaultPickerValue={[moment(context.startDate, "DD MMM YYYY"), moment(context.endDate, "DD MMM YYYY")]}
									// defaultPickerValue={
									// 	store.searchTab.searchFilters
									// 		? store.searchTab.searchFilters.startDate !== "" && store.searchTab.searchFilters.endDate !== ""
									// 			? [moment(store.searchTab.searchFilters.startDate, "DD MMM YYYY"), moment(store.searchTab.searchFilters.endDate, "DD MMM YYYY")]
									// 			: [moment(context.startDate, "DD MMM YYYY"), moment(context.endDate, "DD MMM YYYY")]
									// 		: undefined
									// }
									format={oldDateFormat}
									separator={icons.find((l) => l.id === "Swap")?.icon}
									onChange={(e: any) => {
										if (e === null) {
											// handleFilters("startDate", "");
											// handleFilters("endDate", "");
											handleFilters("dateRange", ["", ""], false);
										} else {
											// handleFilters("startDate", moment(e[0]).format("MMM YYYY"));
											// handleFilters("endDate", moment(e[1]).format("MMM YYYY"));
											handleFilters("dateRange", [moment(e[0]).format("DD MMM YYYY"), moment(e[1]).format("DD MMM YYYY")], false);
										}
									}}
									onCalendarChange={(value: any) => {
										setDateRanges(value);
									}}
									picker="month"
								/>
							)}
						</div>
					</>
				)}

				<div className="flex items-center mr-3 w-4/12">
					<span className="mr-2">Filters:</span>
					<Select
						clearIcon={icons.find((icon) => icon.id === "FormClear")?.icon}
						className="w-96"
						style={{ width: "31rem" }}
						placeholder="Select Filters"
						value={store.searchTab.searchFilters ? store.searchTab.searchFilters.metaDataIds : searchFilterData.metaDataIds}
						allowClear
						mode="multiple"
						maxTagTextLength={15}
						maxTagCount={"responsive"}
						onChange={(selectedItems: string[]) => handleFilters("metaDataIds", selectedItems, false)}
						optionFilterProp="children"
					>
						{metaDataDropdownData
							.sort((a, b) => a.label.localeCompare(b.label))
							.map((group: any) => (
								// <OptGroup key={group.key} label={context.envId === 2 && group.label === "wave" ? "Time Frame" : group.label}>
								<OptGroup key={group.key} label={context.envId === 2 && group.label === "wave" ? `${context.envId && StudyModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].COL1_TEXT}` : group.label}>
									{group.children
										.sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label))
										.map((item: any) => (
											<Option key={item.key} value={item.value}>
												{item.label}
											</Option>
										))}
								</OptGroup>
							))}
					</Select>
				</div>
				<div className="flex items-center mr-3 w-1/6 searchRadio">
					{/* TEST CHANGES - REMOVING CONDITIONAL RENDERING - REMOVE THIS COMMENT LATER - AGRIM */}
					{context.envId !== 3 && (
						<>
							<span className="mr-2">
								Search In <InfoTooltip content={context.envId && SearchModuleMessages[context.envId].SEARCH_IN_INFO_TOOLTIP} overlayWidth={450} />:
							</span>
							<Radio.Group
								defaultValue={store.searchTab.searchFilters.pptSearch ? store.searchTab.searchFilters.pptSearch : searchFilterData.pptSearch}
								value={searchFilterData.pptSearch}
								buttonStyle="solid"
								onChange={(e) => handleFilters("pptSearch", e.target.value, false)}
							>
								<Radio.Button value={false}>{context.envId !== 2 ? "Transcripts" : "Notes"}</Radio.Button>
								<Radio.Button value={true}>Reports</Radio.Button>
							</Radio.Group>
						</>
					)}
				</div>
				{/* <div>
						<div
							className="flex items-center cursor-pointer w-44 ml-4"
							onClick={() => {
							props.showDrawer();
							}}
						>
							<div className="mr-2">{icons.find((l) => l.id === "FilterGreen")?.icon}</div>
							<div style={{ color: "#27A6A4" }} className="flex text-base">
							Advanced Filters
							</div>
						</div>
				</div> */}
			</div>
			<div className="flex items-center">
				Search Query:
				<div className="flex ml-1 items-center w-5/6">
					<Input
						className="flex input-search"
						placeholder={context.envId && SharedMessages[context.envId].QUERY_PLACEHOLDER}
						onChange={(e) => {
							handleFilters("searchWord", e.target.value, false);
						}}
						onKeyDown={(e: any) => {
							if (e.key === "Enter") {
								props.fetchTranscripts(searchFilterData);
								props.setSearchString(searchFilterData.searchWord);
							}
						}}
						defaultValue={
							searchWord === "" || searchWord == null
								? store.searchTab.searchFilters && store.searchTab.searchFilters.searchWord !== ""
									? store.searchTab.searchFilters.searchWord
									: undefined
								: searchWord
						}
						status={showKeywordError ? "error" : undefined}
					/>
					{/* {errorMessage && (
						<div className="text-red-500 text-sm mt-2">
							{errorMessage}
						</div>
					)} */}
				</div>
				<div className="flex ml-1 items-center w-1/6">
					<div className="flex items-center ml-5">
						<Tooltip title={props.isSearchStreamClosed ? "" : context.envId && SearchModuleMessages[context.envId].SEARCH_STREAMING_IN_PROGRESS}>
							<Button
								type="primary"
								className="green-button flex items-center"
								disabled={!props.isSearchStreamClosed || isSearchDisabled}
								onClick={() => onClickSearch()}
							>
								Search
							</Button>
						</Tooltip>
					</div>
					<div
						title="Search Settings"
						className="flex align-middle mt-1 ml-5 text-text-gray-60 cursor-pointer"
						onClick={() => {
							setModalVisible(true);
						}}
					>
						{icons.find((l) => l.id === "Search Settings")?.icon}
					</div>
				</div>
			</div>
			{errorMessage ? <Alert className="mt-4" message={errorMessage} type="error" showIcon closable /> : null}
		</div>
	);
}
