// MarkdownModal.tsx

import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";
import { TranscriptModalData } from "../SearchEngineCards/TranscriptModal";

interface MarkdownModalProps {
	isModalOpen: boolean;
	handleModalClose: () => void;
	transcriptS3Path: string | undefined;
	highlightedDialoguesId: number[] | undefined;
}

interface IS3TranscriptData {
	transcriptData: any;
	presignedURL: string;
	format: string;
}

export const MarkdownModal: FC<MarkdownModalProps> = ({ isModalOpen, handleModalClose, transcriptS3Path, highlightedDialoguesId }) => {
	const [markdownModalData, setMarkdownModalData] = useState<IS3TranscriptData | undefined>(undefined);

	useEffect(() => {
		setMarkdownModalData(undefined);
		isModalOpen && transcriptS3Path && handleClickGetFullTrans();
	}, [isModalOpen]);

	const handleClickGetFullTrans = async () => {
		const transData: any = await SearchServiceHelper.getFullTranscript(transcriptS3Path ? transcriptS3Path : "");
		transData && setMarkdownModalData(transData);
	};

	return (
		<Modal
			title={"Transcript Media Renderer"}
			width={1500}
			bodyStyle={{
				height: "600px",
				overflowY: "auto",
			}}
			centered
			open={isModalOpen}
			onCancel={handleModalClose}
			className="modal-footer-left text-left"
			footer={[
				<Button type="primary" className="green-button items-start mr-6" onClick={handleModalClose}>
					Close
				</Button>,
			]}
		>
			{markdownModalData ? <TranscriptModalData transPayloadData={markdownModalData} highlightedDialogueNo={highlightedDialoguesId} /> : <p>Loading...</p>}
		</Modal>
	);
};
