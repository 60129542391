import { Link } from "react-router-dom";
import { icons } from "../../GlobalThemes";
import SearchEngineImg from "./SearchEngine.jpg";
import "./index.css";
import React from "react";
import AppContext from "../../store";
/**
 * This function is used to render the cards on app landing page
 *
 * @remarks - This function is used inside LandingBody function to render the cards in a loop
 *
 * @param name - This props is used to print the name of the card
 * @param comingSoon - This props shows whether card is launched or coming soon
 * @param link - This props is used to redirect to the link when card is clicked
 * @param type - This props is used to render the type of icon
 *
 * @returns the single card with card name and route link
 */

export default function LandingCards(props: { name: string; comingSoon?: boolean; link?: string; type?: number; setIsOpen?: any; image: number }) {
	const context = React.useContext(AppContext);
	return (
		<div
			className="container sm:w-1/2 md:w-1/3 xl:w-1/3 max-w-sm"
			onClick={props.type === 3 ? () => props.setIsOpen && props.setIsOpen(true) : () => props.setIsOpen && props.setIsOpen(false)}
		>
			<Link to={props.link ? props.link : ""}>
				<div
					className={`mx-2 my-2 h-48 shadow-lg shadow-gray-900 hover:shadow-xl hover:bg-gray-50 transition scaling-class cursor-pointer
			${
				props.image === 1
					? "bkdImgSearch"
					: props.image === 2
					? "bkdImgDiscover"
					: props.image === 3
					? "bkdImgApp3"
					: props.image === 4
					? "bkdImgApp4"
					: props.image === 5
					? "bkdImgApp5"
					: props.image === 6
					? "bkdImgApp6"
					: ""
			}`}
					style={props.link !== "" ? { opacity: 1 } : {}}
				>
					{/* Card Background Color */}
					{/* <uldiv
					// className={`flex justify-center items-center relative ${
					// 	props.type === 1 || props.type == undefined
					// 		? "bg-landingCard-teal text-text-teal"
					// 		: "bg-landingCard-orange text-text-orange"
					// } h-1/2 w-full`}
					className="bg-banner text-text-gray bg-cover bg-right"
					style={{backgroundImage:`url(${SearchEngineImg})`}}
				> */}
					{/* Card Icon */}
					{/* {props.name === "Dashboard"
						? icons.find(
								(l) => l.id === `${props.name}${props.type}`
						  )?.icon
						// : props.type === 3 ? <div className="bg-white rounded-full absolute -bottom-10 h-20 w-20 flex items-center justify-center"> {icons.find((l) => l.id === `${props.name}`)?.icon} </div>
						: icons.find((l) => l.id === `${props.name}`)?.icon} */}
					{/* </div> */}

					<div className="flex flex-col justify-between h-1/2 w-full">
						<div className="h-10 px-4 py-2 " style={{ marginTop: "155px", paddingBottom: "40px", backgroundColor: "rgba(0, 0, 0, 0.55)" }}>
							{/* Card Name (or) Coming Soon */}
							<p className="font-semibold text-lg text-text-white">
								{props.type === 3 ? "" : props.name === "Pulse" ? <>{icons.find((l) => l.id === "NavbarPulse")?.icon} Pulse</> : props.name}{" "}
							</p>
							{/* {props.comingSoon && (
							<p className="text-text-50">Coming Soon...</p>
						)} */}
						</div>
						{props.link && (
							// Action Button
							<div className=" flex justify-end mx-4 py-2 text-base font-medium ">
								<Link to={props.link}>
									<div
										className={`flex cursor-pointer ${
											props.type === 1 || props.type === undefined ? "text-text-teal hover:text-text-orange" : "text-text-orange hover:text-text-teal"
										}`}
										style={{ marginTop: "-45px" }}
									>
										{props.type === 3 ? <p>Create Study</p> : <p></p>}
										{icons.find((l) => l.id === "AngleRight")?.icon}
									</div>
								</Link>
							</div>
						)}
					</div>
				</div>
			</Link>
		</div>
	);
}
