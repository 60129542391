import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import horizontalBarChartConfig from "../chartConfigs/horizontalBarChartConfig";
import verticalBarChartConfig from "../chartConfigs/verticalBarChartConfig";

interface IBarChartProps {
	data: Array<{ id?: number; label?: string; value: number; date?: string }>;
	chartType?: "horizontal" | "vertical";
	colors?: string[];
	title?: string;
	handleClick: (inputData: Record<string, any>, inputType: string) => void;
}

export default function BarChart({ data, chartType = "vertical", colors, title = "", handleClick }: IBarChartProps) {
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	useEffect(() => {
		if (chartComponentRef.current) {
			const chart = chartComponentRef.current.chart;
			if (chart) {
				chart.series[0].data.forEach((point) => {
					point.update({
						events: {
							click: () => {
								// @ts-ignore
								handleClick({ id: point.id, label: point.category, value: point.y }, title + "BarChart");
								console.log("Bar Chart Column Clicked:", point);
							},
						},
					});
				});
			}
		}
	}, []);

	if (!data || data.length === 0) {
		return null;
	}

	let chartConfig;

	switch (chartType) {
		case "horizontal":
			chartConfig = horizontalBarChartConfig;
			break;
		case "vertical":
		default:
			chartConfig = verticalBarChartConfig;
			break;
	}

	const options = {
		...chartConfig,
		xAxis: {
			categories: data.map((item) => item.label),
		},
		series: [
			{
				data: data.map((item, index) => ({
					y: item.value,
					color: colors && colors[index],
				})),
			},
		],
		plotOptions: {
			series: {
				colorByPoint: true,
			},
		},
	};

	return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
}
