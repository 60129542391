import { request } from "./../App";
import { searchQuery, transcriptDataInter } from "./../Pages/SearchEngine/Search";
export const getAllTranscripts = async (
	searchword?: string,
	startdate?: string,
	enddate?: string,
	speaker?: string,
	showResults?: boolean,
	storyId?: string,
	callType?: string,
	therapyStatus?: string,
	emotion?: string
) => {
	let str = "";
	if (searchword) str += "searchword=" + searchword;
	if (startdate) str === "" ? (str += "startdate=" + startdate) : (str += "&startdate=" + startdate);
	if (enddate) str === "" ? (str += "enddate=" + enddate) : (str += "&enddate=" + enddate);
	if (speaker && speaker !== "") str === "" ? (str += "speaker=" + speaker) : (str += "&speaker=" + speaker);
	if (showResults) str === "" ? (str += "showResults=" + showResults) : (str += "&showResults=" + showResults);
	if (storyId) str === "" ? (str += "storyId=" + storyId) : (str += "&storyId=" + storyId);
	if (callType) str === "" ? (str += "callType=" + callType) : (str += "&callType=" + callType);
	if (therapyStatus) str === "" ? (str += "therapyStatus=" + therapyStatus) : (str += "&therapyStatus=" + therapyStatus);
	if (emotion) str === "" ? (str += "emotion=" + emotion) : (str += "&emotion=" + emotion);

	const fetchUrl = `search/searchresults?${str}`;
	const data = await request
		.get(fetchUrl, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
export const getAllTranscripts2 = async (searchQueries: searchQuery) => {
	// let str = "";
	//  console.log("Queries", searchQueries);
	const obj: any = {};
	Object.entries(searchQueries).map(([key, value]) => {
		//    console.log("Key, Val", key, value);
		if ((Array.isArray(value) && value.length > 0) || typeof value === "boolean") obj[key] = value;
		else if (!Array.isArray(value) && value) obj[key] = value;

		if (key === "studyIds") {
			obj[key] = `${value}`;
		}
	});
	const fetchUrl = `search/searchresults`;
	const data = await request
		.post(fetchUrl, obj, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
export const getFullTranscript = async (s3path: string) => {
	const fetchUrl = "search/getfulltranscript";
	const data = await request
		.post(fetchUrl, s3path, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getS3ImageByteArrayData = async (s3path: string) => {
	const fetchUrl = "search/GetFullTranscriptforImage";
	const data = await request
		.post(fetchUrl, s3path, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const addToStory = async (story: { searchQuery: string; summaryJson: transcriptDataInter; storyIds: number[] }) => {
	const fetchUrl = "search/AddToStory";
	const data = await request
		.post(fetchUrl, story, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const fetchCookie = async () => {
	const fetchUrl = "Search/GetCookie";
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getFilters = async (type?: string) => {
	const fetchUrl = "Search/GetSearchAdvancedFilters";
	const data = await request
		.get(fetchUrl, {
			params: {
				type: type,
			},
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getNodeTranscripts = async (id: string) => {
	const fetchUrl = `search/searchresults`;
	const data = await request
		.post(
			fetchUrl,
			{ searchWord: "hi" },
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getSearchEmployee = async (studyId: number | any) => {
	const fetchUrl = `search/GetMetaDataFilters?studyId=${studyId}`;
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			return null;
		});
	return data;
};

export const saveSearchFeedbackData = async (summary: { searchQuery: string; isLike: boolean; searchJson: transcriptDataInter; userFeedback: string | undefined }) => {
	const fetchUrl = "search/SaveSearchQueryFeedback";
	const data = await request
		.post(fetchUrl, summary, { withCredentials: true })
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			return null;
		});
	return data;
};
