import React, { useState } from "react";
import Sider from "antd/lib/layout/Sider";
import Divider from "antd/lib/divider";
import Menu from "antd/lib/menu";
import Button from "antd/lib/button";
import { Link } from "react-router-dom";
import { icons } from "../../GlobalThemes";
import { ISideMenuProps } from "../../model/interface/components";
import "./style.css";

const SideMenu: React.FC<ISideMenuProps> = ({ menuItems, collapsed, onCollapse, siderWidth, collapsedWidth = 0, headerText, pageScroll, handleCardHighlight }) => {
	const scrollToCardWithId = (id: any) => {
		const cardElement = document.getElementById(`card-${id}`);
		if (cardElement) {
			cardElement.scrollIntoView({ behavior: "smooth" });
		}
	};

	const renderMenuItems = () => {
		return menuItems.map((item) => (
			<Menu.Item
				key={item.id}
				onClick={() => {
					if (pageScroll) {
						scrollToCardWithId(item.id);
						handleCardHighlight(item.id);
					}
				}}
			>
				{item.link ? <Link to={item.link}>{item.name}</Link> : item.name}
			</Menu.Item>
		));
	};

	const selectIcon = (collapsed: boolean) => {
		return collapsed ? icons.find((f) => f.id === "RightCircleFilled")?.icon : icons.find((f) => f.id === "LeftCircleFilled")?.icon;
	};

	return (
		<Sider width={siderWidth} className="site-layout-background" collapsedWidth={collapsedWidth} collapsed={collapsed}>
			<div className="text-lg font-bold text-center my-5">{headerText}</div>
			<Divider />
			<Menu mode="inline" className="menu-with-divider font-medium">
				{renderMenuItems()}
			</Menu>
			<Button className={`green-button ${collapsed ? "collapsed-btn" : "expanded-btn"}`} onClick={onCollapse}>
				{selectIcon(collapsed)}
			</Button>
		</Sider>
	);
};

export default SideMenu;
