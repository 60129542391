import React from "react";
import Pages from "./Pages";
import AppContext from "./store";
import { userTypeEnum } from "./GlobalThemes";
/**
 * Defines the different routes to the pages
 *
 * @returns Routes
 */
export function Routes() {
	const context = React.useContext(AppContext);
	let routePaths: {
		path: string;
		element: JSX.Element;
	}[] = [];
	// console.log("UserType", context.userType)

	if (context.userType === userTypeEnum[0]) {
		// console.log("POWERRRRRR");
		if (context.envId === 1) {
			routePaths = [
				{
					path: "/",
					element: <Pages.LandingPage />,
				},
				{
					path: "/search",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/search/:searchWord",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/study/*",
					element: <Pages.StudyModule />,
				},
				{
					path: "/discover",
					element: <Pages.DiscoverModule />,
				},
				{
					path: "/collection/*",
					element: <Pages.StoriesPage />,
				},
				{
					path: "/stories/*",
					element: <Pages.OldStoriesPage />,
				},
				{
					path: "/cognitive/*",
					element: <Pages.CognitivePage />,
				},
			];
		} else if (context.envId === 2) {
			routePaths = [
				{
					path: "/",
					element: <Pages.LandingPage />,
				},
				{
					path: "/search",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/search/:searchWord",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/study/*",
					element: <Pages.StudyModule />,
				},
				{
					path: "/discover",
					element: <Pages.DiscoverModule />,
				},
				{
					path: "/collection/*",
					element: <Pages.StoriesPage />,
				},
				{
					path: "/stories/*",
					element: <Pages.OldStoriesPage />,
				},
				{
					path: "/insights-dashboard/*",
					element: <Pages.InsightsDashboard />,
				},
			];
		} else if (context.envId === 3) {
			routePaths = [
				{
					path: "/",
					element: <Pages.LandingPage />,
				},
				{
					path: "/search",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/dashboard/*",
					element: <Pages.Dashboard />,
				},
				{
					path: "/highpatientneeds/*",
					element: <Pages.HighPatientNeeds />,
				},
				{
					path: "/search/:searchWord",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/study/*",
					element: <Pages.StudyModule />,
				},
				{
					path: "/Node_Graph",
					element: <Pages.NodeGraphView />,
				},
				{
					path: "/Patient_Journey/*",
					element: <Pages.PatientJourneyPage />,
				},
				{
					path: "/stories/*",
					element: <Pages.OldStoriesPage />,
				},
			];
		} else {
			routePaths = [
				{
					path: "/",
					element: <Pages.LandingPage />,
				},
				// {
				// 	path: "/dashboard/*",
				// 	element: <Pages.Dashboard />,
				// },
				{
					path: "/search",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/search/:searchWord",
					element: <Pages.SearchEngine />,
				},
				{
					path: "/study/*",
					element: <Pages.StudyModule />,
				},
				{
					path: "/discover",
					element: <Pages.DiscoverModule />,
				},
				{
					path: "/stories/*",
					element: <Pages.OldStoriesPage />,
				},
				{
					path: "/collection/*",
					element: <Pages.StoriesPage />,
				},
				{
					path: "/Node_Graph",
					element: <Pages.NodeGraphView />,
				},
				{
					path: "/Patient_Journey/*",
					element: <Pages.PatientJourneyPage />,
				},
				// {
				// 	path: "/highpatientneeds/*",
				// 	element: <Pages.HighPatientNeeds />
				// }
				// {
				// 	path: "highpatientneeds/Discon",
				// 	element: <Pages.Discon />
				// }
			];
		}
	} else if (context.userType === userTypeEnum[1]) {
		routePaths = [
			{
				path: "/",
				element: <Pages.LandingPage />,
			},
			// {
			// 	path: "/dashboard/*",
			// 	element: <Pages.Dashboard />,
			// },
			{
				path: "/search/*",
				element: <Pages.SearchEngine />,
			},
			{
				path: "/study/*",
				element: <Pages.StudyModule />,
			},
			// {
			// 	path: "/discover/*",
			// 	element: <Pages.DiscoverModule />,
			// },
		];
	} else if (context.userType === userTypeEnum[2]) {
		routePaths = [
			{
				path: "/",
				element: <Pages.LandingPage />,
			},
			// {
			// 	path: "/dashboard/*",
			// 	element: <Pages.Dashboard />,
			// },
			{
				path: "/search/*",
				element: <Pages.SearchEngine />,
			},
			{
				path: "/study/*",
				element: <Pages.StudyModule />,
			},
			// {
			// 	path: "/discover/*",
			// 	element: <Pages.DiscoverModule />,
			// },
			// {
			// 	path: "/highpatientneeds/*",
			// 	element: <Pages.HighPatientNeeds />
			// }
		];
	}

	return routePaths;
}
// business user - dash +searc
// power - dash+search+story
// analyst - search+story
