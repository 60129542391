import React, { useState, useContext } from "react";
import AppContext from "../../../../store";
import "./index.css";
import "./index.css";
import {
	UploadOutlined,
	// DownOutlined,
	ExclamationCircleOutlined,
	ApartmentOutlined,
} from "@ant-design/icons";
import { Button, Upload, message, Progress, Tooltip, Modal, notification } from "antd";
import TranscriptData from "./TranscriptData";
import { ViewList } from "@styled-icons/material/ViewList";
import * as StudyServiceHelper from "../../../SearchEngine/ServiceHelpers/study";
import MultiTranscriptData from "./MultiTranscriptData";

interface WaveData {
	key: number;
	waveId?: number;
	waveName: string;
	studyIterId: number;
	geoId?: number;
	geography: string | null;
	startDate?: string;
	endDate?: string;
	guideId?: number;
	guideName: string | null;
	transcriptCount: number;
	failedTranscriptCount?: number;
	pendingTranscriptCount?: number;
	processedTranscriptCount?: number;
	rowSpan?: number;
	transDetails?: any[];
	ngStatus?: string;
	createdBy?: string;
	containsAudioFile?: boolean;
}

interface singleFileListObject {
	lastModified: number;
	lastModifiedDate: string;
	name: string;
	percent: number;
	size: number;
	status: string;
	type: string;
	uid: string;
	// originFileObj:
	// File {uid: 'rc-upload-1690371862992-13', name: '34831_FORM-16-FY22-23_FY 2022 - 2023.pdf', lastModified: 1690352596631, lastModifiedDate: Wed Jul 26 2023 11:53:16 GMT+0530 (India Standard Time), webkitRelativePath: '', …}
}

interface FileType {
	fileTypeId: number;
	fileTypeName: string;
}

interface StudyTranscriptObject {
	fileTypeId: number;
	studyIterId: number;
	transcriptId: number;
	transcriptName: string;
	filename: string | null;
	s3Path: string;
	processedTranscriptS3path: string | null;
	trProcessStatus: string;
	parseStatus: any;
	isUploaded: boolean;
	isQCed: boolean;
	isProcessed: boolean;
	uploadErrorText: string;
	qCErrorText: string;
	activeFlag: any;
	uploadedOn: any;
	processStartTime: any;
	processEndTime: any;
	createdOn: any;
	createdBy: any;
	updatedOn: any;
	updatedBy: any;
}

interface TransModal {
	fileTypes: FileType[];
	studyTranscriptData: StudyTranscriptObject[];
}

export default function TranscriptInput(props: {
	transcriptCount: number;
	studyIterId: number;
	studyName: string;
	waveData: WaveData;
	processedTranscriptCount: number;
	disabled: boolean;
	envFlag?: number;
	studyTypeId: number | undefined;
}) {
	const context = useContext(AppContext);

	const [fileList, setFileList] = useState<any[]>([]); // Provide type annotation for fileList
	const [uploadStatus, setUploadStatus] = useState("idle"); // Possible values: 'idle', 'uploading', 'done', 'error'
	const [dataSource, setDataSource] = useState<StudyTranscriptObject[]>([]);
	const [fileTypes, setFileTypes] = useState<FileType[]>([]);
	const [tempSuccessFileCount, setTempSuccessFileCount] = useState<number>(0);
	const [tempTotalNewFileCount, setTempTotalNewFileCount] = useState<number>(0);
	const [updateTriggerStatus, setUpdateTriggerStatus] = useState(false);
	const [overallProgress, setOverallProgress] = useState(0); // Overall upload progress
	const [isUploading, setIsUploading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	React.useEffect(() => {
		updateExistingFilesCount();
	}, []);

	let filePath: string =
		process.env.REACT_APP_API_URL +
		"study/UploadToS3?data=" +
		0 + // trId
		"|" +
		props.waveData.studyIterId + // studyIterId
		"|" +
		(props.waveData.studyIterId + "/transcript") + // upload s3 path (studyIterId/transcript)
		"|" +
		-1 + // FileId
		"|" +
		"false" + // true for guide, false for transcript
		"|" +
		(props.studyTypeId || -1); // studyTypeId, send -1 if not available
	filePath = filePath.replace(/\s/g, "_");

	// API Request to fetch SM Transcripts Data
	async function fetchStudyTranscriptData() {
		// const loadingMessage = message.loading('Fetching Transcript Data...', 0);
		try {
			// console.log("Study Iter Id for API request is -> ", props.studyIterId);

			if (props.studyIterId !== 0) {
				const data: any = await StudyServiceHelper.getTranscriptDetailsForStudy(props.studyIterId);

				if (data) {
					setDataSource(data.studyTranscriptData);
					setFileTypes(data.fileTypes);
					// loadingMessage();
					if (data.studyTranscriptData.length > 0)
						// message.success("Successfully fetched Transcript Data");
						console.log("Successfully fetched Transcript Data");
					// message.warning("No Available Transcript Data");
					else console.log("No Available Transcript Data");
				} else {
					// loadingMessage();
					console.log("Fetch Study Transcript Data API call failed.");
				}
			} else {
				// loadingMessage();
				console.log("Invalid Study Iter Id (Study Iter Id is 0).");
			}
		} catch (error) {
			// loadingMessage();
			message.error("Error fetching Transcript Data");
			console.error("Error fetching Study Transcript Data:", error);
		}
	}

	const updateExistingFilesCount = () => {
		if (props.transcriptCount > 0) {
			const existingFileList: singleFileListObject[] = [];

			for (let i = 0; i <= props.transcriptCount - 1; i++) {
				existingFileList.push({
					lastModified: 1690352596631,
					lastModifiedDate: props.waveData.transDetails ? props.waveData.transDetails[i].updatedOn : "Sun Jan 1 2023 12:00:00 GMT+0530 (India Standard Time)",
					name: props.waveData.transDetails ? props.waveData.transDetails[i]?.trFileName : null,
					percent: 100,
					size: 1024,
					status: "done",
					type: "application/pdf",
					uid: props.waveData.transDetails ? props.waveData.transDetails[i]?.trId : `rc-upload-${Date.now()}-${i}`,
					// lastModifiedDate: 'Wed Jul 26 2023 11:53:16 GMT+0530 (India Standard Time)',
					// name: `File ${i}.pdf`,
					// uid: `rc-upload-${Date.now()}-${i}`,
				});
			}

			setFileList(existingFileList);
		}
	};

	// Define a function to convert a file to base64 string for previewing
	const getBase64 = (file: any) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleUploadChange = (info: any) => {
		// Check if there are any files currently being uploaded
		const isUploadingNow = info.fileList.some((file: any) => file.status === "uploading");

		// If a new file is being uploaded, set isUploading to true
		if (isUploadingNow) {
			setIsUploading(true);
		}

		// Calculate the overall progress
		const totalProgress = info.fileList.reduce((acc: number, file: any) => {
			if (file.status === "done") {
				return acc + 100; // Each completed file contributes 100% to the progress
			}
			if (file.status === "uploading") {
				return acc + file.percent; // Add the current file's percent to the progress
			}
			return acc;
		}, 0);

		// Update the overall progress state
		setOverallProgress(totalProgress / info.fileList.length);

		const allFilesUploaded = info.fileList.every((file: any) => file.status !== "uploading");
		// const successfullyUploadedFilesCount = info.fileList.filter((file: any) => file.status === 'done').length;
		// const failedUploadedFilesCount = info.fileList.length - info.fileList.filter((file: any) => file.status === 'error').length;
		let tempTotal = 0;
		let tempSuccess = 0;
		if (info.file.status === "uploading") {
			openNotification("uploadNotification");
			tempTotal = tempTotalNewFileCount + 1;
			setTempTotalNewFileCount(tempTotal);
		}

		if (info.file.status === "done") {
			tempSuccess = tempSuccessFileCount + 1;
			setTempSuccessFileCount(tempSuccess);
			closeNotification("uploadNotification");
		} else if (info.file.status === "error") {
			closeNotification("uploadNotification");
		}

		if (allFilesUploaded && tempSuccess > 0) {
			setTempTotalNewFileCount(0);
			setTempSuccessFileCount(0);
			message.success(`${tempSuccess} files uploaded successfully`);
		}

		// Update the fileList state
		setFileList(info.fileList);

		// Filter out duplicate file names
		//     const uniqueFileList = info.fileList.filter((file: any, index: number, self: any[]) =>
		//       self.findIndex((item: any) => item.name === file.name) === index
		//     );
		//     setFileList(uniqueFileList);
		//     console.log("File List info post upload ->", fileList);
	};

	const getValidFileExtenstions = () => {
		if (context.envId === 2) {
			if (props.studyTypeId === 4) {
				return ".pdf, .pptx";
			} else {
				return ".txt, .doc, .docx, .m4a, .mp3, .mp4, .flac, .wav, .amr, .ogg, .webm, .csv, .xlsx";
			}
		} else {
			return ".txt, .doc, .docx, .m4a, .mp3, .mp4, .flac, .wav, .amr, .ogg, .pptx, .webm, .csv, .xlsx, .pdf";
		}
	};

	const getValidContentTypes = () => {
		if (context.envId === 2) {
			if (props.studyTypeId === 4) {
				return [
					"application/pdf", // pdf
					"application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
				];
			} else {
				return [
					"text/plain", // txt
					"application/msword", // doc
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
					"audio/x-m4a", // m4a
					"audio/mpeg", // mp3
					"video/mp4", // mp4
					"audio/flac", // flac
					"audio/wav", // wav
					"audio/amr", // amr
					"audio/ogg", // ogg
					"video/webm", // webm
					"text/csv", // csv
					"application/vnd.ms-excel", // xlsx
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
				];
			}
		} else {
			return [
				"text/plain", // txt
				"application/msword", // doc
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
				"audio/x-m4a", // m4a
				"audio/mpeg", // mp3
				"video/mp4", // mp4
				"audio/flac", // flac
				"audio/wav", // wav
				"audio/amr", // amr
				"audio/ogg", // ogg
				"application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
				"video/webm", // webm
				"text/csv", // csv
				"application/vnd.ms-excel", // xlsx
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
				"application/pdf", // pdf
			];
		}
	};

	const beforeUploadHandler = (file: any) => {
		// console.log("Before Upload File Info -> ", file);

		const isValidContentType = getValidContentTypes().includes(file.type);
		// const isValidContentType =
		// 	file.type === "text/plain" || // txt
		// 	file.type === "application/msword" || // doc
		// 	file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // docx
		// 	file.type === "audio/x-m4a" || // m4a
		// 	file.type === "audio/mpeg" || // mp3
		// 	file.type === "video/mp4" || // mp4
		// 	file.type === "audio/flac" || // flac
		// 	file.type === "audio/wav" || // wav
		// 	file.type === "audio/amr" || // amr
		// 	file.type === "audio/ogg" || // ogg
		// 	file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || // pptx
		// 	file.type === "video/webm" || // webm
		// 	file.type === "text/csv" || // csv
		// 	file.type === "application/vnd.ms-excel" || // xlsx
		// 	file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // xlsx
		// 	file.type === "application/pdf"; // pdf

		if (!isValidContentType) {
			if (context.envId === 2 && props.studyTypeId === 4) {
				message.error("For this study type, only PDF and PPT files are accepted. Please select a valid file and try again.");
			} else {
				message.error("The uploaded file has an unsupported format. Please select a valid file and try again.");
			}
			return false;
		}

		const isValidSize = file.size <= 209715200; // 200MB in bytes
		if (!isValidSize) {
			message.error("File size exceeds the maximum allowed (200MB). Please upload a smaller file.");
			return false;
		}

		const existingFileName = fileList.some((existingFile) => existingFile.name === file.name);
		if (existingFileName) {
			message.error(`A file named ${file.name} already exists. Please choose a different file.`);
			return false;
		}

		return true;
	};

	const handleRemove = (file: any) => {
		//        console.log('Removing file:', file.name);
		setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== file.uid));
		// Perform additional actions when a file is removed.
	};

	const openNotification = (key: string) => {
		context.setIsStudyTabsRestricted(true);
		const args = {
			message: "Upload In Progress",
			description: "Your files are being uploaded. Please do not switch any tabs or go back to ensure your files are uploaded successfully.",
			duration: 0,
			key: key,
		};
		notification.open(args);
	};

	const closeNotification = (key: string) => {
		// const key = "uploadNotification";
		notification.close(key);
		context.setIsStudyTabsRestricted(false);
	};

	const transcriptUploadComponent = (uploadFilePath: string) => (
		<Upload
			// customRequest={handleUpload}
			beforeUpload={beforeUploadHandler}
			action={uploadFilePath}
			headers={{
				"X-Api-Key": process.env.REACT_APP_API_KEY ?? "0",
			}}
			withCredentials={true}
			onChange={handleUploadChange}
			// onPreview={handlePreview}
			onRemove={handleRemove}
			fileList={fileList}
			// accept=".txt, .doc, .docx, .m4a, .mp3, .mp4, .flac, .wav, .amr, .ogg, .webm, .pptx, .csv, .xlsx, .pdf"
			accept={getValidFileExtenstions()}
			showUploadList={false}
			listType="text"
			// disabled={uploadStatus === "uploading"}
			multiple
			maxCount={100}
		>
			<Button
				style={{ border: "none", background: "transparent", display: "flex", justifyContent: "center", alignItems: "center" }}
				disabled={props.disabled}
				// icon={}
			>
				<UploadOutlined size={20} style={{ color: props.disabled ? "#5a5a5a" : "#27A6A4", marginLeft: "10px" }} />
			</Button>
		</Upload>
	);

	const handleModalClick = () => {
		fetchStudyTranscriptData();
		if (dataSource) setIsModalOpen(!isModalOpen);
	};

	// MODIFIED FOR DEMO PURPOSES - AGRIM
	// const handleUpload = (options: any) => (event: Event) => {
	//     event.stopPropagation();
	//     /// You can handle the file upload logic here using external libraries or APIs.
	//     // The `options` parameter contains information about the file being uploaded.
	//     // You can use `options.file` to get the actual file object.
	//     // After the file upload is completed, call `options.onSuccess` with the appropriate response.
	//     // If the file upload fails, call `options.onError` with the error details.

	//     // For example:
	//     // Call your API or upload service here to upload the file and handle the response accordingly.

	//     // Simulating a successful file upload after a delay of 2 seconds.
	//     setTimeout(() => {
	//         options.onSuccess({}, options.file);
	//     }, 1000);
	// };

	async function handleDeleteTranscript(key: string) {
		//      console.log("handleDeleteTranscript function called in TransciptInput ->", key);
		// Find the index of the transcript to be deleted
		const dataIndex = dataSource.findIndex((item: any) => item.transcriptId === key);
		let idToBeDeleted: number = parseInt(key);
		//      console.log("Inside handleDeleteTranscript: dataSource, dataIndex  ->", dataSource, dataIndex);

		if (dataIndex !== -1) {
			// Remove the transcript from the dataSource
			var response = await StudyServiceHelper.deleteUploadedTranscript(idToBeDeleted);
			// console.log(response);
			if (response === true) {
				message.success("Transcript deleted successfully");
				setDataSource((prevData) => prevData.filter((item: any) => item.transcriptId !== key));
				updateExistingFilesCount();
			} else {
				message.error("Error while deleting transcript");
			}
		}
	}

	async function triggerNodeGraph() {
		// console.log("triggerNodeGraph function called in TransciptInput with record -> ", props.waveData);
		if (!props.waveData) {
			return;
		}

		try {
			const response = await StudyServiceHelper.triggerNodeGraph(props.waveData.studyIterId);
			// const response = false;

			if (response) {
				const geography = props.waveData.geography || "";
				message.success(`Node Graph triggered successfully for geography - ${geography}`);
				setUpdateTriggerStatus(true);
			} else {
				message.error("Error while triggering Node Graph");
				setUpdateTriggerStatus(false);
			}
		} catch (error) {
			console.error("Error triggering Node Graph:", error);
			message.error("An error occurred while triggering Node Graph. Please try again later.");
			setUpdateTriggerStatus(false);
		}
	}

	const handleNodeGraphTrigger = () => {
		// console.log("handleNodeGraphTrigger function called in TransciptInput with record -> ", props.waveData, props.envFlag);
		if (props.waveData.containsAudioFile && props.waveData.guideId === 0 && props.envFlag != 2) {
			Modal.confirm({
				title: "Are you sure you want to proceed?",
				content: "Please upload DG guide to get refined result.",
				// content: 'Without DG guide, audio file alone might not produce optimised result.',
				okText: "Trigger Anyway",
				okType: "danger",
				cancelText: "Cancel",
				onOk() {
					triggerNodeGraph();
				},
			});
		} else {
			triggerNodeGraph();
		}
	};

	const isDiscoveryButtonDisabled = () =>
		props.waveData.ngStatus === "RUNNING" ||
		props.waveData.ngStatus === "FAILED" ||
		props.waveData.ngStatus === "NONE" ||
		props.waveData.ngStatus === null ||
		updateTriggerStatus;
	const isNGStatusFailed = () => props.waveData.ngStatus === "FAILED";

	return (
		<>
			{/* TEST CHANGES - REMOVING CONDITIONAL RENDERING - REMOVE THIS COMMENT LATER - AGRIM */}
			{/* {props.envFlag === 2 ?
        <MultiTranscriptData uploadedCount={0} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} dataSource={dataSource} fileTypes={fileTypes} studyIterId={props.studyIterId} handleDelete={handleDeleteTranscript} fetchStudyTranscriptData={fetchStudyTranscriptData} geographyCreatedBy={props.waveData.createdBy ?? ""}/> : */}
			<TranscriptData
				uploadComponent={(fp) => transcriptUploadComponent(fp)}
				uploadedCount={fileList.length}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				dataSource={dataSource}
				handleDelete={handleDeleteTranscript}
				fetchStudyTranscriptData={fetchStudyTranscriptData}
				geographyCreatedBy={props.waveData.createdBy ?? ""}
				waveName={props.waveData.waveName || ""}
				geoName={props.waveData.geography || ""}
			/>
			{/* } */}

			<div style={{ display: "grid", alignItems: "center", gridTemplateColumns: "180px 40px" }}>
				{/* Column 1 */}
				<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
					{/* TEST CHANGES - REMOVING CONDITIONAL RENDERING - REMOVE THIS COMMENT LATER - AGRIM */}
					{/* {(fileList.length > 0 || props.envFlag === 2) && ( */}
					{fileList.length > 0 && (
						<div style={{ display: "flex", alignItems: "center" }}>
							<span className="transcript-input-text">
								<p>
									{props.processedTranscriptCount} of {props.transcriptCount} {props.transcriptCount === 1 ? "file" : "files"} processed{" "}
								</p>

								{props.waveData.failedTranscriptCount ? (
									props.waveData.failedTranscriptCount > 0 ? (
										<p style={{ color: "red" }}>
											({props.waveData.failedTranscriptCount} {props.waveData.failedTranscriptCount === 1 ? "file" : "files"} failed)
										</p>
									) : null
								) : null}
							</span>
							<ViewList size={20} onClick={handleModalClick} style={{ color: "#27A6A4", marginLeft: "20px", cursor: "pointer" }} />
						</div>
					)}
				</div>

				{/* Column 2 */}
				<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
					{transcriptUploadComponent(filePath)}

					{/* Display the overall progress */}
					<div style={{ marginLeft: "15px", visibility: isUploading ? "visible" : "hidden" }}>
						<Progress type="circle" width={30} percent={Math.round(overallProgress)} />
					</div>

					<Tooltip title={isNGStatusFailed() ? "Node Graph Failed. Please contact sysadmin." : ""} placement="top">
						<Button
							size="small"
							shape="round"
							onClick={handleNodeGraphTrigger}
							style={{
								marginLeft: "20px",
								opacity: isDiscoveryButtonDisabled() ? 0.9 : 1,
								background: isDiscoveryButtonDisabled() ? "#EAEAEA" : "transparent",
								color: isNGStatusFailed() ? "red" : isDiscoveryButtonDisabled() ? "#8A8A8A" : "#27A6A4",
								borderColor: isDiscoveryButtonDisabled() ? "#8A8A8A" : "#27A6A4",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							disabled={isDiscoveryButtonDisabled()}
						>
							<ApartmentOutlined style={{ fontSize: "12px", color: isDiscoveryButtonDisabled() ? "#8A8A8A" : "#27A6A4", verticalAlign: "middle" }} />
							<span style={{ fontSize: "12px", marginLeft: "5px" }}>
								{updateTriggerStatus === true
									? "Running"
									: props.waveData.ngStatus === "CREATE"
									? "Create Discovery Tree"
									: props.waveData.ngStatus === "UPDATE"
									? "Refresh Discovery Tree"
									: props.waveData.ngStatus === "RUNNING"
									? "Running"
									: props.waveData.ngStatus === "FAILED"
									? "Node Graph Failed"
									: "Refresh Discovery Tree"}
							</span>
						</Button>
					</Tooltip>

					{uploadStatus === "error" && (
						// Show danger sign if file upload fails
						<span style={{ marginLeft: "5px" }}>
							<ExclamationCircleOutlined style={{ fontSize: "14px", color: "#E9364C" }} />
						</span>
					)}
				</div>
			</div>
		</>
	);
}
