// LinkRenderer.tsx
import React, { ReactNode, MouseEvent } from "react";
import "./MarkdownMediaStyle.css";

interface ILinkRendererProps {
	setIsMarkdownModalVisible: (isMarkdownModalVisible: boolean) => void;
	setMarkdownContentS3Path: (markdownContentS3Path: string | undefined) => void;
	setMarkdownHighlightedDialogueIds: (markdownHighlightedDialogueIds: number[] | undefined) => void;
}

interface IRenderProps {
	href: string;
	children: ReactNode;
}

export const LinkRenderer = ({ setIsMarkdownModalVisible, setMarkdownContentS3Path, setMarkdownHighlightedDialogueIds }: ILinkRendererProps) => {
	const RenderLink = ({ href, children }: IRenderProps) => {
		const handleClick = (e: MouseEvent<HTMLAnchorElement>, href: string) => {
			e.preventDefault();
			// Decode the URL-encoded string
			const decodedHref = decodeURIComponent(href);
			const [textS3Path, dialogueIds] = decodedHref.split("|");
			setIsMarkdownModalVisible(true);
			setMarkdownContentS3Path(textS3Path);
			setMarkdownHighlightedDialogueIds(JSON.parse(dialogueIds));
		};

		return (
			<a href={href} onClick={(e) => handleClick(e, href)} className="markdownLink">
				{children}
			</a>
		);
	};

	return RenderLink;
};
