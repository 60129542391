import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import sentimentChartConfig from "../chartConfigs/sentimentChartConfig";

interface ISentimentChartProps {
	data: Array<{ avgscore?: number; value: number; date?: string }> | null;
	chartType?: "avgSentiment";
	colors?: string[];
	title?: string;
	handleClick: (inputData: Record<string, any>, inputType: string) => void;
}

export default function SentimentChart({ data, chartType = "avgSentiment", colors, title = "", handleClick }: ISentimentChartProps) {
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

	useEffect(() => {
		if (chartComponentRef.current) {
			const chart = chartComponentRef.current.chart;
			if (chart) {
				// Handle click events for bar columns
				chart.series[0].data.forEach((point) => {
					point.update({
						events: {
							click: () => {
								// @ts-ignore
								handleClick({ id: point.id, label: point.category, value: point.y }, title + "BarLineChart");
								console.log("Sentiments Clicked column:", point);
							},
						},
					});
				});

				// Handle click events for line chart points
				chart.series[1].data.forEach((point) => {
					point.update({
						events: {
							click: () => {
								console.log("Sentiments Clicked line point:", point);
							},
						},
					});
				});
			}
		}
	}, []);

	if (!data || data.length === 0) {
		return null;
	}

	let chartConfig;

	switch (chartType) {
		case "avgSentiment":
		default:
			chartConfig = sentimentChartConfig;
			break;
	}

	const options: Highcharts.Options = {
		...sentimentChartConfig,
		// @ts-ignore
		xAxis: {
			...sentimentChartConfig.xAxis,
			categories: data.map((item) => item.date),
		},
		series: [
			{
				...sentimentChartConfig.series[0],
				// @ts-ignore
				data: data.map((item) => item.value),
				color: colors && colors[0],
			},
			{
				...sentimentChartConfig.series[1],
				// @ts-ignore
				data: data.map((item) => item.avgscore),
				color: colors && colors[1],
				marker: {
					...sentimentChartConfig.series[1].marker,
					lineColor: colors && colors[1],
				},
			},
		],
	};

	return <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />;
}
