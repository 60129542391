const horizontalBarChartConfig = {
	chart: {
		type: "bar",
	},
	title: {
		text: "Horizontal Bar Chart",
	},
	xAxis: {
		categories: ["A", "B", "C", "D", "E"],
	},
	yAxis: {
		title: {
			text: "Values",
		},
	},
	plotOptions: {
		bar: {
			dataLabels: {
				enabled: true,
			},
		},
	},
	legend: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
};

export default horizontalBarChartConfig;
