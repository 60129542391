import { request } from "./../App";
import { IChartFiltersPayload } from "../model/interface/insightsDashboard";

export const getInsightsDashboardChartData = async (chartDataFilters: IChartFiltersPayload) => {
	const fetchUrl = "Insights/GetInsightsChartData";
	const data = await request
		.post(fetchUrl, chartDataFilters, { withCredentials: true })
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			return null;
		});
	return data;
};
