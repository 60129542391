import { Button, Select, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { icons } from "../../GlobalThemes";
import React, { useEffect, useState, useContext } from "react";
import { searchQuery } from "../../Pages/SearchEngine/Search";
import * as NodeGraphServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/nodeGraph";
import { useDispatch, useSelector } from "react-redux";
import { selectStore, setCognitiveFilters, setCognitiveHomeData } from "../../state/slice/appSlice";
import { ICognitiveFilterValues } from "../../model/interface/cognitive";
import { ApiErrorMessages, CognitiveModuleMessages } from "../../constants/messages";
import AppContext from "../../store";

/**
 *
 * @remarks - This function is the part of cognitive tab and will be rendered at the top of the page
 *
 * @param study
 * @param wave
 * @param region
 *
 * @returns the filters at cognitive tab
 */

export function CognitiveFilters(props: {
	showDrawer?: () => void;
	fetchTranscripts?: (queries: searchQuery) => void;
	searchWordRequired: boolean;
	searchString?: any;
	setSearchString?: any;
	fetchedFilters?: any;
	searchClick: (value: any) => void;
	showButton?: boolean;
}) {
	const context = useContext(AppContext);
	const store = useSelector(selectStore);
	const dispatch = useDispatch();
	const initialSearchFilterData: ICognitiveFilterValues = {
		study: undefined,
		studyId: undefined,
		wave: undefined,
		waveId: undefined,
		region: undefined,
		regionId: undefined,
		studyIterId: undefined,
	};

	const [searchFilterData, setSearchFilterData] = useState<ICognitiveFilterValues | undefined>(undefined);
	const [fetchedRegionData, setFetchedRegionData] = React.useState<any>();
	const [fetchedWaveFilters, setFetchedWaveFilters] = React.useState<any>();
	const [fetchedRegionFilters, setFetchedRegionFilters] = React.useState<any>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4", alignSelf: "center", flex: 1 }} spin />;

	useEffect(() => {
		setSearchFilterData(store.cognitiveTab.cognitiveFilters);
	}, []);

	// API Request to fetch Cognitive Wave Dropdown Data
	async function fetchDropdownWaveData(studyId: number) {
		setIsLoading(true);
		try {
			const data: any = await NodeGraphServiceHelper.getWavesForStudyId(studyId);

			if (data && data.length > 0) {
				setFetchedWaveFilters([
					{
						optionsObject: data.map((item: { waveId: number; waveName: string; studyIterId: number }) => ({
							id: item.waveId,
							name: item.waveName,
							studyIterId: item.studyIterId,
							subgroup: [],
						})),
						filter: "Wave Name",
						type: "select",
					},
				]);
				setFetchedRegionFilters(null);
				setSearchFilterData((prevFilterData) => ({
					...(prevFilterData || initialSearchFilterData),
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				}));
			} else {
				setFetchedRegionFilters(null);
				setSearchFilterData((prevFilterData) => ({
					...(prevFilterData || initialSearchFilterData),
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				}));
				message.error(context.envId && CognitiveModuleMessages[context.envId].NO_WAVE_FOUND);
				console.log("Fetch Wave Data API returned empty data or no waves found for the study.");
			}
		} catch (error) {
			setFetchedRegionFilters(null);
			setSearchFilterData((prevFilterData) => ({
				...(prevFilterData || initialSearchFilterData),
				region: undefined,
				regionId: undefined,
				studyIterId: undefined,
			}));
			message.error(ApiErrorMessages.UNEXPECTED_ERROR);
			console.error("Error fetching Wave Dropdown Data:", error);
		} finally {
			setIsLoading(false); // Reset isLoading state after API call
		}
	}

	// API Request to fetch Cognitive Region Dropdown Data
	async function fetchDropdownRegionData(waveId: number) {
		setIsLoading(true);
		try {
			const data: any = await NodeGraphServiceHelper.getGeoData(waveId);

			if (data && data.length > 0) {
				setFetchedRegionData(data);
				setFetchedRegionFilters([
					{
						optionsObject: data.map((item: { geoId: number; geoName: string; studyIterId: number }) => ({
							id: item.geoId,
							name: item.geoName,
							studyIterId: item.studyIterId,
							subgroup: [],
						})),
						filter: "Region Name",
						type: "select",
					},
				]);
			} else {
				setFetchedRegionData(null);
				setFetchedRegionFilters(null);
				message.error(context.envId && CognitiveModuleMessages[context.envId].NO_REGION_FOUND);
				console.log("Fetch Region Data API returned empty data or no regions found for the wave.");
			}
		} catch (error) {
			setFetchedRegionData(null);
			setFetchedRegionFilters(null);
			message.error(ApiErrorMessages.UNEXPECTED_ERROR);
			console.error("Error fetching Region Dropdown Data:", error);
		} finally {
			setIsLoading(false); // Reset isLoading state after API call
		}
	}

	const handleFilters = (filterType: string, filterValue: string | boolean | string[], filterKey: number | void | null) => {
		// console.log("handleFilters value:", filterType, filterValue, filterKey);
		// console.log("fetchedRegionData value:", fetchedRegionData);
		let updatedFilters: any = { ...searchFilterData };

		updatedFilters[filterType] = filterValue;

		if (filterKey) {
			updatedFilters[filterType + "Id"] = filterKey;

			if (filterType === "region") {
				let studyIterIds = fetchedRegionData?.filter((e?: any) => e.geoId == filterKey);
				if (studyIterIds && studyIterIds.length > 0) {
					updatedFilters[filterType + "Id"] = filterKey;
					updatedFilters["studyIterId"] = studyIterIds[0].studyIterId;
				}
			}

			if (filterType === "study" && filterKey) {
				const temp = JSON.parse(JSON.stringify(updatedFilters));
				temp["wave"] = undefined;
				temp["waveId"] = undefined;
				temp["region"] = undefined;
				temp["regionId"] = undefined;
				temp["studyIterId"] = undefined;
				updatedFilters = temp;
				fetchDropdownWaveData(filterKey);
			}

			if (filterType === "wave" && filterKey) {
				updatedFilters = {
					...updatedFilters,
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				};
				fetchDropdownRegionData(filterKey);
			}
		} else {
			// Reset all dropdown options and selected values
			switch (filterType) {
				case "study":
					updatedFilters = initialSearchFilterData;
					setFetchedWaveFilters(undefined);
					setFetchedRegionFilters(undefined);
					break;
				case "wave":
					updatedFilters = {
						...updatedFilters,
						wave: undefined,
						waveId: undefined,
						region: undefined,
						regionId: undefined,
						studyIterId: undefined,
					};
					setFetchedRegionFilters(undefined);
					break;
				case "region":
					updatedFilters = {
						...updatedFilters,
						region: undefined,
						regionId: undefined,
						studyIterId: undefined,
					};
					break;
				default:
					updatedFilters = initialSearchFilterData;
					setFetchedWaveFilters(undefined);
					setFetchedRegionFilters(undefined);
					break;
			}
		}

		// console.log("Cognitive Search Handle Filters - STATE -> ", updatedFilters);
		// console.log("Cognitive Search Handle Filters - REDUX -> ", store.cognitiveTab.cognitiveFilters);
		setSearchFilterData(updatedFilters);
		store.cognitiveTab.cognitiveFilters !== updatedFilters && dispatch(setCognitiveHomeData(undefined));
		dispatch(setCognitiveFilters(updatedFilters));
	};

	const onClickSearch = () => {
		props.searchClick(searchFilterData);
	};

	const renderLoadingSpinner = () => (
		<div style={{ textAlign: "center", margin: "40px 0", flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "transparent" }}>
			<Spin indicator={antIcon} />
			<p>Loading...</p>
		</div>
	);

	return (
		<div>
			<div className="flex justify-between items-center pb-4 mt-4">
				<div className="flex items-center">
					<div className="flex items-center mr-6">
						<span className="mr-2">Study:</span>
						<div style={{ width: "200px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder="Select Study Name"
								value={store.cognitiveTab.cognitiveFilters?.study}
								allowClear
								disabled={props.showButton !== undefined ? !props.showButton : false}
								onChange={(value: string, option: any) => {
									// const key = option ? option.key : searchFilterData.studyId;
									const key = option ? option.key : null;
									handleFilters("study", value, key as number);
								}}
							>
								{isLoading
									? renderLoadingSpinner()
									: props.fetchedFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>

					<div className="flex items-center mr-6">
						<span className="mx-2">Wave:</span>
						<div style={{ width: "150px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder="Select Wave"
								value={store.cognitiveTab.cognitiveFilters?.wave}
								allowClear
								disabled={props.showButton !== undefined ? !props.showButton : false}
								onChange={(value: string, option: any) => {
									const key = option ? option.key : null;
									handleFilters("wave", value, key as number);
								}}
							>
								{isLoading
									? renderLoadingSpinner()
									: fetchedWaveFilters &&
									  fetchedWaveFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>

					<div className="flex items-center mr-6">
						<span className="mx-2">Region:</span>
						<div style={{ width: "150px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder="Select Region"
								value={store.cognitiveTab.cognitiveFilters?.region}
								allowClear
								disabled={props.showButton !== undefined ? !props.showButton : false}
								onChange={(value: string, option: any) => {
									const key = option ? option.key : null;
									handleFilters("region", value, key as number);
								}}
							>
								{isLoading
									? renderLoadingSpinner()
									: fetchedRegionFilters &&
									  fetchedRegionFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>
					{props.showButton === false ? (
						<></>
					) : (
						<Button type="primary" htmlType="submit" className="green-button flex items-center ml-4" onClick={() => onClickSearch()}>
							Show Insights
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}
