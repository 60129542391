import React, { useState, useEffect } from "react";
import LandingCards from "../../../../Components/LandingCards";
import CreateStudyForm from "../../../../Components/CreateStudyForm";
import AppContext from "../../../../store";
import { Modal, Divider } from "antd";
import "./style.css";
import { Input, Button, Popconfirm, message } from "antd";
import { SearchOutlined, RightOutlined, VerticalLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as StudyServiceHelper from "../../../SearchEngine/ServiceHelpers/study";
import { request } from "../../../../App";
import { DashboardCards } from "../../../SearchEngine/data";
import { SharedMessages } from "../../../../constants/messages";
// import { dashboardCards } from "../../../../GlobalThemes";

/**
 * This function is used to render the landing cards for specific user
 *
 * @returns the landing cards
 */

export default function LandingBody(props: { searchWordRequired: boolean }) {
	const context = React.useContext(AppContext);
	const cards = DashboardCards();
	const [searchWord, setSearchWord] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [studyList, setStudyList] = useState([]);
	const [tempStudyList, setTempStudyList] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		if (!isOpen && context.userProfile && context.userProfile.profileName && context.envId !== 3) {
			fetchDataForPage();
		}
	}, [context.userProfile, isOpen]);

	async function fetchDataForPage() {
		const reqInterceptor: any = request.interceptors.request.use((config: any) => {
			config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
			if (context.userProfile && context.userProfile.profileName) {
				config.headers["userRole"] = context.userProfile.profileName;
			}
			return config;
		});

		try {
			const data: any = await StudyServiceHelper.getStudies();
			if (data) {
				setStudyList(data);
			}
		} catch (error) {
			console.error(error);
		} finally {
			// Eject the interceptor after the API call
			request.interceptors.request.eject(reqInterceptor);
		}
	}

	const handleFilters = (filterValue: string) => {
		//    console.log("value:", filterValue); // Commented out console.log
		// Add regex check for special characters to filterValue
		const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
		if (typeof filterValue === "string" && specialCharsRegex.test(filterValue)) {
			setErrorMessage("Special characters not allowed");
			return;
		} else {
			setErrorMessage("");
		}
		setSearchWord(filterValue);
	};

	let navigate = useNavigate();

	const { Search } = Input;

	const onSearch = (filterValue: string) => {
		if (filterValue !== "") {
			const lowerCaseFilterValue = filterValue.toLowerCase();
			const tempVar = studyList.filter((el: any) => el.studyName.toLowerCase().includes(lowerCaseFilterValue));
			// console.log("filtered list:" + tempVar);
			setTempStudyList(tempVar);
		} else {
			setTempStudyList([]);
			// fetchDataForPage();
		}
	};

	const handleOk = (values: any) => {
		// console.log("Study Modal handleOk Triggered.");
		// console.log("Form values:", values);
		setIsOpen(false);
	};

	const handleCancel = () => {
		// console.log("Study Modal handleCancel Triggered.");
		setIsOpen(false);
	};

	// React.useEffect(() => {
	//   fetchDataForPage();
	// }, []);

	// React.useEffect(() => {
	//   // console.log("Updated Study list data on landing page: ", studyList);
	// }, [studyList]);

	// React.useEffect(() => {
	//   if (isOpen === false) {
	//     // console.log("Refetching Study Details as isOpen value is -> ", isOpen);
	//     fetchDataForPage();
	//   }
	// }, [isOpen]);

	const handleStudyOnClick = (studyId: number, studyName: string, studyTypeId: number) => {
		// console.log("Selected StudyType details: ", studyTypeId);
		navigate("/study/Manage_Study/" + studyName);
		sessionStorage.setItem("studyId", JSON.stringify(studyId));
		sessionStorage.setItem("studyName", studyName);
		sessionStorage.setItem("studyTypeId", JSON.stringify(studyTypeId));
	};

	const handleDeleteStudy = async (studyId: number) => {
		try {
			// console.log("Inside handleDeleteStudy function");

			// API Request to Delete Study
			const response: any = await StudyServiceHelper.deleteStudy(studyId);

			if (response) {
				// console.log("Study deleted successfully:", response);
				//message.success("Study deleted successfully");

				const updatedData = studyList.filter((item: any) => item.studyId !== studyId);
				setStudyList(updatedData);
			} else {
				message.error("Failed to delete study");
			}
		} catch (error) {
			console.error("Error deleting study:", error);
			message.error("Error deleting study. Please try again later.");
		}
	};

	return (
		<div className="bg-body pb-3 landing-body">
			<div className="flex items-center bg-white pt-4 pl-6">
				<div className="flex items-center w-4/6">
					<Input
						className="flex input-search"
						placeholder={context.envId && SharedMessages[context.envId].QUERY_PLACEHOLDER}
						onChange={(e) => {
							handleFilters(e.target.value);
						}}
						onKeyDown={(e: any) => {
							if (e.key === "Enter") {
								handleFilters(e.target.value);
								navigate("/search/" + searchWord);
							}
						}}
					/>
				</div>

				<div
					className="flex items-center"
					style={{
						padding: "7px",
						background: "#27a6a4",
						borderColor: "#27a6a4",
					}}
					onClick={() => {
						navigate("/search/" + searchWord);
					}}
				>
					<SearchOutlined className="search-button flex items-center" />
				</div>
				{props.searchWordRequired ? (
					<div style={{ marginTop: "-20px" }}>
						<p className="text-sm text-text-red">Keyword is Required</p>
					</div>
				) : errorMessage ? (
					<div className="text-red-500 text-sm mt-1 ml-2">{errorMessage}</div>
				) : null}
				{context.envId !== 3 ? (
					<div className="ml-auto mr-4">
						<Button
							type="primary"
							className="green-button flex items-center"
							onClick={() => {
								setIsOpen(true);
							}}
						>
							Create New Study
						</Button>
					</div>
				) : null}
			</div>
			<div className="items-center bg-white pb-4 pl-6">
				<div className="flex items-center pt-2" style={{ fontWeight: "bold" }}>
					Commonly Searched
				</div>
				<div className="flex items-center pt-2">
					{/* {searchQuery.map((query) => {
						return (
						<div className="flex items-center w-1/3 mr-8" style = {{"cursor": "pointer","borderRightWidth":"medium"}} onClick={() => {navigate("/search/" + {query})}}>
							{query} <RightOutlined className = "ml-28" />
						</div>
					
						);
					})} */}

					{/* <div
            className="flex items-center w-1/3"
            style={{ cursor: "pointer", borderRightWidth: "medium" }}
            onClick={() => {
              navigate(
                "/search/What are the drivers and barriers for Lectrazine adoption?"
              );
            }}
          >
            What are the drivers and barriers for Lectrazine adoption?{" "}
            <RightOutlined className="ml-56" />
          </div>
          <div
            className="flex items-center ml-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                "/search/Write a comparison between one-sided model vs two-sided model"
              );
            }}
          >
            Write a comparison between one-sided model vs two-sided model{" "}
            <RightOutlined className="ml-28" />
          </div> */}
				</div>
			</div>
			{cards?.map((view) => {
				return (
					<>
						<div className="w-full px-4 py-2 flex">
							<div className="w-3/4">
								<h3 className="text-text text-xl font-semibold py-1 px-2">{view.programName}</h3>
								<div className="flex flex-wrap">
									{view.cards.map((card) => {
										return <LandingCards name={card.name} link={card.link} image={card.image} comingSoon={card.link === "" ? true : false} type={card.type} />;
									})}
								</div>
							</div>
							{context.envId !== 3 ? (
								<div className="w-1/4 items-center">
									<div className="flex items-center">
										<h3 className="flex w-5/6 text-text text-xl font-semibold py-2">Data Set</h3>
										<div className="flex items-center" style={{ paddingLeft: "46px" }}>
											<Search
												className=" items-center"
												placeholder="Search Datasets"
												allowClear
												onChange={(e) => {
													onSearch(e.target.value);
												}}
												style={{ cursor: "pointer", width: "200px" }}
											/>
										</div>
									</div>
									<div className="pt-2" style={{ height: "46vh", overflow: "auto" }}>
										{!tempStudyList || tempStudyList.length === 0
											? studyList?.map((study?: any) => (
													<div
														className="items-centre"
														onClick={() => handleStudyOnClick(study.studyId, study.studyName, study.studyTypeId)}
														style={{ cursor: "pointer" }}
													>
														{study.studyName}{" "}
														<RightOutlined
															style={{
																cursor: "pointer",
																float: "right",
																paddingRight: "5px",
															}}
														/>
														{study.studyIterCount === 0 && (
															<span onClick={(e) => e.stopPropagation()} style={{ float: "right", paddingRight: "20px", marginTop: "-6px" }}>
																<Popconfirm
																	title="Sure to delete this study?"
																	placement="topRight"
																	arrowPointAtCenter
																	onConfirm={() => {
																		handleDeleteStudy(study.studyId);
																		console.log("Study deleted");
																	}}
																	onCancel={() => {
																		console.log("Study deletion cancelled");
																	}}
																	okText="Yes"
																	cancelText="No"
																>
																	<DeleteOutlined
																		style={{
																			cursor: "pointer",
																			// color: "#27A6A4",
																			fontWeight: "bold",
																		}}
																	/>
																</Popconfirm>
															</span>
														)}
														<Divider />
													</div>
											  ))
											: tempStudyList?.map((study?: any) => (
													<div
														className="items-centre"
														onClick={() => handleStudyOnClick(study.studyId, study.studyName, study.studyTypeId)}
														style={{ cursor: "pointer" }}
													>
														{study.studyName}{" "}
														<RightOutlined
															style={{
																cursor: "pointer",
																float: "right",
																paddingRight: "5px",
															}}
														/>
														{study.studyIterCount === 0 && (
															<span onClick={(e) => e.stopPropagation()} style={{ float: "right", paddingRight: "20px", marginTop: "-6px" }}>
																<Popconfirm
																	title="Sure to delete this study?"
																	placement="topRight"
																	arrowPointAtCenter
																	onConfirm={() => {
																		handleDeleteStudy(study.studyId);
																		console.log("Study deleted");
																	}}
																	onCancel={() => {
																		console.log("Study deletion cancelled");
																	}}
																	okText="Yes"
																	cancelText="No"
																>
																	<DeleteOutlined
																		style={{
																			cursor: "pointer",
																			// color: "#27A6A4",
																			fontWeight: "bold",
																		}}
																	/>
																</Popconfirm>
															</span>
														)}
														<Divider />
													</div>
											  ))}
									</div>
								</div>
							) : null}
						</div>
						{isOpen && (
							<Modal title="Create Study" centered open={isOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={700}>
								<CreateStudyForm handleOk={handleOk} handleCancel={handleCancel} />
							</Modal>
						)}
					</>
				);
			})}
		</div>
	);
}
