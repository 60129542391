import { request } from "../App";

export const GetAllCognitiveFactor = async (studyIterId: number) => {
	try {
		const response = await request.get("Cognitive/GetAllCognitiveFactors", {
			params: {
				studyIterId,
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		// console.error("Error : ", err.message);
		throw err;
	}
};

export const GetCognitiveFactorsDetails = async (cogFactorId: number, studyIterId: number) => {
	try {
		const response = await request.get("Cognitive/GetCognitiveFactorsDetails", {
			params: {
				cogFactorId,
				studyIterId,
			},
			withCredentials: true,
		});
		console.log(response.status);
		return response.data;
	} catch (err) {
		// console.error("Error : ", err.message);
		throw err;
	}
};

export const GetReferencesForEvidence = async (cogFactorId: number, studyIterId: number, evidenceName: string) => {
	try {
		const response = await request.get("Cognitive/GetReferencesForEvidence", {
			params: {
				cogFactorId,
				studyIterId,
				evidenceName,
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		// console.error("Error : ", err.message);
		throw err;
	}
};
