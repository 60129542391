import { Breadcrumb } from "antd";
import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import SubNavigation from "../../Components/SubNavigation";
import AppContext from "../../store";
import ManageStudy from "./Components/ManageStudy/index";
import ManageMetadata from "./Components/ManageMetadata/index";
import ConfigureTaxonomy from "./Components/ConfigureTaxonomy/index";
import { StudyModuleTabs } from "../HighPatientNeeds/NodeGraph/data";
// import { active } from "d3";
// import { icons, userTypeEnum } from "../../GlobalThemes";
// import { Avatar, Card, Typography } from "antd";
// import { Collapse, Space } from "antd";
// import { renderToStaticMarkup } from "react-dom/server";
// import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
// import moment from "moment";
// import "./style.css";

export default function StudyModule() {
	// const state: any = useLocation();
	const navigate = useNavigate();
	const context = React.useContext(AppContext);

	const components =
		context.envId === 2
			? sessionStorage.getItem("studyTypeId") === "4"
				? [<ManageStudy />]
				: [<ManageStudy />, <ConfigureTaxonomy />]
			: context.envId === 1
			? [<ManageStudy />, <ManageMetadata />]
			: [<ManageStudy />, <ManageMetadata />, <ConfigureTaxonomy />];
	const tabs = StudyModuleTabs();
	const handleClick = () => {
		navigate("/");
	};

	return (
		<div>
			<div className="w-screen" style={{ zIndex: 99, position: "relative" }}>
				<div className="flex py-4 px-4 items-center" style={{ marginBottom: "-10px" }}>
					<div className="">
						{/* <h2 className="text-xl font-semibold ">Patient Journey</h2> */}
						<Breadcrumb>
							<Breadcrumb.Item>
								<div style={{ display: "flex", alignItems: "center" }}>
									<a onClick={handleClick}>
										<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
											<ArrowLeftOutlined style={{ color: "black", fontWeight: "bold" }} />
											<span>{sessionStorage.getItem("studyName")}</span>
										</div>
									</a>
								</div>
							</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
			</div>
			<SubNavigation tabs={tabs} innerRoute={true} isStudyTabsRestricted={context.isStudyTabsRestricted} />
			<div
				className="w-screen px-4 "
				// className="w-screen px-4 shadow-lg" // provides shadow to components (Manage Study and Metadata)
				style={{ zIndex: 99, position: "relative" }}
			>
				<Routes>
					{tabs.map((subTab, index) => (
						<Route key={index} path={index === 0 && 0 ? "/" : subTab.subTab.split(" ").join("_") + "/*"} element={components[index]} />
					))}
				</Routes>
			</div>
		</div>
	);
}
