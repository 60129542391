import { Button, Select, Spin, Tag, Tooltip, message } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { icons } from "../../GlobalThemes";
import React, { useState, useEffect } from "react";
import { searchQuery } from "../../Pages/SearchEngine/Search";
import * as NodeGraphServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/nodeGraph";
import { useDispatch, useSelector } from "react-redux";
import { selectStore, setDiscoverFilters, setDiscoverTreeData } from "../../state/slice/appSlice";
import AppContext from "../../store";
import { ApiErrorMessages, DiscoverModuleMessages } from "../../constants/messages";

export interface IDiscoverFilterValues {
	study: string | undefined;
	studyId: number | string[] | undefined;
	wave: string[] | undefined;
	waveId: string[] | undefined;
	region: string[] | undefined;
	regionId: string[] | undefined;
	studyIterId: string[] | undefined;
}

/**
 *
 * @remarks - This function is the part of discover tab and will be rendered at the top of the page
 *
 * @param study
 * @param wave
 * @param region
 *
 * @returns the filters at discover tab
 */

export function DiscoverFilters(props: {
	showDrawer?: () => void;
	fetchTranscripts?: (queries: searchQuery) => void;
	searchWordRequired: boolean;
	searchString?: any;
	setSearchString?: any;
	fetchedFilters?: any;
	searchClick: (value: any) => void;
	showButton?: boolean;
}) {
	const context = React.useContext(AppContext);
	const store = useSelector(selectStore);
	const dispatch = useDispatch();
	const initialSearchFilterData: IDiscoverFilterValues = {
		study: undefined,
		studyId: undefined,
		wave: undefined,
		waveId: undefined,
		region: undefined,
		regionId: undefined,
		studyIterId: undefined,
	};

	const [currentContextEnvId, setCurrentContextEnvId] = useState<number>(-1);
	const [searchFilterData, setSearchFilterData] = useState<IDiscoverFilterValues>(store.discoverTab.discoverFilters);
	const [fetchedRegionData, setFetchedRegionData] = React.useState<any>();
	const [fetchedWaveFilters, setFetchedWaveFilters] = React.useState<any>();
	const [fetchedRegionFilters, setFetchedRegionFilters] = React.useState<any>();
	const [waveLoading, setWaveLoading] = React.useState<boolean>(false);
	const [regionLoading, setRegionLoading] = React.useState<boolean>(false);

	const userProfileName = context.userProfile ? context.userProfile.displayName ? context.userProfile.displayName.toLowerCase() : "default" : "default";
	const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4", alignSelf: "center", flex: 1 }} spin />;
	const sortByName = (a: any, b: any) => a.name.localeCompare(b.name);

	useEffect(() => {
		if (store.discoverTab.discoverFilters.studyId && store.discoverTab.discoverFilters.waveId) {
			// @ts-ignore
			fetchDropdownWaveData(store.discoverTab.discoverFilters.studyId);
			// @ts-ignore
			fetchDropdownRegionData(store.discoverTab.discoverFilters.waveId);
		}
	}, []);

	useEffect(() => {
		setCurrentContextEnvId(context.envId || -1);
	}, [context.envId]);

	// API Request to fetch NG Wave Dropdown Data
	async function fetchDropdownWaveData(studyId: number) {
		setWaveLoading(true);
		try {
			const data: any = await NodeGraphServiceHelper.getWavesForStudyId(studyId);

			if (data && data.length > 0) {
				const sortedOptions = data
					.map((item: { waveId: number; waveName: string; studyIterId: number }) => ({
						id: item.waveId,
						name: item.waveName,
						studyIterId: item.studyIterId,
						subgroup: [],
					}))
					.sort(sortByName);

				setFetchedWaveFilters([
					{
						optionsObject: sortedOptions,
						filter: "Wave Name",
						type: "select",
					},
				]);
				setFetchedRegionFilters(null);
				setSearchFilterData((prevFilterData) => ({
					...prevFilterData,
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				}));
				//message.success("Successfully fetched Wave Data");
				// console.log("Fetched Wave Dropdown Data -> ", data);
			} else {
				setFetchedRegionFilters(null);
				setSearchFilterData((prevFilterData) => ({
					...prevFilterData,
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				}));
				message.error(ApiErrorMessages.NO_DATA_AVAILABLE);
				console.log("Fetch Wave Data API returned empty data or no waves found for the study.");
			}
		} catch (error) {
			setFetchedRegionFilters(null);
			setSearchFilterData((prevFilterData) => ({
				...prevFilterData,
				region: undefined,
				regionId: undefined,
				studyIterId: undefined,
			}));
			message.error(ApiErrorMessages.FETCH_ERROR);
			console.error("Error fetching Wave Dropdown Data:", error);
		} finally {
			setWaveLoading(false); // Reset loading state after API call
		}
	}

	// API Request to fetch NG Region Dropdown Data
	async function fetchDropdownRegionData(waveIds: number[]) {
		setRegionLoading(true);
		try {
			const data: any = currentContextEnvId === 2 ? await NodeGraphServiceHelper.getMedicalGeoData(waveIds.map(Number)) : await NodeGraphServiceHelper.getGeoData(waveIds[0]);

			if (data && data.length > 0) {
				setFetchedRegionData(data);
				const mergedData = data.reduce((acc: any, item: any) => {
					// Grouping duplicate objects (with same region name)
					const existingItem = acc.find((i: any) => i.geoName === item.geoName);
					if (existingItem) {
						// If the item already exists, append the new studyIterId
						existingItem.studyIterIds += `,${item.studyIterId}`;
					} else {
						// If it's a new item, add it to the accumulator
						acc.push({
							geoId: item.geoId,
							geoName: item.geoName,
							studyIterIds: item.studyIterId.toString(),
						});
					}
					return acc;
				}, []);

				const sortedOptions = mergedData
					.map((item: any) => ({
						id: item.geoId,
						name: item.geoName,
						studyIterIds: item.studyIterIds,
						subgroup: [],
					}))
					.sort(sortByName);

				setFetchedRegionFilters([
					{
						optionsObject: sortedOptions,
						filter: "Region Name",
						type: "select",
					},
				]);
			} else {
				setFetchedRegionData(null);
				setFetchedRegionFilters(null);
				message.error(ApiErrorMessages.NO_DATA_AVAILABLE);
				console.log("Fetch Region Data API returned empty data or no regions found for the wave.");
			}
		} catch (error) {
			setFetchedRegionData(null);
			setFetchedRegionFilters(null);
			message.error(ApiErrorMessages.FETCH_ERROR);
			console.error("Error fetching Region Dropdown Data:", error);
		} finally {
			setRegionLoading(false); // Reset loading state after API call
		}
	}

	const handleFilters = (filterType: string, filterValue: string | boolean | string[], filterKey: number | number[] | void | null) => {
		// FILTER - FILTERTYPE, FILTERVALUE, FILTERKEY
		// STUDY - string, string, number
		// WAVE - string, string[], number[]
		// REGION - string, string[], number[]

		// console.log("handleFilters value:", filterType, filterValue, filterKey);
		// console.log("fetchedRegionData value:", fetchedRegionData);
		// console.log("fetchedRegionFilters value:", fetchedRegionFilters);
		let updatedFilters: any = { ...searchFilterData };

		updatedFilters[filterType] = filterValue;

		// FILTERKEY is the ID of the selected filter
		if (filterKey) {
			updatedFilters[filterType + "Id"] = filterKey;

			if (filterType === "study" && filterKey) {
				const temp = JSON.parse(JSON.stringify(updatedFilters));
				temp["wave"] = undefined;
				temp["waveId"] = undefined;
				temp["region"] = undefined;
				temp["regionId"] = undefined;
				temp["studyIterId"] = undefined;
				updatedFilters = temp;
				fetchDropdownWaveData(filterKey as number);
			}

			if (filterType === "wave" && filterKey) {
				updatedFilters = {
					...updatedFilters,
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				};
				Array.isArray(filterKey) && filterKey.length > 0 && fetchDropdownRegionData(filterKey);
			}
			
			if (filterType === "region") {
				let selectedGeoData = Array.isArray(filterKey) && fetchedRegionFilters[0]?.optionsObject?.filter((e?: any) => filterKey.includes(e.id.toString()));
				let studyIterIds = selectedGeoData.flatMap((x: any) => x.studyIterIds.split(","));

				if (studyIterIds) {
					updatedFilters[filterType + "Id"] = filterKey;
					updatedFilters["studyIterId"] = studyIterIds;
				}
				// console.log("studyIterIds", studyIterIds);
			}
		} else {
			// NO FILTERKEY FOR INITIAL SELECTION (CLEARING FILTERS)
			// Reset all dropdown options and selected values
			switch (filterType) {
				case "study":
					updatedFilters = initialSearchFilterData;
					setFetchedWaveFilters(undefined);
					setFetchedRegionFilters(undefined);
					break;
				case "wave":
					updatedFilters = {
						...updatedFilters,
						wave: undefined,
						waveId: undefined,
						region: undefined,
						regionId: undefined,
						studyIterId: undefined,
					};
					setFetchedRegionFilters(undefined);
					break;
				case "region":
					updatedFilters = {
						...updatedFilters,
						region: undefined,
						regionId: undefined,
						studyIterId: undefined,
					};
					break;
				default:
					updatedFilters = initialSearchFilterData;
					setFetchedWaveFilters(undefined);
					setFetchedRegionFilters(undefined);
					break;
			}
		}

		// console.log("Search handle filters -> ", updatedFilters);
		setSearchFilterData(updatedFilters);
		dispatch(setDiscoverFilters(updatedFilters));
		filterType === "study" && filterKey && dispatch(setDiscoverTreeData(null));
	};

	const onClickSearch = () => {
		if (searchFilterData.studyIterId === undefined || (Array.isArray(searchFilterData.studyIterId) && searchFilterData.studyIterId.length === 0)) {
			// message.error(context.envId && DiscoverModuleMessages[context.envId].REQD_FIELDS_VALIDATION_ERROR);
			message.error(context.envId && DiscoverModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].REQD_FIELDS_VALIDATION_ERROR);
			return;
		};
		// console.log("onClickSearch Data Value", searchFilterData);
		props.searchClick(searchFilterData);
	};

	const renderLoadingSpinner = () => (
		<div style={{ textAlign: "center", margin: "40px 0", flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "transparent" }}>
			<Spin indicator={antIcon} />
			<p>Loading...</p>
		</div>
	);

	const tagRender = (props: any) => {
		const { label, closable, onClose } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};

		const tagStyle: React.CSSProperties = {
			position: "relative",
			display: "flex",
			flexShrink: 0,
			boxSizing: "border-box",
			maxWidth: "100%",
			height: "24px",
			marginTop: "2px",
			marginBottom: "2px",
			lineHeight: "22px",
			background: "#f5f5f5",
			border: "1px solid #f0f0f0",
			borderRadius: "2px",
			cursor: "default",
			transition: "font-size 0.3s, line-height 0.3s, height 0.3s",
			userSelect: "none",
			marginInlineEnd: "4px",
			paddingInlineStart: "8px",
			paddingInlineEnd: "4px",
			alignItems: "center",
			fontSize: "12px",
			color: "rgba(0, 0, 0, 0.85)",
		};

		const closeIconStyle: React.CSSProperties = {
			fontSize: "10px",
			marginLeft: "4px",
			color: "rgba(0, 0, 0, 0.45)",
			cursor: "pointer",
		};

		const contentStyle: React.CSSProperties = {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		};

		const trimmedLabel = label.length > 15 ? `${label.slice(0, 15)}...` : label;

		return (
			<Tooltip title={label} mouseEnterDelay={0.5}>
				<Tag onMouseDown={onPreventMouseDown} style={tagStyle}>
					<span style={contentStyle}>{trimmedLabel}</span>
					{closable && (
						<CloseOutlined
							style={closeIconStyle}
							onClick={(e: any) => {
								e.stopPropagation();
								onClose();
							}}
						/>
					)}
				</Tag>
			</Tooltip>
		);
	};

	return (
		<div>
			<div className="flex justify-between items-center pb-4 mt-4">
				<div className="flex items-center">
					<div className="flex items-center mr-6">
						<span className="mr-2">Study:</span>
						<div style={{ width: "200px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder="Select Study Name"
								value={store.discoverTab.discoverFilters.study}
								allowClear
								disabled={props.showButton !== undefined ? !props.showButton : false}
								onChange={(value: string, option: any) => {
									// const key = option ? option.key : searchFilterData.studyId;
									const key = option ? option.key : null;
									handleFilters("study", value, key as number);
								}}
							>
								{props.fetchedFilters[0]?.optionsObject?.map((filter?: any) => (
									<Select.Option key={filter["id"]} value={filter["name"]}>
										{filter["name"]}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>

					<div className="flex items-center mr-6">
						{/* <span className="mx-2">{currentContextEnvId === 2 ? "Time Frame:" : "Wave:"}</span> */}
						<span className="mx-2">{context.envId && DiscoverModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].DROPDOWN_2_TEXT}{":"}</span>
						<div style={{ width: currentContextEnvId === 2 ? "300px" : "160px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								// placeholder={currentContextEnvId === 2 ? "Select Time Frame" : "Select Wave"}
								placeholder={context.envId && DiscoverModuleMessages[context.envId][userProfileName === "global" ? 1 : userProfileName === "europe" ? 2 : 3].DROPDOWN_2_PLACEHOLDER}
								// @ts-ignore
								value={store.discoverTab.discoverFilters.wave}
								allowClear
								disabled={props.showButton !== undefined ? !props.showButton : false}
								onChange={(value: string, option: any) => {
									const key = option ? (currentContextEnvId === 2 ? option.map((x: any) => x.key) : option.key) : null;
									const waveValue = currentContextEnvId === 2 ? value : [value];
									handleFilters("wave", waveValue, key as number[]);
								}}
								mode={currentContextEnvId === 2 ? "multiple" : undefined}
								maxTagCount="responsive"
								tagRender={tagRender}
							>
								{waveLoading
									? renderLoadingSpinner()
									: fetchedWaveFilters &&
									  fetchedWaveFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>

					<div className="flex items-center mr-6">
						<span className="mx-2">{currentContextEnvId === 2 ? "Geography:" : "Region:"}</span>
						<div style={{ width: currentContextEnvId === 2 ? "300px" : "160px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder={currentContextEnvId === 2 ? "Select Geography" : "Select Region"}
								// @ts-ignore
								value={store.discoverTab.discoverFilters.region}
								allowClear
								disabled={props.showButton !== undefined ? !props.showButton : false}
								onChange={(value: string, option: any) => {
									const key = option ? (currentContextEnvId === 2 ? option.map((x: any) => x.key) : option.key) : null;
									const regionValue = currentContextEnvId === 2 ? value : [value];
									handleFilters("region", regionValue, key as number[] | null);
								}}
								mode={currentContextEnvId === 2 ? "multiple" : undefined}
								maxTagCount="responsive"
								tagRender={tagRender}
							>
								{regionLoading
									? renderLoadingSpinner()
									: fetchedRegionFilters &&
									  fetchedRegionFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>
					{props.showButton === false ? (
						<></>
					) : (
						<Button type="primary" htmlType="submit" className="green-button flex items-center ml-4" onClick={() => onClickSearch()}>
							Show Insights
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}
