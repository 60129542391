import React, { useState, useEffect } from "react";
import { Card, Empty, Input, List, Popconfirm, Select, Skeleton } from "antd";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import { ClockCircleOutlined, CalendarOutlined, DeleteOutlined } from "@ant-design/icons";
import "../style.css";
import { Link } from "react-router-dom";
import AppContext from "../../../store";
import "../../../Components/Markdown/style.css";
// import DeleteAlert from "../../Components/DeleteAlert";
// import * as Messages from "../../Components/Messages";
// import * as StoriesServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/stories";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

export default function SavedSummaryCard(props: {
  storyId?: any;
  data: any;
  isloaded: boolean;
  showTags?: boolean;
  hideProgressBar?: boolean;
  fetchedStoryData?: any;
  fetchStoryData?: any;
  transcriptData?: any;
  storyDialogues?: any;
  searchString?: any;
  setSearchString?: any;
  keywords?: string[];
  deleteSearch: (id?: any) => void;
  storyName: string;
  createdBy?: string;
  // deleteStory?: (storyId: string) => void;
}) {
  const context = React.useContext(AppContext);
  const [readMore, setReadMore] = useState<any>({});
  const [emptyTextField, setEmptyTextField] = useState("View saved searches after searching with criteria");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedSortOption, setSelectedSortOption] = useState<number>(0);
  const dataSortOptions = [
    { value: 0, label: "Newest first" }, // do not change the order
    { value: 1, label: "Oldest first" },
  ];
  // const [savedSearchData, setsavedSearchData] = React.useState(props.data);
  // const [alertVisible, setAlertVisible] = React.useState(false);
  // const [selectDelete, setSelectDelete] = React.useState(0);
  // const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");

  // function handleDelete(id: number) {
  //   // if (props.deleteStory) props.deleteStory(storyId);
  //   setAlertVisible(true);
  //   setSelectDelete(id);
  //   setSearchQuery(query);
  // }

  // function handleDeleteApi() {
  //   setAlertVisible(false);
  //   if (selectDelete) {
  //     deleteSearch(selectDelete);
  //   }
  //   setSelectDelete(0);
  // }

  // async function deleteSearch(id: number) {
  //   const key = "updatable";
  //   Messages.loading("Deleting Saved Search", key);
  //   const data: any = await StoriesServiceHelper.deleteSavedSearch(id);
  //   if (data === 200) {
  //     const temp = [...filteredData];
  //     const idx = temp.findIndex((d) => d.id === id);
  //     temp.splice(idx, 1);
  //     setFilteredData(temp);
  //     Messages.success("Saved Search Deleted Sucessfully", key);
  //   } else {
  //     Messages.error("Failed!", key);
  //   }
  // }

  useEffect(() => {
    // setFilteredData(props.data);
    handleSortData(selectedSortOption, props.data);
  }, [props.data]);

  // useEffect(() => {
  //   if (filteredData)
  //     handleSortData(selectedSortOption);
  // }, [filteredData]);

  const onSearch = (searchValue: any) => {
    const lowerCaseSearchValue = searchValue.target.value.toLowerCase();
    const filteredData = props.data.filter((result: any) => {
      const combinedValues = Object.values(result).join(" ").toLowerCase();
      return combinedValues.includes(lowerCaseSearchValue);
    });
    // setFilteredData(filteredData);
    handleSortData(selectedSortOption, filteredData);
  };

  const handleSortData = (value: number, data: any) => {
    setSelectedSortOption(value);

    let sortedData = [...data];
    if (value === 0) {
      sortedData.sort((a: any, b: any) => (new Date(b.createdOn) as any) - (new Date(a.createdOn) as any)); // Sort from newest to oldest based on date
    } else if (value === 1) {
      sortedData.sort((a: any, b: any) => (new Date(a.createdOn) as any) - (new Date(b.createdOn) as any)); // Sort from oldest to newest based on date
    }

    setFilteredData(sortedData);
  };

  function formatCustomDate(dateString: Date) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }

  function formatCustomDateTime(dateString: Date) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

    return formattedTime;
  }

  return (
    <>
      {/* <DeleteAlert visible={alertVisible} setVisible={setAlertVisible} onYes={handleDeleteApi} setTitle={"Saved Search"} selectedQuery={searchQuery} /> */}
      <div className="bg-white flex relative px-6 py-4" style={{ position: "fixed", width: "100%", zIndex: "7" }}>
        <Input.Search
          className="w-8/12 input-search"
          placeholder="Search"
          // allowClear
          enterButton
          onChange={onSearch}
        />
        <div className="flex items-center ml-auto pr-6">
          <span className="mx-2">Sort By:</span>
          <Select className="w-32" defaultValue={{ value: 0 }} onChange={(value) => handleSortData(value.value, filteredData)} options={dataSortOptions} labelInValue />
        </div>
      </div>

      {props.isloaded ? (
        <div style={{ zIndex: "-1" }}>
          <List
            itemLayout="vertical"
            header="Saved Searches"
            className="px-6 pt-20"
            size="default"
            pagination={{
              hideOnSinglePage: true,
              style: { float: "right" },
              // onChange: (page) => {
              //   //console.log(page);
              // },
              pageSize: 10,
            }}
            dataSource={filteredData}
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
            }}
            renderItem={(result: any, index: number) => (
              <>
                {/* {console.log("Test:",result.name)} */}
                <Card
                  className="pt-4"
                  bordered={false}
                  // style={{ paddingTop: "0px Important" }}
                >
                  <div
                    className="flex items-center"
                    style={{
                      paddingTop: "0px Important",
                    }}
                  >
                    <span className="w-11/12 pb-2 font-semibold text-base text-text-teal">
                      <Link to={`${result.id}`} state={{ result, name: props.storyName }}>
                        {result.searchQuery}
                      </Link>
                    </span>
                    <div className="flex justify-end items-center">
                      <div className="flex text-gray-400 mr-4">
                        <CalendarOutlined className="text-sm mr-1" />
                        <span className="text-sm whitespace-nowrap">{formatCustomDate(result.createdOn)}</span>
                      </div>
                      <div className="flex text-gray-400 mr-4">
                        <ClockCircleOutlined className="text-sm mr-1" />
                        <span className="text-sm whitespace-nowrap">{formatCustomDateTime(result.createdOn)}</span>
                      </div>
                      <Popconfirm
                        title="Sure to delete this saved search?"
                        placement="topRight"
                        arrowPointAtCenter
                        onConfirm={() => {
                          // deleteSearch(result.id); //, result.searchQuery
                          props.deleteSearch(result.id); //, result.searchQuery
                        }}
                        okText="Yes"
                        cancelText="No"
                        disabled={context.userName !== props.createdBy}
                      >
                        <DeleteOutlined className={`flex text-base ${context.userName !== props.createdBy ? "text-gray-400" : "text-red-600"}`} />
                      </Popconfirm>
                    </div>
                  </div>
                  <div className="text-base pb-2">
                    {readMore[result.id] && result.searchSummary.length > 450 ? (
                      // <ReactMarkdown
                      //   children={result.searchSummary}
                      //   remarkPlugins={[remarkGfm]}
                      //   components={{
                      //     code({ children }) {
                      //       return (
                      //         <SyntaxHighlighter
                      //           children={String(children).replace(/\n$/, "")}
                      //           //   {...TempData}
                      //         />
                      //       );
                      //     },
                      //   }}
                      // />
                      <Markdown remarkPlugins={[breaks, gfm]}>{result.searchSummary}</Markdown>
                    ) : (
                      // <div dangerouslySetInnerHTML={{ __html: result.searchSummary }} />

                      // <ReactMarkdown
                      //   children={result.searchSummary?.slice(0, 450)}
                      //   remarkPlugins={[remarkGfm]}
                      //   components={{
                      //     code({ children }) {
                      //       return (
                      //         <SyntaxHighlighter
                      //           children={String(children).replace(/\n$/, "")}
                      //           //   {...TempData}
                      //         />
                      //       );
                      //     },
                      //   }}
                      // />
                      <Markdown remarkPlugins={[breaks, gfm]}>{result.searchSummary?.slice(0, 450)}</Markdown>
                      // <div dangerouslySetInnerHTML={{ __html: result.searchSummary?.slice(0, 450) }} />
                    )}
                    {result.searchSummary.length > 0 ? (
                      <div
                        className="text-blue-600 italic cursor-pointer"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          padding: "10px",
                        }}
                        onClick={() => {
                          if (readMore[result.id]) {
                            setReadMore({
                              ...readMore,
                              [result.id]: false,
                            });
                          } else {
                            setReadMore({
                              ...readMore,
                              [result.id]: true,
                            });
                          }
                        }}
                      >
                        {!readMore[result.id] ? <>...Read more</> : <>Read less</>}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Card>
              </>
            )}
          />
        </div>
      ) : (
        <>
          <div>
            <Skeleton active className="py-4 px-8 border-b" />
            <Skeleton active className="py-4 px-8 border-b" />
            <Skeleton active className="py-4 px-8 border-b" />
          </div>
        </>
      )}
    </>
  );
}
