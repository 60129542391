import React, { useEffect, useReducer, useCallback, useState } from 'react';
import { Row, Col, Card, message } from 'antd';
import LZString from 'lz-string';
import './index.css';
import BarChart from '../../../../Components/HighchartsWidgets/charts/BarChart';
import LineChart from '../../../../Components/HighchartsWidgets/charts/LineChart';
import SentimentChart from '../../../../Components/HighchartsWidgets/charts/SentimentChart';
import HeatmapComponent from '../../../../Components/InteractiveMapBox';
import Filters, { IFilterConfig } from '../../../../Components/ConfigDrivenFilters';
import * as InsightsDashboardServiceHelper from '../../../SearchEngine/ServiceHelpers/insightsDashboard';
import { IChartFiltersPayload, IChartDataResponse, IChartData } from '../../../../model/interface/insightsDashboard';
import { LoadingOverlay } from '../../../../Components/Loading/LoadingOverlay';
import LineChartWithSelector from '../../../../Components/HighchartsWidgets/charts/LineChartWithSelector';

const filterConfig: IFilterConfig[] = [
	{
		name: 'studyIds',
		label: 'Study Name',
		type: 'datasetDropdown',
	},
	{
		name: 'metaDataIds',
		label: 'Filters',
		type: 'metaFiltersDropdown',
	},
];

type Action =
	| { type: 'SET_GEOGRAPHY_DATA'; payload: IChartData }
	| { type: 'SET_CATEGORY_DATA'; payload: IChartData }
	| { type: 'SET_TOPIC_DATA'; payload: IChartData }
	| { type: 'SET_SENTIMENT_DATA'; payload: IChartData };

type State = {
	geographyData: IChartData | undefined;
	categoryData: IChartData | undefined;
	topicData: IChartData | undefined;
	sentimentData: IChartData | undefined;
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case 'SET_GEOGRAPHY_DATA':
			return { ...state, geographyData: action.payload };
		case 'SET_CATEGORY_DATA':
			return { ...state, categoryData: action.payload };
		case 'SET_TOPIC_DATA':
			return { ...state, topicData: action.payload };
		case 'SET_SENTIMENT_DATA':
			return { ...state, sentimentData: action.payload };
		default:
			return state;
	}
}

export default function LandingPage() {
	const [state, dispatch] = useReducer(reducer, {
		geographyData: undefined,
		categoryData: undefined,
		topicData: undefined,
		sentimentData: undefined,
	});
	const [isLoading, setIsLoading] = useState(false);

	const handleFiltersChange = useCallback(async (inputData: any, inputType?: string) => {
		setIsLoading(true);
		try {
			let chartFilters: IChartFiltersPayload = {
				dataSetsFilter: [],
				insightsFilter: [],
				datesFilter: [],
				categoriesFilter: [],
				topicsFilter: [],
				sentimentCategoriesFilter: [],
				sentimentScoresFilter: [],
				metaDatasFilter: [],
			};

			switch (inputType) {
				case 'formFilters':
					chartFilters = {
						...chartFilters,
						dataSetsFilter: inputData.studyIds || [],
						metaDatasFilter: inputData.metaDataIds || [],
					};
					break;
				case 'CategoryBarChart':
					chartFilters = {
						...chartFilters,
						categoriesFilter: [inputData.label] || [],
					};
					break;
				default:
					console.warn('Unsupported input type:', inputType);
					return;
			}

			const compressedData: any = await InsightsDashboardServiceHelper.getInsightsDashboardChartData(chartFilters);
			if (compressedData === null) {
				throw new Error('No data available');
			}

			// const data: IChartDataResponse = JSON.parse(LZString.decompressFromUTF16(compressedData));
			const data: IChartDataResponse = JSON.parse(LZString.decompressFromBase64(compressedData));
			if (data === null) {
				throw new Error('Failed to decompress data');
			}

			if (data) {
				console.log('Data fetched:', data);
				dispatch({ type: 'SET_GEOGRAPHY_DATA', payload: data.geographyChartsData });
				dispatch({ type: 'SET_CATEGORY_DATA', payload: data.categoryChartsData });
				// dispatch({ type: 'SET_TOPIC_DATA', payload: data.topicChartsData });
				dispatch({ type: 'SET_SENTIMENT_DATA', payload: data.sentimentChartsData });

				message.success('Chart data fetched successfully.');
			} else {
				message.error('No chart data available.');
			}
		} catch (error) {
			message.error('Error in fetching chart data');
			console.error('Error in fetching chart data: ', error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		handleFiltersChange(null);
	}, [handleFiltersChange]);

	const countryCoordinates: { [key: string]: [number, number] } = {
		US: [-95.7129, 37.0902],
		Germany: [10.4515, 51.1657],
		Canada: [-106.3468, 56.1304],
		'United States of America(US)': [-95.7129, 37.0902],
		Japan: [138.2529, 36.2048],
		Switzerland: [8.2275, 46.8182],
		France: [2.2137, 46.2276],
		UK: [-3.436, 55.3781],
	};

	const heatmapData: GeoJSON.FeatureCollection = {
		type: 'FeatureCollection',
		features:
			state.geographyData?.insightCounts?.map((insight) => ({
				type: 'Feature',
				geometry: {
					type: 'Point',
					// @ts-ignore
					coordinates: countryCoordinates[insight.label] || [0, 0],
				},
				properties: {
					weight: insight.value,
				},
			})) || [],
	};

	const renderCharts = (data: IChartData | undefined, title: string, barColors: string[], lineColors: string[]) => {
		if (!data) {
			return null;
		}

		return (
			<>
				{data.insightCounts && data.insightCounts.length > 0 && (
					<Col span={12}>
						<Card className='custom-card' title={`${title} Insights`}>
							<div className='chart-container'>
								{title === 'Geography' ? (
									// <HeatmapComponent
									// 	data={heatmapData}
									// 	center={[-79.9959, 40.4406]}
									// 	zoom={6}
									// 	accessToken={
									// 		process.env.MAPBOX_ACCESS_TOKEN ||
									// 		'pk.eyJ1IjoidW1hbmdqMiIsImEiOiJjbHgwYW9oZTMwZmV6MmtyMjZlbW0xMXAyIn0.KXw3cPjRVgyLxqlUZL-4qA'
									// 	}
									// />
									<>Map Chart Placeholder</>
								) : (
									<BarChart data={data.insightCounts} colors={barColors} title={title} handleClick={handleFiltersChange} />
								)}
							</div>
						</Card>
					</Col>
				)}
				{data.timeEvolution && data.timeEvolution.length > 0 && (
					<Col span={12}>
						<Card className='custom-card' title={`${title} Time Evolution`}>
							<div className='chart-container'>
								{/* <LineChart
									data={data.timeEvolution}
									colors={lineColors}
									title={title}
									handleClick={handleFiltersChange}
									maxDataPoints={5} // Adjust this value as needed
								/> */}
								<LineChartWithSelector data={data.timeEvolution} colors={lineColors} title={title} handleClick={handleFiltersChange} />
							</div>
						</Card>
					</Col>
				)}
				{title === 'Sentiment' && data.avgInsightCounts && data.avgInsightCounts.length > 0 && (
					<Col span={12}>
						<Card className='custom-card' title='Sentiment Analysis'>
							<div className='chart-container'>
								<SentimentChart data={data.avgInsightCounts} colors={['#FF6384', '#36A2EB']} title={title} handleClick={handleFiltersChange} />
							</div>
						</Card>
					</Col>
				)}
			</>
		);
	};

	return (
		<div className='landing-page'>
			<div className='scrollable-container'>
				<Filters config={filterConfig} onFiltersChange={handleFiltersChange} />
				{isLoading && <LoadingOverlay />}
				<Row gutter={[16, 16]} style={{ marginLeft: '0px', marginRight: '0px' }}>
					{renderCharts(state.geographyData, 'Geography', ['#FF6384', '#36A2EB', '#FFCE56'], ['#FF6384', '#36A2EB', '#FFCE56'])}
					{renderCharts(
						state.categoryData,
						'Category',
						['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#A6CEE3', '#B2DF8A', '#FB8072', '#80B1D3'],
						['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#A6CEE3', '#B2DF8A', '#FB8072', '#80B1D3']
					)}
					{renderCharts(
						state.topicData,
						'Topic',
						['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#A6CEE3', '#B2DF8A', '#FB8072', '#80B1D3'],
						['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#A6CEE3', '#B2DF8A', '#FB8072', '#80B1D3']
					)}
					{renderCharts(state.sentimentData, 'Sentiment', ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'], ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'])}
				</Row>
			</div>
		</div>
	);
}
