import List from "antd/lib/list";
import { Link } from "react-router-dom";
import CognitiveCard from "../../../../Components/CognitiveCard";

export default function HomePageCard(props: { loading?: boolean; data: any; highlightedCardId?: number | null }) {
	function createConfigObject(data: any) {
		return {
			title: data.name,
			headStyle: { padding: 0 },
			className: "homepage-card m-2 pt-10",
			loading: props.loading,
			hoverable: true,
			contentStyle: "flex overflow-auto h-64",
			left: [
				{ type: "text", value: { subHeader: "Definition", text: data.definition } },
				{
					type: "text",
					value: {
						subHeader: "High Level Insights",
						text: data.topics
							.map((topic: any) => {
								return `**${topic.topicOrder}\\. ${topic.evidenceName}**: ${topic.evidenceSummary}`;
							})
							.join("\n"),
					},
				},
			],
		};
	}

	const handleCardClick = () => {
		// Add your custom logic here
	};

	return (
		<div className="m-5">
			<List
				grid={{ gutter: 16, column: 2 }}
				dataSource={props.data}
				renderItem={(item: any) => {
					const config = createConfigObject(item);
					return (
						<List.Item key={item.id}>
							<div id={`card-${item.id}`} className={props.highlightedCardId === item.id ? "highlighted-card" : ""}>
								<Link to={`/cognitive/${item.id}`}>
									<CognitiveCard config={config} />
								</Link>
							</div>
						</List.Item>
					);
				}}
			/>
		</div>
	);
}
